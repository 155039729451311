import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Brand, BrandTableProps } from './brand.interface';
import { Edit, Visibility } from '@mui/icons-material';
import React from 'react';
import { Organization } from '../organizations/organization.interface';
import { Link } from 'react-router-dom';
import { DisplayImage } from '../../common/fields/display/image';

const brandFields = <T extends Brand>(
  edit: (brand: Brand) => void,
  organizations: Organization[],
  view: (brand: Brand) => void,
): GridColDef<T>[] => {
  return [
    {
      field: 'view',
      flex: 0.1,
      renderCell: (params: GridCellParams<T>) => (
        <>
          <Visibility
            onClick={() => view(params.row as Brand)}
            sx={{ marginRight: 2 }}
            color="primary"
          />
          <Edit onClick={() => edit(params.row as Brand)} color="info" />
        </>
      ),
      renderHeader: () => <strong>{'View / Edit'}</strong>,
    },
    {
      field: 'id',
      flex: 0.05,
      sortable: true,
      renderHeader: () => <strong>{'Id'}</strong>,
      renderCell: (params: GridCellParams<T>) => {
        return <Link to={`/brand/${params.id}`}>{params.value}</Link>;
      },
    },
    {
      field: 'organizationId',
      flex: 0.1,
      renderCell: (params: GridCellParams<T['organizationId']>) => {
        const organization = organizations.find(
          (org: Organization) => org.id === params.value,
        );
        return <>{organization?.name || ''}</>;
      },
      renderHeader: () => <strong>{'Organization'}</strong>,
    },
    {
      field: 'name',
      renderHeader: () => <strong>{'Name'}</strong>,
      flex: 0.2,
      sortable: true,
      renderCell: (params: GridCellParams<T>) => {
        return <Link to={`/brand/${params.id}`}>{params.value}</Link>;
      },
    },
    {
      field: 'logomarkResourceId',
      flex: 0.2,
      renderHeader: () => <strong>{'Logo'}</strong>,
      renderCell: (params: GridCellParams<T['logomarkResourceId']>) => {
        return <DisplayImage {...{ maxHeight: 45, imageId: params.value }} />;
      },
    },
    {
      field: 'url',
      renderHeader: () => <strong>{'URL'}</strong>,
      flex: 0.15,
    },
    {
      field: 'valueProposition',
      renderHeader: () => <strong>{'Value Proposition'}</strong>,
      flex: 0.2,
    },
  ];
};

export const BrandsTable: React.FC<BrandTableProps> = ({
  edit,
  brands,
  organizations,
  view,
}) => {
  const fieldMap = brandFields(edit, organizations, view);

  return (
    <>
      {brands.length === 0 ? (
        <>{'No Brands were found'}</>
      ) : (
        <DataGrid
          rows={[...brands].sort((a: Brand, b: Brand) => a.id - b.id)}
          columns={fieldMap}
          autoHeight
          sx={{ backgroundColor: '#FFF' }}
        />
      )}
    </>
  );
};

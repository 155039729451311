import { AsyncThunk, createAsyncThunk, Slice } from '@reduxjs/toolkit';
import { ReduxStateKeys } from './store.interface';
import { GenericCRUDActions, CrudActionLimited } from './crud.actions';
import { createPersistentObjectSlice } from './generic.slice';
import { ModelContext, ModelMap } from '../common/types/models';
import { User } from '../common/user/user.interface';
import { ApiService } from '../services/api.service';

const sliceName: ReduxStateKeys = 'user';
const api = new ApiService<User>();
type ModelType = ModelMap[ModelContext.USER];

export const initialUserState: ModelType = {
  id: 0,
  username: 'Guest',
  emailAddress: 'guest@wellput.io',
  role: 0,
  activeOrganizationId: 0,
  organizationIds: [],
};

export const { readById, updateRecord }: CrudActionLimited<ModelType> =
  new GenericCRUDActions<ModelType>(sliceName);
/**
 * @Function fetchUserViaToken
 * @Params JWT
 * @Description Fetch User from API via JWT
 */
export const fetchUserViaToken: AsyncThunk<
  Partial<User>,
  string,
  {}
> = createAsyncThunk(
  `user/token`,
  async (token: string): Promise<Partial<User>> => {
    const response = await api.get(`/v1/token`, token);
    const user: User = response.data as User;
    return user;
  },
);
const crudActions: CrudActionLimited<ModelType> = {
  readById,
  updateRecord,
};
const users: Slice<ModelType> = createPersistentObjectSlice<ModelType>({
  name: sliceName,
  initialState: initialUserState,
  crudActions,
});

export const { update } = users.actions;

export default users.reducer;

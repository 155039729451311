import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

interface  DeleteNewsletterPromptProps {
  open: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
}

export const DeleteNewsletterPrompt: React.FC<DeleteNewsletterPromptProps> = ({open, onCancel, onDelete}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Are you sure you want to delete?
    </DialogTitle>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onDelete} autoFocus variant="contained">
        Yes, delete
      </Button>
    </DialogActions>
  </Dialog>
)

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

class AxiosClient {
  private authorizationHeader: string = 'Bearer default';
  private backendProtocol = process.env.REACT_APP_BACKEND_PROTOCOL || 'http';
  private backendHostname =
    process.env.REACT_APP_BACKEND_HOSTNAME || 'localhost';
  private backendPort = process.env.REACT_APP_BACKEND_PORT || '3001';
  public baseUrl: string;
  public instance: AxiosInstance;

  public constructor() {
    // Assemble the API baseUrl
    this.baseUrl = `${this.backendProtocol}://${this.backendHostname}:${this.backendPort}`;
    // Define the default axios config
    const config: AxiosRequestConfig = {
      baseURL: this.baseUrl,
      headers: {
        Authorization: this.authorizationHeader,
      },
    };
    // // Get the active user from Redux
    // this.reduxUser = store.getState().user;
    // Create the aios instance
    this.instance = axios.create(config);
  }
}
const axiosClient: AxiosClient = new AxiosClient();
Object.freeze(axiosClient);
export default axiosClient;

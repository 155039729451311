import React, { useEffect } from "react";

import Message from "./components/message";
import Box from "@mui/material/Box";
import Brand from "./components/brand";
import Actions from "./components/actions";
import Overview from "./components/overview";
import Pricing from "./components/pricing";
import IdealAudience from "./components/idealAudience";
import Collaboration from "./components/collaboration";
import { RfpDTO } from "./rfpDetails.interface";
import { useParams } from "react-router";
import { ViewContextProps } from "../../../common/types/view";
import { readById } from "../../../store/rfps.slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hook";
import { ReduxState } from "../../../store/store.interface";

interface RfpDetailsProps extends ViewContextProps {}

const RfpDetails: React.FC<RfpDetailsProps> = (
  props: RfpDetailsProps
): JSX.Element => {
  const params = useParams<{ id?: string }>();
  let id: number = 0;
  if (params.id) id = Number.parseInt(params.id);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(readById(id) as any);
  });

  const rfp = useAppSelector((state: ReduxState) => {
    return state.rfps.find((rfp: RfpDTO) => rfp.id === id);
  });

  if (!rfp) {
    return <></>;
  }

  return (
    <Box paddingX="6.25rem" paddingTop="6rem" paddingBottom="3.25rem">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingBottom="3.75rem"
        borderBottom="1px solid rgba(0, 0, 0, 0.5)"
      >
        <Message />
      </Box>

      <Box paddingTop="4.375rem">
        <Box display="flex" justifyContent="space-between">
          <Brand
            name={rfp.brand.name}
            websiteUrl={rfp.brand.url}
            imageUrl={rfp.brand.logomarkResourceContentUrl}
          />
          <Actions
            {...{
              viewContet: props.viewContext,
              next: props.next,
              previous: props.previous,
            }}
          />
        </Box>

        <Overview data={rfp} />

        <Pricing data={rfp} />

        <IdealAudience data={rfp} />

        <Collaboration data={rfp} />
      </Box>
    </Box>
  );
};

export default RfpDetails;

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import GridViewIcon from "@mui/icons-material/GridView";

interface MessageProps {}

const Message: React.FC<MessageProps> = (props: MessageProps): JSX.Element => (
	<Box display="flex" paddingY="1.125rem" paddingX="0.875rem" bgcolor="#E5E5E5" borderRadius="0.3125rem">
		<GridViewIcon width="1.25rem" height="1.25rem"/>
		<Typography id="rfps-message" paragraph style={{paddingLeft: '11px', fontSize: '1.5rem', lineHeight: '120%'}}>
			Hooray! M1 Finance wants to advertise in your newsletter(s)! Create a proposal below to get started.
		</Typography>
	</Box>
);

export default Message;

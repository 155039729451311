import { FC } from "react";
import ChangeRequestView from "./changeRequestView";
import ChangeRequestForm from "./changeRequestForm";
import { ChangeRequestWidgetProps } from "./changeRequest.interface";

const ChangeRequestWidget: FC<ChangeRequestWidgetProps> = ({
  showForm,
  ...props
}) =>
  showForm ? (
    <ChangeRequestForm {...props} />
  ) : (
    <ChangeRequestView {...props} />
  );

export default ChangeRequestWidget;

import Box from "@mui/material/Box";
import { FlowPanelNavigation } from "../../navigation/flowPanel.navigation";

export const FlowPanelSection = () => {
	return (
		<Box id="flow-panel">
			<FlowPanelNavigation />
		</Box>
	);
};

import React from "react";
import Box from "@mui/material/Box";
import RadioInput from "@mui/material/Radio";
import { makeStyles } from '@mui/styles';

import { radioStyles } from "styles/radio.styles";
import { RadioProps } from "./radio.interface";

export const Radio: React.FC<RadioProps> = ({ value, label, onChange }) => {
    const useStyles = makeStyles(radioStyles);
    const classes = useStyles();
    return (
        <Box display={"flex"} alignItems={"center"} className={classes.radio}>
            <Box>
                <RadioInput value={value} onChange={onChange} />
            </Box>
            <Box>
                <Box>{label}</Box>
            </Box>
        </Box>
    );
};

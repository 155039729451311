export interface ActionProps {
	next?: () => void;
	previous?: () => void;
	parentNext?: () => void;
	parentPrevious?: () => void;
}

export enum ViewContext {
	DEFAULT = "Default",
	FLOW = "Flow",
}
export interface ViewProps extends ViewContextProps {
	title?: string;
	subTitle?: string;
}
export interface ViewContextProps extends ActionProps {
	viewContext?: ViewContext;
}

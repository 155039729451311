import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { ViewContext } from '../../common/types/view';
import { viewStyles } from '../../styles/view.style';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { readById } from '../../store/organizations.slice';
import { ReduxState } from '../../store/store.interface';
import { OrderProps, OrderStages } from './orders.interface';
import { enumValues } from '../../utilities/enum.util';
import { FormGenerator } from '../../common/formGenerators/formGenerator';
import { FormGeneratorProps } from '../../common/formGenerators/formGenerator.interface';
const useStyles = makeStyles(() => viewStyles);

export const OrderForm: React.FC<OrderProps> = ({
  cancel,
  save,
  viewContext,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>() || { id: -1 };
  const hasId = id && +id > -1;
  const order = useAppSelector((state: ReduxState) =>
    hasId ? state.orders.find((org) => org.id === +id) : state.flow.order,
  );
  const orders = useAppSelector((state: ReduxState) => state.orders);
  const publishers = useAppSelector((state: ReduxState) =>
    state.organizations
      .filter((org) => org.isPublisher)
      .map((p) => ({ id: p.id, value: p.name })),
  );
  const brands = orders
    .map((o) => o.brand)
    .map((p) => ({ id: p.id, value: p.name }));
  const [fetched, setFetched] = useState(false);
  const [orderCopy, setOrderCopy] = useState(order);
  const updateCompanyProp = (prop: string) => (value: any) => {
    setOrderCopy({ ...orderCopy, [prop]: value });
  };

  const setOrder = () => {
    if (orderCopy) save({ ...orderCopy, creationTimestamp: `${Date.now()}` });
  };
  useEffect(() => {
    if (!orderCopy && !fetched && id && hasId) {
      dispatch(readById(+id) as any);
      setFetched(true);
    }
  }, [orderCopy, fetched, id, setFetched, dispatch, hasId]);

  const previous = (): void => (previous ? previous() : navigate(-1));

  const organizationFormConfig: FormGeneratorProps = {
    maxWidth: '328px',
    fields: [
      {
        id: 'orderName',
        label: 'Company name',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateCompanyProp('name')(event),
        value: orderCopy?.name,
      },
      {
        id: 'publisher',
        label: 'Publisher',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateCompanyProp('publisher')(event),
        value: orderCopy?.publisher?.name,
        options: publishers,
        type: 'select',
      },
      {
        id: 'brand',
        label: 'Brand',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateCompanyProp('name')(event),
        value: orderCopy?.brand?.name,
        options: brands,
        type: 'select',
      },
      {
        id: 'projectedSpend',
        label: 'Projected spend',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateCompanyProp('projectedSpend')(event),
        value: orderCopy?.projectedSpend,
      },
      {
        id: 'maximumSpend',
        label: 'Maximum spend',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateCompanyProp('maximumSpend')(event),
        value: orderCopy?.maximumSpend,
      },
      {
        id: 'startTimestamp',
        label: 'Start timestamp',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateCompanyProp('startTimestamp')(event),
        value: orderCopy?.startTimestamp,
        type: 'date',
      },
      {
        id: 'endTimestamp',
        label: 'End timestamp',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateCompanyProp('endTimestamp')(event),
        value: orderCopy?.endTimestamp,
        type: 'date',
      },
      {
        id: 'stage',
        label: 'Stage',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateCompanyProp('stage')(event),
        value: orderCopy?.stage,
        options: enumValues(OrderStages).map((stage) => {
          return { id: stage, value: stage };
        }),
        type: 'select',
      },
      {
        id: 'ecpc',
        label: 'ecpc',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateCompanyProp('ecpc')(event),
        value: orderCopy?.ecpc,
      },
      {
        id: 'alert',
        label: 'Alert',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateCompanyProp('alert')(event),
        value: orderCopy?.alert,
      },
    ],
  };
  const classes = useStyles();
  return (
    <div data-testid="admin-order-form" className={classes.viewContainer}>
      <Box>
        <FormGenerator
          fields={organizationFormConfig.fields}
          maxWidth={organizationFormConfig.maxWidth}
        />
      </Box>
      <Box className={classes.actions}>
        <Button
          variant="outlined"
          className={classes.actionButton}
          onClick={cancel || previous}
        >
          {viewContext === ViewContext.FLOW ? 'Back' : 'Cancel'}
        </Button>
        <Button
          variant="contained"
          className={classes.actionButton}
          onClick={setOrder}
        >
          {viewContext === ViewContext.FLOW ? 'Next' : 'Save'}
        </Button>
      </Box>
    </div>
  );
};

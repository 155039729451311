import { Slice } from '@reduxjs/toolkit';
import { ReduxStateKeys } from './store.interface';
import { createTransientObjectSlice } from './generic.slice';
import { ModelContext, ModelMap } from '../common/types/models';

const sliceName: ReduxStateKeys = 'error';
type ModelType = ModelMap[ModelContext.ERROR];

export const initialErrorState: ModelType = {};

const auth: Slice<ModelType> = createTransientObjectSlice<ModelType>({
  name: sliceName,
  initialState: initialErrorState,
});

export const { update } = auth.actions;

export default auth.reducer;

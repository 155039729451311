import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NewsletterDetails, NewslettersProps } from "./newsletter.interface";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import Assistance from "../../common/assistance/assistance";
import { NewslettersTitle } from "./components/newslettersTitle";
import { NewsletterItem } from "./components/newsletterItem";
import { FlowButtonSet } from "../../common/flowButtonSet/flowButtonSet";
import { DeleteNewsletterPrompt } from "./components/deleteNewsletterPrompt";

import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { ReduxState } from "../../store/store.interface";
import { readAll } from "../../store/newsletter.slice";

const Newsletters: React.FC<NewslettersProps> = ({ viewContext }) => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useAppDispatch();
  const newsletters = useAppSelector((state: ReduxState) => state.newsletters);
  useEffect(() => {
    if (newsletters.length === 0 && !fetched) {
      dispatch(readAll() as any);
      setFetched(true);
    }
  }, [newsletters.length, fetched, dispatch]);

  const [selected, setSelected] = useState<number | undefined>(undefined);
  const [openDelete, setOpenDelete] = useState(false);

  const navigate = useNavigate();

  const handleAddNewsletter = () => {
    navigate("/newsletters/create");
  };

  const handleOnView = (newsletter: NewsletterDetails) => {
    navigate(`/newsletters/${newsletter.id}`);
  };

  const handleOnEdit = (newsletter: NewsletterDetails) => {
    navigate(`/newsletters/${newsletter.id}`);
  };

  const handleOnDelete = (newsletter: NewsletterDetails) => {
    setOpenDelete(true);
  };

  return (
    <>
      <Assistance />
      {viewContext === "Flow" && (
        <Box color={"#5D5FEF"}>Line item 1 {">"} Newsletter</Box>
      )}
      <NewslettersTitle viewContext={viewContext} />
      {newsletters.length === 0 ? (
        <>{"No Newsletters Were Found"}</>
      ) : (
        newsletters.map((nl, index) => (
          <NewsletterItem
            index={index}
            name={nl.name}
            count={nl.subscriberCount}
            checked={selected === index}
            onSelect={() => setSelected(index)}
            onView={() => handleOnView(nl)}
            onEdit={() => handleOnEdit(nl)}
            onDelete={() => handleOnDelete(nl)}
          />
        ))
      )}
      <Button
        startIcon={<Add />}
        sx={{ marginTop: "4.6875rem" }}
        onClick={handleAddNewsletter}
      >
        NEWSLETTER
      </Button>
      {viewContext === "Flow" && <FlowButtonSet />}
      <DeleteNewsletterPrompt
        open={openDelete}
        onDelete={() => setOpenDelete(false)}
        onCancel={() => setOpenDelete(false)}
      />
    </>
  );
};

export default Newsletters;

export const requiredFieldErrorMessage = 'This field is required.';
export const maxLengthErrorMessage = (max_chars: number) =>
  `this field can't be longer than ${max_chars} chars.`;

export type FieldValidation = {
  isInvalid: boolean;
  feedback: string;
};
type ConstraintFeedback = {
  condition: (value: any) => boolean;
  feedback: string;
};

export const isDefined = (value?: any): boolean =>
  value !== undefined || value !== '';
export const isUndefined = (value?: any): boolean => value === undefined;
export const minValue =
  (min: number) =>
  (value?: number): boolean =>
    !!value && value >= min;
export const maxValue =
  (max: number) =>
  (value?: number): boolean =>
    !!value && value <= max;
export const minCharLength =
  (min: number) =>
  (value?: string): boolean =>
    !!value && value.length >= min;
export const maxCharLength =
  (max: number) =>
  (value?: string): boolean =>
    value === undefined || value.length <= max;
export const minSetLength =
  (min: number) =>
  (value?: any[]): boolean =>
    !!value && value.length >= min;
export const maxSetLength =
  (max: number) =>
  (value?: any[]): boolean =>
    !!value && value.length <= max;
export const isEmptySet = (value: any[]) =>
  Array.isArray(value) && value.length === 0;

export const isMemberOfEnum =
  <T extends { [k: string]: any }>(enumObject: T) =>
  (value: any): boolean => {
    const enumValues: any[] = Object.values<any>(enumObject);
    return !!value && enumValues.includes(value);
  };
export const isValidUrl = (url: string) => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(url);
};
export const isTruthy = (value?: boolean): boolean =>
  value !== undefined && value;
export const isFalsey = (value?: boolean): boolean =>
  value !== undefined && !value;
export const includesLinkTag = (value?: string): boolean =>
  !!value && value.toLowerCase().includes('<a href=');

export const timestampGreaterThanOrEqualToToday = (value?: number) =>
  !!value && value * 1000 > new Date().setUTCHours(0, 0, 0, 0);

export const isRequired = (value: any) => !value;
export const hasMaxLength = (maxLength: number) => (value: string) =>
  (value.length || 0) > maxLength;
export const hasMinIntValue = (minValue: number) => (value: string) =>
  parseInt(value) <= minValue;
export const composeConstraints = (
  value: any,
  constraints: ConstraintFeedback[],
): FieldValidation => {
  for (let i = 0; i < constraints.length; i++) {
    if (constraints[i].condition(value)) {
      return {
        isInvalid: true,
        feedback: constraints[i].feedback,
      };
    }
  }
  return {
    isInvalid: false,
    feedback: '',
  };
};

import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { readAll as readAllOrganizations } from '../../store/organizations.slice';
import { ReduxState } from '../../store/store.interface';
import { update as updateUser } from '../../store/user.slice';
import { Organization } from '../../views/organizations/organization.interface';
import { useAuthentication } from '../user/auth/useAuthentication.hook';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
interface UserOrganizationButtonProps {
  anchorRef: React.RefObject<HTMLDivElement>;
}

export const UserOrganizationButton: React.FC<UserOrganizationButtonProps> = (
  props,
) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const { anchorRef } = props;
  const [fetched, setFetched] = useState(false);
  const { isAuthenticated } = useAuthentication();
  const { user, organizations } = useAppSelector((state: ReduxState) => state);
  const handleChange = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    value?: number,
  ) => {
    dispatch(updateUser({ activeOrganizationId: value || 0 }));
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  const menuItems = [{ name: 'Select One', id: 0 }, ...organizations].map(
    (org: Partial<Organization>, index: number) => {
      return (
        <MenuItem
          key={`organization-${index}`}
          value={org.id}
          selected={user.activeOrganizationId === org.id}
          onClick={(event) => handleChange(event, org.id)}
        >
          {org.name}
        </MenuItem>
      );
    },
  );
  useEffect(() => {
    if (isAuthenticated) {
      if (organizations.length === 0 && !fetched) {
        dispatch(readAllOrganizations() as any);
        setFetched(true);
      }
      if (organizations.length > 0 && user.activeOrganizationId === 0) {
        dispatch(updateUser({ activeOrganizationId: organizations[0].id }));
      }
    }
  }, [dispatch, fetched, isAuthenticated, organizations, setFetched, user]);
  return organizations.length > 0 ? (
    <>
      <Button
        disabled
        variant="text"
        sx={{ borderLeft: '1px solid rgb(0, 0, 0, 0.1)', minWidth: '175px' }}
      >
        {
          organizations.find(
            (org: Organization) => org.id === user.activeOrganizationId,
          )?.name
        }
      </Button>
      <Button
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="Select an Organization"
        aria-haspopup="menu"
        onClick={handleToggle}
        variant="text"
        sx={{
          outline: 'none !important',
        }}
      >
        <ArrowDropDownIcon />
      </Button>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{ borderRadius: '0 0 10px 10px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {menuItems}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ) : (
    <></>
  );
};

import { Link } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Organization, OrganizationTableProps } from './organization.interface';
import { Cancel, Edit } from '@mui/icons-material';
import React from 'react';

const organizationFields = <T extends Organization>(
  edit: (organization: Organization) => void,
  remove: (id: number) => void,
): GridColDef<T>[] => {
  return [
    {
      field: 'id',
      headerName: 'Id',
      width: 170,
      sortable: true,
      renderCell: (params: GridCellParams<T>) => {
        return (
          <Link underline="always" href={`/organizations/${params.id}`}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Organization',
      width: 170,
      sortable: true,
      renderCell: (params: GridCellParams<T>) => {
        return (
          <Link underline="always" href={`/organizations/${params.id}`}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'isPublisher',
      headerName: 'Is Publisher?',
      width: 170,
      renderCell: (params: GridCellParams<T>) => {
        return <>{`${params.value}`}</>;
      },
    },
    {
      field: 'isAdvertiser',
      headerName: 'Is Advertiser?',
      width: 170,
      renderCell: (params: GridCellParams<T>) => {
        return <>{`${params.value}`}</>;
      },
    },
    {
      field: 'logomarkResource',
      headerName: 'Organization Logo',
      width: 170,
      renderCell: (params: GridCellParams<T>) => {
        return <>{params.value}</>;
      },
    },
    {
      field: 'mediaKit',
      headerName: 'MediaKit',
      width: 170,
      renderCell: (params: GridCellParams<T>) => (
        <Link underline="always" href={`${params.value?.mediaKit}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 170,
      renderCell: (params: GridCellParams<T>) => (
        <Edit onClick={() => edit(params.row as Organization)} />
      ),
    },
    {
      field: 'remove',
      headerName: 'Remove',
      width: 170,
      renderCell: (params: GridCellParams<T>) => (
        <Cancel onClick={() => remove(+params.id || 0)} />
      ),
    },
  ];
};

export const OrganizationsTable: React.FC<OrganizationTableProps> = ({
  edit,
  organizations,
  remove,
}) => {
  const fieldMap = organizationFields(edit, remove);

  return (
    <>
      {organizations.length === 0 ? (
        <>{'No Organizations were found'}</>
      ) : (
        <DataGrid
          rows={[...organizations].sort(
            (a: Organization, b: Organization) => a.id - b.id,
          )}
          columns={fieldMap}
          autoHeight
          sx={{ backgroundColor: '#FFF' }}
        />
      )}
    </>
  );
};

import { Box, Button } from '@mui/material';
import Assistance from '../../common/assistance/assistance';
import ViewTitle from '../../common/titles/viewTitle/viewTitle';
import React, { useEffect, useState } from 'react';
import { LineItemForm } from './lineItem.form';
import { LineItemsTable } from './lineItems.table';
import { LineItem } from './lineItem.interface';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { ReduxState } from '../../store/store.interface';
import { readAll, updateRecord } from '../../store/lineItems.slice';
import { PlusOne } from '@mui/icons-material';
import { LineItemView } from './lineItem.view';
import { ViewContext } from '../../common/types/view';
import { makeStyles } from '@mui/styles';
import { viewStyles } from '../../styles/view.style';
import { useNotifications } from '../../hooks/useNotifications.hook';
import { useAuthentication } from '../../common/user/auth/useAuthentication.hook';
import { readAll as readAllBrands } from '../../store/brands.slice';

const useStyles = makeStyles(() => viewStyles);
export const LineItemsAdminView: React.FC<{}> = () => {
  // state controls
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuthentication();
  const { lineItems, auth, organizations, brands } = useAppSelector(
    (state: ReduxState) => state,
  );
  const activeOrganizationId = useAppSelector(
    (state: ReduxState) => state.user.activeOrganizationId,
  );

  const filteredLineItems: LineItem[] =
    !lineItems || lineItems.length === 0
      ? []
      : lineItems.filter(
          (lineItem: LineItem) =>
            lineItem.organizationId === activeOrganizationId,
        );
  const [activeLineItem, setActiveLineItem] = useState<Partial<LineItem>>();
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [fetchedBrands, setFetchedBrands] = useState(false);
  const { notify, Notification } = useNotifications();
  const toggleEdit = (lineItem?: Partial<LineItem>) => {
    if (lineItem) setActiveLineItem(lineItem);
    setShowEdit(!showEdit);
    setFetched(false);
  };
  const toggleView = (lineItem?: LineItem) => {
    if (lineItem) setActiveLineItem(lineItem);
    setShowView(!showView);
    setFetched(false);
  };
  const handleCreateClick = () => {
    setActiveLineItem({} as LineItem);
    setShowEdit(true);
  };
  const toggleActive = async (id: number) => {
    const lineItem: LineItem | undefined = filteredLineItems.find(
      (li: LineItem) => li.id === id,
    );
    if (lineItem) {
      let updatedLineItem = { ...lineItem, isActive: !lineItem.isActive };
      delete updatedLineItem.hasBeenActive;

      const result = await dispatch(updateRecord(updatedLineItem) as any);
      if (result.error) {
        return notify(result.error.message, 'error');
      }

      return notify(
        `Line Item ${updatedLineItem.id} is now ${
          updatedLineItem.isActive ? 'active' : 'inactive'
        }.`,
        'success',
      );
    } else {
      return notify(`Line Item not found.`, 'info');
    }
  };

  useEffect(() => {
    if (
      !showEdit &&
      isAuthenticated &&
      filteredLineItems.length === 0 &&
      !fetched
    ) {
      dispatch(readAll() as any);
      setFetched(true);
    }
    if (isAuthenticated && brands.length === 0 && !fetchedBrands) {
      dispatch(readAllBrands() as any);
      setFetchedBrands(true);
    }
  }, [
    auth,
    dispatch,
    setFetched,
    filteredLineItems.length,
    fetched,
    showEdit,
    isAuthenticated,
    brands.length,
    fetchedBrands,
  ]);
  return (
    <div className={classes.viewContainer}>
      <Box sx={{ mb: '3rem', color: 'primary.main' }}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Assistance />
        </Box>
        <ViewTitle
          {...{
            title: 'Placements',
            subTitle: 'Admin Console',
            rightAlignedContent: (
              <Button
                variant="outlined"
                startIcon={<PlusOne />}
                onClick={handleCreateClick}
                sx={{ backgroundColor: '#FFF' }}
              >
                Create Placement
              </Button>
            ),
          }}
        />
        {showEdit ? (
          <LineItemForm
            lineItem={activeLineItem}
            brands={brands}
            toggleVisibility={toggleEdit}
            notify={notify}
          />
        ) : showView ? (
          <LineItemView
            {...{
              lineItem: activeLineItem as LineItem,
              viewContext: ViewContext.FLOW,
              toggleVisibility: toggleView,
              notify,
            }}
          />
        ) : (
          <LineItemsTable
            {...{
              edit: toggleEdit,
              lineItems: filteredLineItems,
              toggleActive,
              organizations,
              brands,
              view: toggleView,
            }}
          />
        )}
        {Notification}
      </Box>
    </div>
  );
};

import { StyleRules } from "@mui/styles";

export const reviewCardStyles: StyleRules = {
    container: {
        border: "1px solid ",
        padding: "1rem",
        borderRadius: "10px",
        maxWidth: "255px",
    },
    title: { color: "#5D5FEF" },
    wrapper: {
        border: "1px solid ",
        padding: "1rem",
        borderRadius: "10px",
        position: "relative",
        marginTop: "2rem",
        marginBottom: "2rem",
    },
    actions: {
        position: "absolute",
        top: "45px",
        right: "-10px",
        width: "20px",
    },
}
import { PayloadAction } from "@reduxjs/toolkit";
import { FlowReducerActionPayload } from "../common/types/actions.types";

export const actionPayloadGetter = <T>(action: PayloadAction<T | number>): T =>
  action.payload as T;
export const flowActionPayloadGetter = <T>(
  action: PayloadAction<T>
): FlowReducerActionPayload =>
  ({
    ...action.payload,
  } as FlowReducerActionPayload);

import { actionPayloadGetter } from './reducer.store';
import {
  CrudActionEssential,
  CrudActionEssentialKeys,
  CrudActionLimited,
  CrudActionLimitedKeys,
} from './crud.actions';
import {
  ActionReducerMapBuilder,
  AnyAction,
  CaseReducer,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import { ReduxCollectionModelTypes } from './store.interface';
import { ModelTypes, ModelTypesWithId } from '../common/types/models';

type CollectionReducerActionMapType<T extends ReduxCollectionModelTypes> = {
  [Property in CrudActionEssentialKeys<T>]: CaseReducer<T[], AnyAction>;
};
type CollectionReducerActionMapKeys<T extends ReduxCollectionModelTypes> =
  keyof CollectionReducerActionMapType<T>;
type ObjectReducerActionMapType<T extends ModelTypesWithId> = {
  [Property in CrudActionLimitedKeys<T>]: CaseReducer<T, AnyAction>;
};
type ObjectReducerActionMapKeys<T extends ModelTypesWithId> =
  keyof ObjectReducerActionMapType<T>;
/**
 * @Function baseObjectReducers
 * @Description Base Object Reducers - These functions are the base reducers within slice that is an Object.
 * They control / mutate state directly and should be considered pure functions.
 */
export const baseObjectReducers = <
  T extends ModelTypes,
>(): ValidateSliceCaseReducers<T, SliceCaseReducers<T>> => {
  return {
    update: (state: T, action: PayloadAction<T>) => {
      const data: T = actionPayloadGetter<T>(action);
      return { ...state, ...data };
    },
  } as ValidateSliceCaseReducers<T, SliceCaseReducers<T>>;
};
/**
 * @Function baseCollectionReducers
 * @Description Base Collection Reducers - These functions are the base reducers within slice that is a Collection of Objects.
 * They control / mutate state directly and should be considered pure functions.
 */
export const baseCollectionReducers = <
  T extends ModelTypesWithId,
>(): ValidateSliceCaseReducers<T[], SliceCaseReducers<T[]>> => {
  return {
    add: (state: T[], action: PayloadAction<T>): T[] => {
      const data = actionPayloadGetter<T>(action);
      return [...state, data] as T[];
    },
    replaceAll: (state: T[], action: PayloadAction<T[]>): T[] => {
      return action.payload;
    },
    update: (state: T[], action: PayloadAction<T>): T[] => {
      const data = actionPayloadGetter<T>(action);
      return [
        ...state.map((model: T) => {
          return model.id !== data.id ? model : { ...model, ...data };
        }),
      ] as T[];
    },
    remove: (state: T[], action: PayloadAction<number>): T[] => {
      const id = actionPayloadGetter<number>(action);
      return state.filter((model: T) => model.id !== id) as T[];
    },
  } as ValidateSliceCaseReducers<T[], SliceCaseReducers<T[]>>;
};
const collectionReducerActionMap = <T extends ReduxCollectionModelTypes>(
  reducers: ValidateSliceCaseReducers<T[], SliceCaseReducers<T[]>>,
): CollectionReducerActionMapType<T> => ({
  readById: reducers.add as CaseReducer<T[], AnyAction>,
  readAll: reducers.replaceAll as CaseReducer<T[], AnyAction>,
  createRecord: reducers.add as CaseReducer<T[], AnyAction>,
  updateRecord: reducers.update as CaseReducer<T[], AnyAction>,
  deleteRecord: reducers.remove as CaseReducer<T[], AnyAction>,
});
const objectReducerActionMap = <T extends ModelTypesWithId>(
  reducers: ValidateSliceCaseReducers<T, SliceCaseReducers<T>>,
): ObjectReducerActionMapType<T> => ({
  readById: reducers.add as CaseReducer<T, AnyAction>,
  updateRecord: reducers.update as CaseReducer<T, AnyAction>,
});
/**
 * @Function crudReducerBuilder<T>
 * @Params crudActions CrudActionEssential<T> This is a map of Async calls.
 * @Params reducers SliceCaseReducers<T> Base reducer methods that will be mapped to fulfilled status hooks.
 * @Description This function builds state hooks for each Objects' HTTP methods.
 */
export const crudCollectionReducerBuilder = <
  T extends ReduxCollectionModelTypes,
>(
  crudActions: CrudActionEssential<T>,
  reducers: ValidateSliceCaseReducers<T[], SliceCaseReducers<T[]>>,
): ((builder: ActionReducerMapBuilder<T[]>) => void) => {
  const map = collectionReducerActionMap(reducers);

  return (builder: ActionReducerMapBuilder<T[]>) => {
    // Iterate over all actions and produce case functions for each stage of the request.
    Object.keys(crudActions).forEach((key: string) => {
      // Build the `pending` case function.
      builder.addCase(
        crudActions[key as CrudActionEssentialKeys<T>].pending,
        () => {
          // Do something with the pending state
        },
      );
      // Build the `fulfilled` case function.
      builder.addCase(
        // Watch case
        crudActions[key as CrudActionEssentialKeys<T>].fulfilled,
        // Fire this method when triggered.
        (state, action) => {
          return map[key as CollectionReducerActionMapKeys<T>](state, action);
        },
      );
      // Build the `rejected` case function.
      builder.addCase(
        crudActions[key as CrudActionEssentialKeys<T>].rejected,
        () => {
          // Do something with the rejected state
        },
      );
    });
  };
};
/**
 * @Function crudReducerBuilder<T>
 * @Params crudActions CrudActionEssential<T> This is a map of Async calls.
 * @Params reducers SliceCaseReducers<T> Base reducer methods that will be mapped to fulfilled status hooks.
 * @Description This function builds state hooks for each Objects' HTTP methods.
 */
export const crudObjectReducerBuilder = <T extends ModelTypesWithId>(
  crudActions: CrudActionLimited<T>,
  reducers: ValidateSliceCaseReducers<T, SliceCaseReducers<T>>,
): ((builder: ActionReducerMapBuilder<T>) => void) => {
  const map: ObjectReducerActionMapType<T> = objectReducerActionMap(reducers);

  return (builder: ActionReducerMapBuilder<T>) => {
    // Iterate over all actions and produce case functions for each stage of the request.
    Object.keys(crudActions).forEach((key: string) => {
      // Build the `pending` case function.
      builder.addCase(
        crudActions[key as CrudActionLimitedKeys<T>].pending,
        () => {
          // Do something with the pending state
        },
      );
      // Build the `fulfilled` case function.
      builder.addCase(
        // Watch case
        crudActions[key as CrudActionLimitedKeys<T>].fulfilled,
        // Fire this method when triggered.
        (state, action) => {
          return map[key as ObjectReducerActionMapKeys<T>](state, action);
        },
      );
      // Build the `rejected` case function.
      builder.addCase(
        crudActions[key as CrudActionLimitedKeys<T>].rejected,
        () => {
          // Do something with the rejected state
        },
      );
    });
  };
};

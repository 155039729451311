import Box from "@mui/material/Box";
import { makeStyles } from '@mui/styles';
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export const ChipAdUnitButtons = () => {
    const useStyles = makeStyles(() => ({
        wrapper: {
            backgroundColor: "#5D5FEF",
            borderRadius: "50%",
            padding: "5px",
            display: "grid",
            placeItems: "center",
            marginBottom: "3px",
        },
        icon: { fill: "#fff", width: "10px", height: "10px" },
    }));
    const classes = useStyles();
    return (
        <Box style={{}}>
            <Box className={classes.wrapper}>
                <ModeEditOutlineOutlinedIcon className={classes.icon} />
            </Box>
            <Box className={classes.wrapper}>
                <DeleteOutlineOutlinedIcon className={classes.icon} />
            </Box>
        </Box>
    );
};

import { Box, Grid } from '@mui/material';
import React from 'react';
import { CreativesCompactTable } from './creatives.compact.table';
import { Creative, CreativeAssignmentProps } from './creative.interface';
import { GridSelectionModel } from '@mui/x-data-grid/models/gridSelectionModel';
import { GridRowId } from '@mui/x-data-grid/models/gridRows';
import { Title } from '../../common/titles/title/title';

export const CreativeAssignment: React.FC<CreativeAssignmentProps> = (
  props: CreativeAssignmentProps,
): JSX.Element => {
  const updateCreatives = (currentSelection: GridSelectionModel) => {
    const creativeIds: number[] = currentSelection.map((id: GridRowId) => +id);
    if (props.lineItem) {
      props.setLineItem({
        ...props.lineItem,
        creativeIdsByAdUnitIndex: [creativeIds],
      });
    }
  };
  const typeAndBrandFilteredCreatives: Creative[] =
    props.lineItem && props.creatives.length > 0
      ? props.creatives.filter(
          (c: Creative) =>
            c.adUnitType === props.lineItem.adUnitType &&
            c.brandId === props.lineItem.brandId,
        )
      : [];
  return (
    <Box>
      <Box sx={{ my: 3 }}>
        <Title text="CREATIVE ASSIGNMENT" />
      </Box>
      <Grid container sx={{ py: 1 }} spacing={4}>
        <Grid item xs={12}>
          <CreativesCompactTable
            {...{
              creatives: typeAndBrandFilteredCreatives,
              brands: props.brands,
              organizations: props.organizations,
              selected: Array.isArray(props.lineItem.creativeIdsByAdUnitIndex)
                ? props.lineItem.creativeIdsByAdUnitIndex[0]
                : [],
              onSelection: updateCreatives,
              readonly: false,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

import { FC } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { Title } from "../titles/title/title";
import CardHeader from "../cards/cardHeader/cardHeader";
import { ChangeRequestWidgetProps } from "./changeRequest.interface";

import styles from "../../styles/changeRequestView.styles";

const ChangeRequestView: FC<ChangeRequestWidgetProps> = ({
  index,
  changeRequest: { description, comment },
  editable = false,
  edit,
  remove,
}) => {
  const handleEdit = () => {
    edit && edit(index);
  };
  const handleRemove = () => {
    remove && remove(index);
  };
  return (
    <Box sx={styles.container}>
      <CardHeader
        leftAligned={
          <Title text={`Request #${index + 1}`} icon={<SwapHorizIcon />} />
        }
      />
      <Box sx={styles.content}>
        <Box sx={styles.descriptionContainer}>
          <Typography variant="h4" sx={styles.description}>
            {description}
          </Typography>
          {editable && (
            <IconButton
              data-testid="edit-button"
              sx={styles.iconButton}
              color="primary"
              aria-label="edit change request"
              component="span"
              size="small"
              onClick={handleEdit}
            >
              <EditIcon />
            </IconButton>
          )}
        </Box>
        <Divider light />
        <>
          <Box sx={styles.deleteIconContainer}>
            {editable && (
              <IconButton
                data-testid="remove-button"
                sx={styles.iconButton}
                color="primary"
                aria-label="delete change request"
                component="span"
                size="small"
                onClick={handleRemove}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
          {comment && (
            <Box sx={styles.commentContainer}>
              <CommentIcon color="primary" />
              <Typography variant="body2" sx={styles.comment}>
                {comment}
              </Typography>
            </Box>
          )}
        </>
      </Box>
    </Box>
  );
};

export default ChangeRequestView;

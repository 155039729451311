import React from 'react';
import { ReactChildren, ReactElement, ReactNode } from 'react';

type Props = {
  predicate: boolean;
  onHide?: () => void;
  onShow?: () => void;
  children: ReactChildren | ReactNode | ReactElement;
};

export const Conditional = ({
  predicate,
  onHide = () => {},
  onShow = () => {},
  children,
}: Props) => {
  predicate ? onShow() : onHide();
  return <>{predicate ? children : null}</>;
};

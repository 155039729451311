import { PaletteOptions } from '@mui/material/styles';

import Colors from './colors';

const palette: PaletteOptions = {
  primary: {
    main: Colors.LightCobaltBlue,
    dark: Colors.Jacarta,
    light: Colors.Soap,
  },
  secondary: {
    main: Colors.AliceBlue,
    dark: Colors.Lavender,
    light: Colors.GhostWhite,
  },
  error: {
    main: Colors.TerraCotta,
    dark: Colors.TerraCottaDark,
    light: Colors.TerraCotta20,
  },
  info: {
    main: Colors.DarkSilver,
    dark: Colors.DarkCharcoal,
    light: Colors.SilverSand,
  },
  success: {
    main: Colors.LightMossGreen,
    dark: Colors.IguanaGreen,
    light: Colors.LightMossGreen50,
  },
};

export default palette;

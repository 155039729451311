import { SxProps, Theme } from "@mui/material/styles";
import Colors from "../theme/palette/colors";

const styles: { [key: string]: SxProps<Theme> } = {
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: (theme: Theme) => theme.shape.borderRadius,
    border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
  },
  content: {
    padding: (theme: Theme) => theme.spacing(4),
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: (theme: Theme) => theme.spacing(4),
    marginBottom: (theme: Theme) => theme.spacing(2),
  },
  iconButton: {
    marginRight: (theme: Theme) => theme.spacing(-6.125),
    color: Colors.AliceBlue,
    backgroundColor: Colors.BrightPurple,
    "&:hover": {
      backgroundColor: Colors.BrightPurple50,
    },
  },
  deleteIconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    height: (theme: Theme) => theme.spacing(4),
  },
  commentContainer: {
    display: "flex",
  },
  comment: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    marginTop: "2px",
  },
};

export default styles;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import ViewTitle from '../../../common/titles/viewTitle/viewTitle';
import { ViewContext } from '../../../common/types/view';
import Assistance from '../../../common/assistance/assistance';
import { NumberField } from '../../../common/formGenerators/inputs/numberField/numberField';
import { NumberFieldProps } from '../../../common/formGenerators/inputs/numberField/numberField.interface';
import { viewStyles } from '../../../styles/view.style';
import { CreativeTerms } from 'views/proposals/proposals.interface';
import { WithId } from '../../../common/types/types';
import { useAppDispatch } from 'hooks/redux.hook';
import { update } from 'store/proposals.slice';

const useStyles = makeStyles(viewStyles);

export const ProposalRevisionTimeline: React.FC<any> = (props) => {
  const [creativeTerms, setCreativeTerms] = useState<
    Partial<CreativeTerms & WithId>
  >({
    id: props.id || null,
    maxRevisionRoundCount: props.maxRevisionRoundCount || null,
    advertiserRevisionDays: props.advertiserRevisionDays || null,
    mailingLeadTimeDays: props.mailingLeadTimeDays || null,
  });
  const dispatch = useAppDispatch();
  const setStateCreativeTerms = () => {
    dispatch(update({ data: creativeTerms }));
    if (props.next) return props.next();
  };
  const updateCreativeTermsProp = (prop: string) => (value: any) => {
    setCreativeTerms({ ...creativeTerms, [prop]: value });
  };

  const classes = useStyles();
  const revisionsConfig: Array<NumberFieldProps> = [
    {
      title: 'Revisions',
      chipText: 'The advertiser prefers 3 rounds',
      description: 'rounds (maximum)',
      initialValue: creativeTerms.mailingLeadTimeDays,
      onChange: updateCreativeTermsProp('maxRevisionRoundCount'),
    },
    {
      title: 'Publisher creative time',
      description: 'days (total)',
      initialValue: creativeTerms.advertiserRevisionDays,
      onChange: updateCreativeTermsProp('advertiserRevisionDays'),
    },
    {
      title: 'Mailing lead time',
      description: 'days before mailing',
      initialValue: creativeTerms.mailingLeadTimeDays,
      onChange: updateCreativeTermsProp('mailingLeadTimeDays'),
    },
  ];
  return (
    <div className={classes.viewContainer}>
      <Box sx={{ mb: '3rem' }}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Assistance />
        </Box>
        <ViewTitle
          title="Tell us about your cretive preferences."
          subTitle="Help us understand your preferences better."
          showDivider={false}
          viewContext={ViewContext.FLOW}
        />
      </Box>

      {revisionsConfig.map(({ title, chipText, description, onChange }) => {
        return (
          <NumberField
            key={`revision-field-${title.toLocaleLowerCase()}`}
            title={title}
            chipText={chipText}
            description={description}
            onChange={onChange}
          />
        );
      })}

      <Box className={classes.actions}>
        <Button
          variant="outlined"
          className={classes.actionButton}
          onClick={props.previous}
        >
          {props.viewContext === ViewContext.FLOW ? 'Back' : 'Cancel'}
        </Button>
        <Button
          variant="contained"
          className={classes.actionButton}
          onClick={setStateCreativeTerms}
        >
          {props.viewContext === ViewContext.FLOW ? 'Next' : 'Continue'}
        </Button>
      </Box>
    </div>
  );
};

export default ProposalRevisionTimeline;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import ViewTitle from '../../../common/titles/viewTitle/viewTitle';
import { Section } from '../../../common/sections/section';
import SectionTitle from '../../../common/titles/sectionTitle';
import { SectionCard } from '../../../common/cards/sectionCard/sectionCard';
import ListItemSummary from '../../../common/listItem/listItemSummary';
import ListItem from '../../../common/listItem/listItem';
import { Title } from '../../../common/titles/title';
import ChangeRequestWidgets from '../../../common/changeRequest/changeRequestWidgets';
import { StateDetail } from '../../../common/stateDetail/stateDetail';
import PublisherBrief from './publisherBrief';

import { ProposalDetailsProps } from './proposalDetails.interface';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hook';
import { ReduxState } from '../../../store/store.interface';
import { readById } from '../../../store/proposals.slice';

import styles from '../../../styles/proposalDetails.styles';
import { FieldValueHelper } from '../../../common/fields/display/fieldValueHelper';

const ProposalDetails: React.FC<ProposalDetailsProps> = (
  props: ProposalDetailsProps,
): JSX.Element => {
  const [changeRequestsVisible, setChangeRequestsVisible] =
    useState<boolean>(false);
  const [changeRequestsSubmitted, setChangeRequestsSubmitted] =
    useState<boolean>(false);
  const [proposalDeclined, setProposalDeclined] = useState<boolean>(false);
  const [proposalAccepted, setProposalAccepted] = useState<boolean>(false);
  const params = useParams<{ id?: string }>();
  let id: number;
  if (props.id) {
    id = +props.id;
  } else if (params.id) {
    id = +params.id;
  }

  const proposal = useAppSelector((state: ReduxState) =>
    state.proposals.find((prop) => prop.id === id || -1),
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!proposal) {
      dispatch(readById(id) as any);
    }
  });

  let stateDetailMessaging =
    'Great job! Your change requests have been submitted.';
  if (proposalDeclined) {
    stateDetailMessaging =
      'Thanks for your response! You have denied this proposal.';
  } else if (proposalAccepted) {
    stateDetailMessaging = 'Spectacular! You have accepted this proposal.';
  }
  const stateDetailVisible =
    proposalDeclined || proposalAccepted || changeRequestsSubmitted;

  if (!proposal)
    return (
      <>
        <ViewTitle title="Proposal details" />
        {'Proposal not found'}
      </>
    );

  return (
    <>
      <ViewTitle title={'Proposal details'} />
      <SectionCard hasPadding>
        <Section
          title={
            <SectionTitle
              icon={<PersonIcon color="primary" />}
              title="Publisher"
            />
          }
          content={<PublisherBrief publisher={proposal.publisher} />}
        />
      </SectionCard>
      <SectionCard hasPadding>
        <Section
          title={
            <SectionTitle
              icon={<LightbulbIcon color="primary" />}
              title="Creative Terms"
            />
          }
          content={
            <Box sx={styles.contentContainer}>
              <ListItemSummary
                items={Object.entries(proposal.creativeTerms).map(
                  ([key, value]) => ({ title: key, value }),
                )}
              />
            </Box>
          }
        />
      </SectionCard>
      <SectionCard hasPadding>
        <Section
          title={
            <SectionTitle
              icon={<ReceiptLongIcon color="primary" />}
              title="Line Items"
            />
          }
          content={
            <>
              {proposal.lineItems.map((item, index) => (
                <Box key={index}>
                  <ListItem
                    header={{
                      leftAligned: <Title text={`#${index + 1}`} />,
                      rightAligned: 'L-123-2',
                    }}
                    title={{
                      text: 'The Daily',
                      icon: <BusinessIcon />,
                    }}
                    content={
                      <ListItemSummary
                        items={Object.entries(item).map(([key, value]) => ({
                          title: key,
                          value,
                        }))}
                      />
                    }
                    contentButtonLabel="Show more"
                    alternateContent={
                      <ListItemSummary
                        items={Object.entries(item).map(([key, value]) => ({
                          title: key,
                          value,
                        }))}
                      />
                    }
                    alternateButtonLabel="Show less"
                  />
                </Box>
              ))}
            </>
          }
        />
      </SectionCard>
      <SectionCard variant="header">
        <Box sx={styles.spendContainer}>
          <FieldValueHelper
            title="Total projected spend"
            helper="Total projected spend"
            value="Calculated from the above"
          />
          <Typography variant="h4">{proposal.rfp.maximumSpend}</Typography>
        </Box>
      </SectionCard>
      <SectionCard variant="header">
        <Box sx={styles.spendContainer}>
          <FieldValueHelper
            title="Total projected spend"
            helper="Total projected spend"
            value="Calculated from the above"
          />
          <Typography variant="h4">{proposal.rfp.maximumSpend}</Typography>
        </Box>
      </SectionCard>
      {changeRequestsVisible && (
        <SectionCard hasPadding>
          <ChangeRequestWidgets
            changeRequests={[
              {
                description: 'Here goes the description of change request 1.',
                comment: 'Here goes the comment of change request 1.',
              },
              {
                description: 'Here goes the description of change request 2.',
                comment: 'Here goes the comment of change request 2.',
              },
              {
                description: 'Here goes the description of change request 3.',
                comment: 'Here goes the comment of change request 3.',
              },
            ]}
            editable={!changeRequestsSubmitted}
          />
        </SectionCard>
      )}
      {stateDetailVisible && (
        <StateDetail
          icon={<CheckCircleOutlineIcon color="primary" />}
          messaging={stateDetailMessaging}
          mailto="support@wellput.io"
        />
      )}

      {!changeRequestsVisible && !proposalDeclined && !proposalAccepted && (
        <Box sx={styles.buttonContainer}>
          <Button
            variant="outlined"
            sx={styles.declineProposalButton}
            onClick={() => setProposalDeclined(true)}
          >
            Decline proposal
          </Button>
          <Button
            variant="contained"
            sx={styles.requestChangesButton}
            onClick={() => setChangeRequestsVisible(true)}
          >
            Request changes
          </Button>
          <Button
            variant="contained"
            sx={styles.acceptProposalButton}
            endIcon={<ArrowRightAltIcon />}
            onClick={() => setProposalAccepted(true)}
          >
            Accept proposal
          </Button>
        </Box>
      )}
      {changeRequestsVisible && !changeRequestsSubmitted && (
        <Box sx={styles.buttonContainer}>
          <Button
            variant="contained"
            sx={styles.submitChangeRequestsButton}
            endIcon={<ArrowRightAltIcon />}
            onClick={() => {
              setChangeRequestsSubmitted(true);
              setChangeRequestsVisible(false);
            }}
          >
            Submit change requests
          </Button>
        </Box>
      )}
    </>
  );
};

export default ProposalDetails;

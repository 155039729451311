import { StyleRules } from "@mui/styles";

export const stateDetailStyles: StyleRules = {
    wrapper: {
        textAlign: "center",
    },
    icon: {
        paddingTop: "40px",
    },
    message: {
        padding: "18px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "24px",
    },
    mailTo: {
        paddingBottom: "60px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
    },
};

import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Field } from "../../formGenerator.interface";

export const DateInput = ({ label, value, onChange }: Field) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                label={label}
                inputFormat="MM/dd/yyyy"
                value={value}
                onChange={(e) => {
                    const date = e ? new Date(e) : new Date();
                    onChange(new Date(date));
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
};

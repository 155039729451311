import { Title } from '../../../common/titles/title/title';
import { SectionProps } from './sectionProps';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { LineItem } from '../lineItem.interface';
import { conditions } from '../conditions';
import React from 'react';
import { Conditional } from '../../../common/conditional/conditional';
import { NumericFormat } from 'react-number-format';

export const DeliverySection = ({
  lineItem,
  setLineItem,
  isValidField,
  getFieldMessage,
}: SectionProps) => {
  // Updates the delivery section fields
  const updateField = (attribute: keyof LineItem) => (eve) => {
    let updatedLineItem: Partial<LineItem> = {
      ...lineItem,
    };
    if (['pacingLimit'].includes(attribute)) {
      updatedLineItem.pacingLimit = +eve.target.value.split(',').join('');
    } else if (['startTimestamp, endTimestamp'].includes(attribute)) {
      if (!!eve && !!eve.$y && isNaN(eve.$y)) {
        delete updatedLineItem[attribute];
      } else {
        const timestamp: number = Math.floor(
          attribute === 'startTimestamp'
            ? new Date(eve as number).setUTCHours(0, 0, 0, 1) / 1000
            : new Date(eve as number).setUTCHours(23, 59, 59, 0) / 1000,
        );
        updatedLineItem = {
          ...updatedLineItem,
          [attribute]: timestamp,
        };
      }
    } else if (['pacingPeriod'].includes(attribute)) {
      const value = eve.target.value;
      // If none then removing both period and limit makes these optional in the validator
      if (value === 'None') {
        delete updatedLineItem.pacingPeriod;
        delete updatedLineItem.pacingLimit;
      } else {
        updatedLineItem.pacingPeriod = value;
      }
    }
    setLineItem(updatedLineItem);
  };
  return (
    <>
      <Box sx={{ my: 3 }}>
        <Title text="DELIVERY" />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <DatePicker
            label="Start Date"
            disablePast
            renderInput={(params) => (
              <TextField
                {...params}
                error={!isValidField(lineItem, 'startTimestamp')}
                helperText={getFieldMessage('startTimestamp')}
              />
            )}
            value={
              lineItem.startTimestamp ? lineItem.startTimestamp * 1000 : null
            }
            onChange={(e: any) => {
              let updatedLineItem: Partial<LineItem> = {
                ...lineItem,
              };
              if (!!e && isNaN(e.$y)) {
                delete updatedLineItem.startTimestamp;
              } else {
                updatedLineItem.startTimestamp = Math.floor(
                  new Date(e as number).setUTCHours(0, 0, 0, 1) / 1000,
                );
              }
              setLineItem(updatedLineItem);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            label="End Date (Optional)"
            disablePast
            renderInput={(params) => (
              <TextField
                {...params}
                error={!isValidField(lineItem, 'endTimestamp')}
                helperText={getFieldMessage('endTimestamp')}
              />
            )}
            value={
              !!lineItem.endTimestamp ? lineItem.endTimestamp * 1000 : null
            }
            onChange={(e: any) => {
              let updatedLineItem: Partial<LineItem> = {
                ...lineItem,
              };
              if (!!e && isNaN(e.$y)) {
                delete updatedLineItem.endTimestamp;
              } else {
                updatedLineItem.endTimestamp = Math.floor(
                  new Date(e as number).setUTCHours(23, 59, 59, 0) / 1000,
                );
              }
              setLineItem(updatedLineItem);
            }}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Conditional predicate={conditions.pacingPeriod(lineItem)}>
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <InputLabel>Pacing Period</InputLabel>
              <Select
                label="Pacing Period"
                error={!isValidField(lineItem, 'pacingPeriod')}
                onChange={updateField('pacingPeriod')}
                value={lineItem.pacingPeriod || 'None'}
              >
                <MenuItem value={'None'}>None</MenuItem>
                <MenuItem value={'PT1H'}>Hourly</MenuItem>
                <MenuItem value={'P1D'}>Daily</MenuItem>
                <MenuItem value={'P7D'}>Weekly</MenuItem>
                <MenuItem value={'P1M'}>Monthly</MenuItem>
              </Select>
              <FormHelperText error>
                {getFieldMessage('pacingPeriod')}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Conditional>
        <Conditional predicate={conditions.pacingLimit(lineItem)}>
          <Grid item xs={4}>
            <NumericFormat
              customInput={TextField}
              required
              variant="outlined"
              label="Pacing Limit"
              thousandSeparator={true}
              onChange={updateField('pacingLimit')}
              error={!isValidField(lineItem, 'pacingLimit')}
              helperText={getFieldMessage('pacingLimit')}
              value={lineItem.pacingLimit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{'sends'}</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Conditional>
      </Grid>
    </>
  );
};

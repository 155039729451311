import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { makeStyles } from '@mui/styles';

// import { DraggableFileUpload } from '../../common/draggableFileUpload/draggableFileUpload';
import { Organization, OrganizationFormProps } from './organization.interface';
import { viewStyles } from '../../styles/view.style';
import { useAppDispatch } from '../../hooks/redux.hook';
import { createRecord, updateRecord } from '../../store/organizations.slice';
// import { FormGeneratorProps } from '../../common/formGenerators/formGenerator.interface';
import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
const useStyles = makeStyles(() => viewStyles);

export const OrganizationForm: React.FC<OrganizationFormProps> = (props) => {
  const [company, setCompany] = useState(props.organization);
  const [imageUpload] = useState<Blob>();
  const dispatch = useAppDispatch();
  const companyHasLogo = !!company?.logomarkResource?.content || imageUpload;
  const handleInputChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCompany({
        ...company,
        [prop]: event.target.value,
      });
      console.log('company: ', company);
    };
  const handleCheckedChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCompany({
        ...company,
        [prop]: event.target.checked,
      });
      console.log('company: ', company);
    };
  const updateCompanyProp = (prop: string) => (value: any) => {
    setCompany({ ...company, [prop]: value });
  };

  // const updateLogo = () => {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     const logo = document.getElementById('avatar') as HTMLImageElement;
  //     logo.src = reader.result as string;
  //   };
  //   const input = document.getElementById('logo-upload') as HTMLInputElement;
  //   const file = input && input.files ? (input.files[0] as Blob) : null;
  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }
  // };
  const saveOrganization = () => {
    console.log('saveOrganization hit ', company);
    dispatch(
      (company?.id
        ? updateRecord(company as Organization)
        : createRecord(company as Partial<Organization>)) as any,
    );
    if (props.toggleVisibility) props.toggleVisibility();
  };

  // const organizationFormConfig: FormGeneratorProps = {
  //   maxWidth: '328px',
  //   fields: [
  //     {
  //       id: 'organizationName',
  //       label: 'Company name',
  //       onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
  //         updateCompanyProp('name')(event),
  //       value: company?.name,
  //     },
  //     {
  //       id: 'organizationMediaKit',
  //       label: 'Media kit link (optional)',
  //       alternative: DraggableFileUpload({
  //         text: 'Drop your media kit here or click to brose',
  //         fileName: 'mediaKit',
  //         onFileUpload: (event: any) => updateCompanyProp('mediaKit')(event),
  //       }),
  //       onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
  //         updateCompanyProp('mediaKit')(event),
  //       value: company?.mediaKit?.url,
  //     },
  //   ],
  // };
  const classes = useStyles();
  return (
    <div className={classes.objectViewContainer}>
      <Box>
        <Box sx={{ mb: '1rem' }}>
          <Typography variant="h6">Logo (optional)</Typography>
          <div className={classes.avatar}>
            {companyHasLogo ? (
              <img alt={''} />
            ) : (
              <Avatar
                id="avatar"
                className={classes.avatar}
                variant="square"
              ></Avatar>
            )}
          </div>

          <Button
            variant="contained"
            color="primary"
            className={classes.avatarUploadButton}
            component="label"
          >
            <AddAPhotoOutlinedIcon sx={{ p: '3px' }} />
            Add Logo
            <input
              id="logo-upload"
              type="file"
              accept="image/*"
              hidden
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const fileUpload =
                  Array.isArray(e.target?.files) && e.target.files[0]
                    ? e.target.files[0]
                    : undefined;
                if (fileUpload) {
                  updateCompanyProp('logo')(fileUpload);
                }
              }}
            />
          </Button>
        </Box>
        <Box>
          <TextField
            required
            id="outlined-required"
            label="Name"
            value={company?.name || ''}
            onChange={handleInputChange('name')}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="isAdvertiser"
                  onChange={handleCheckedChange('isAdvertiser')}
                  checked={company?.isAdvertiser || false}
                />
              }
              label="Advertiser"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="isPublisher"
                  onChange={handleCheckedChange('isPublisher')}
                  checked={company?.isPublisher || false}
                />
              }
              label="Publisher"
            />
          </FormGroup>
        </Box>
        {/* <FormGenerator
          fields={organizationFormConfig.fields}
          maxWidth={organizationFormConfig.maxWidth}
        /> */}
      </Box>
      <Box className={classes.actions}>
        <Button
          variant="outlined"
          className={classes.actionButton}
          onClick={props?.toggleVisibility}
        >
          {'Cancel'}
        </Button>
        <Button
          variant="contained"
          className={classes.actionButton}
          onClick={saveOrganization}
        >
          {'Save'}
        </Button>
      </Box>
    </div>
  );
};

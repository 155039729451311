import React from "react";
import { ActionProps, ViewContext, ViewContextProps } from "../../common/types/view";
export type FlowRouteParams = {
	[Property in keyof ViewContextProps]: ViewContextProps[Property];
};
export type IndexedRoutes = { [index: number]: FlowRoute };
export interface FlowRoute {
	component: JSX.Element;
	params?: FlowRouteParams;
}
export class FlowRouter {
	private _routes: IndexedRoutes;
	private _firstStep: number;
	private _lastStep: number;
	private _currentStep: number;
	public instanceName: string;

	constructor(
		private flow: JSX.Element[],
		params?: FlowRouteParams,
		name?: string,
		firstStep: number = 0
	) {
		this.instanceName = name || "Default";
		this._routes = this.makeRoutes(params);
		this._currentStep = 0;
		this._firstStep = firstStep;
		this._lastStep = flow.length - 1;
	}
	private setCurrentStep(step: number) {
		this._currentStep = step;
	}
	public get currentStep() {
		return this._currentStep;
	}
	renderWithProps(component: JSX.Element, props: FlowRouteParams): JSX.Element {
		const clone = React.cloneElement(component, {
			...props,
		});
		return clone;
	}

	makeRoutes(params: FlowRouteParams = {}): IndexedRoutes {
		return this.flow.reduce(
			(collector: IndexedRoutes, step: JSX.Element, index: number) => {
				collector[index] = {
					component: this.renderWithProps(step, {
						...params,
						viewContext: ViewContext.FLOW,
					}),
				};
				return collector;
			},
			{}
		);
	}
	renderCurrent(): JSX.Element {
		const currentRoute = this._routes[this.currentStep];
		return currentRoute.component;
	}
	renderCurrentWithProps(props: FlowRouteParams): JSX.Element {
		const currentRoute = this.renderCurrent();
		return React.cloneElement(currentRoute, {
			...props,
		});
	}
	renderCurrentControls(controls: ActionProps): JSX.Element {
		const { component } = this._routes[this.currentStep];
		return this.renderWithProps(component, {
			...component.props,
			...controls,
		});
	}
	renderStepControls(controls: {
		next: () => void;
		previous: () => void;
	}): JSX.Element {
		const { component } = this._routes[this.currentStep];
		return this.renderWithProps(component, {
			...component.props,
			...controls,
		});
	}
	// navigateToCurrrent(): FlowRoute {
	// 	return this._routes[this.currentStep];
	// }
	navigateForward(): void {
		const nextStep = this.currentStep + 1;
		if (nextStep > this._lastStep) return;

		return this.setCurrentStep(nextStep);
	}
	navigateBackward(): void {
		const previousStep = this.currentStep - 1;
		if (previousStep < 0) return;

		return this.setCurrentStep(previousStep);
	}
	navigateToBeginning(): void {
		const theBegining: number = 0;
		return this.setCurrentStep(theBegining);
	}
	navigateToFirstStep(): void {
		return this.setCurrentStep(this._firstStep);
	}
	navigateToEnd(): void {
		return this.setCurrentStep(this._lastStep);
	}
	navigateToStep(step: number): void {
		if (0 <= step && this._lastStep >= step) {
			return this.setCurrentStep(step);
		}
	}
}

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";

// import { Currency, formatMoney } from "../../utilities/money.util";
import { ProposalsProps } from "./proposals.interface";
import { formatTimestamp } from "../../utilities/date.util";
import { ViewContext } from "../../common/types/view";
import ViewTitle from "../../common/titles/viewTitle/viewTitle";
import { Organization } from "../organizations/organization.interface";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { ReduxState } from "store/store.interface";
import { readAll } from "store/proposals.slice";

const proposalsColumns = (): GridColDef[] => {
  const renderDate = (params: GridCellParams) => {
    const timestamp = params.value?.toString() || "";
    return <>{formatTimestamp(timestamp)}</>;
  };

  return [
    {
      field: "name",
      headerName: "Proposal",
      width: 170,
      sortable: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Link underline="always" href={`/proposals/${params.id}`}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "publisher",
      headerName: "Publisher",
      width: 170,
      renderCell: (params: GridCellParams) => {
        return <>{(params.value as Organization).name}</>;
      },
    },
    {
      field: "creationTimestamp",
      headerName: "Created",
      width: 170,
      renderCell: renderDate,
    },
    {
      field: "expirationTimestamp",
      headerName: "Expires",
      width: 170,
      renderCell: renderDate,
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 170,
      renderCell: (params: GridCellParams) => <Chip label={params.value} />,
    },
  ];
};

export const Proposals: React.FC<ProposalsProps> = (props) => {
  const dispatch = useAppDispatch();
  const columnMap = proposalsColumns();
  const [fetched, setFetched] = useState(false);
  const proposals = useAppSelector((state: ReduxState) => state.proposals);
  useEffect(() => {
    if (proposals.length === 0 && !fetched) {
      dispatch(readAll() as any);
      setFetched(true);
    }
  }, [proposals.length, fetched, dispatch]);

  return (
    <>
      <Box display={"flex"}>
        <Box p={2} flexGrow={1}>
          <ViewTitle
            {...{
              title: "Proposals",
              viewContext: props.viewContext || ViewContext.DEFAULT,
            }}
          />
        </Box>
        <Box>
          <TextField
            id="search-proposals"
            label="Search proposals"
            variant="outlined"
          />
        </Box>
      </Box>
      {proposals.length === 0 ? (
        <>{"No Proposals were found"}</>
      ) : (
        <DataGrid
          rows={[...proposals].sort((a, b) => a.id - b.id)}
          columns={columnMap}
          autoHeight
        />
      )}
    </>
  );
};

export default Proposals;

import React from 'react';
import Typography from '@mui/material/Typography';

import MailToButton from '../buttons/mailTo.button';

interface AssistanceProps {}

const Assistance: React.FC<AssistanceProps> = (props: AssistanceProps) => {
  return (
    <>
      <Typography
        id={'get-assistance'}
        {...{ variant: 'subtitle2', style: { marginTop: 10, marginBottom: 5 } }}
      >
        {'Want assistance? '}
        <MailToButton
          {...{ mailto: 'mailto:help@wellput.io', label: 'Get Help' }}
        />
      </Typography>
    </>
  );
};

export default Assistance;

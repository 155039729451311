import { StyleRules } from "@mui/styles";

export const radioStyles: StyleRules = {
    radio: {
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: "rgba(196, 196, 196, 0.1)",
        borderRadius: "5px",
        // minWidth: "516px",
    },
};

import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormHelperText } from '@mui/material';

const Inline = Quill.import('blots/inline');
class LinkBlot extends Inline {
  static create(value) {
    let node = super.create();
    node.setAttribute('href', value);
    return node;
  }

  static formats(node) {
    return node.getAttribute('href');
  }
}
LinkBlot.blotName = 'link';
LinkBlot.tagName = 'a';

Quill.register(LinkBlot);

interface EditorProps {
  label: string;
  value: string;
  setValue: (value: string) => void;
  hasLinks: boolean;
  isRequired: boolean;
  maxLength: number;
}
export const Editor: React.FC<EditorProps> = (
  props: EditorProps,
): JSX.Element => {
  const linkVariable = '{{{CLICK_URL}}}';

  const modules = {
    toolbar: [
      props.hasLinks ? ['bold', 'italic', 'link'] : ['bold', 'italic'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  const formats = props.hasLinks
    ? ['header', 'bold', 'italic', 'list', 'bullet', 'link']
    : ['header', 'bold', 'italic', 'list', 'bullet'];

  const replaceHref = (htmlString, newHref) => {
    return htmlString.replace(/(<a href=")[^"]*/g, '$1' + newHref);
  };

  const [isRawHTML, setIsRawHTML] = useState(true);
  const sanitizeAndSetValue = (value: string) => {
    if (value.includes('<p><br></p>')) {
      value = value.split('<p><br></p>').join('');
    }
    if (value.includes('&nbsp')) {
      value = value.split('&nbsp').join(' ');
    }
    value = value.trim();
    props.setValue(value);
  };
  function isValidHTMLParagraph(text) {
    // Regular expression to match a valid HTML paragraph
    var regex = /^<p>(.*?)<\/p>$/;
    return regex.test(text);
  }

  return (
    <Box sx={{ py: 4 }}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography>{props.label}</Typography>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isRawHTML} />}
            label="Use Raw HTML"
            onChange={() => setIsRawHTML(!isRawHTML)}
          />
        </FormGroup>
      </Box>
      {!isRawHTML ? (
        <ReactQuill
          theme="snow"
          value={props.value}
          onChange={(val, delta) => {
            console.log('values match? ', props.value === val);
            console.log('values match? ', { val, delta });
            if (props.value !== val) {
              props.hasLinks
                ? sanitizeAndSetValue(replaceHref(val, linkVariable))
                : sanitizeAndSetValue(val);
            }
          }}
          modules={modules}
          formats={formats}
        />
      ) : (
        <TextField
          onChange={(val) => {
            props.hasLinks
              ? sanitizeAndSetValue(replaceHref(val.target.value, linkVariable))
              : sanitizeAndSetValue(val.target.value);
          }}
          value={props.value}
          rows={10}
          multiline
          fullWidth
          error={!isValidHTMLParagraph(props.value)}
          required={props.isRequired}
        />
      )}
      <FormHelperText error>
        {props.isRequired &&
        (!Boolean(props.value) || props.value === '<p><br></p>')
          ? 'This field is required'
          : ''}
        {props.value.length > props.maxLength
          ? `Content is longer than ${props.maxLength}`
          : ''}
      </FormHelperText>
    </Box>
  );
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};

export const toCapitilized = (str: string) => {
  if (!str) return '';
  else {
    return str
      .match(/[A-Za-z][a-z]*/g)!
      .map(capitalize)
      .join(' ');
  }
};

export const idToReactKey = (id: string) => {
  return id.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase();
};

export const camelToKebab = (name: string): string => {
  return name.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

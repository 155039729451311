import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import LaunchIcon from "@mui/icons-material/Launch";

import { PublisherBriefProps } from "./proposalDetails.interface";

import styles from "../../../styles/publisherBrief.styles";

const PublisherBrief: React.FC<PublisherBriefProps> = ({
  publisher,
}: PublisherBriefProps): JSX.Element => {
  const logoImg = publisher?.logomarkResource?.content
    ? URL.createObjectURL(
        new Blob([new Uint8Array(publisher?.logomarkResource.content)])
      )
    : undefined;
  return (
    <Box sx={styles.container}>
      <img
        style={{ width: "140px", height: "140px" }}
        src={logoImg}
        alt="publisher logo"
      />
      <Box sx={styles.infoContainer}>
        <Typography variant="h4">{publisher.name}</Typography>
        <Link
          href={publisher.mediaKit?.url}
          variant="body2"
          sx={styles.website}
        >
          {publisher.mediaKit?.url}
        </Link>
        <Button
          variant="contained"
          href={publisher.mediaKit?.url}
          endIcon={<LaunchIcon />}
          sx={styles.mediaKitButton}
        >
          Media kit
        </Button>
      </Box>
    </Box>
  );
};

export default PublisherBrief;

import * as React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import { UserAuth } from './auth/userAuth';
import { UserOrganizationButton } from '../selectors/user.organization.button';
import { useAppSelector } from '../../hooks/redux.hook';
import { ReduxState } from '../../store/store.interface';
import { Button } from '@mui/material';
import { ButtonStyles } from '../../styles/button.style';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ButtonStyles);
export const UserWidget: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { user } = useAppSelector((state: ReduxState) => state);
  return (
    <div className={classes.userWidget}>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
        ref={anchorRef}
        disableElevation
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderBottom: 'none',
          borderRadius: '10px 10px 0 0',
          backgroundColor: '#FFF',
        }}
      >
        <Button
          disableElevation
          disableRipple
          variant="text"
          sx={{
            ':hover': {
              backgroundColor: 'none',
              cursor: 'default',
            },
            outline: 'none !important',
          }}
        >
          {user.username}
        </Button>
        <UserOrganizationButton anchorRef={anchorRef} />
        <UserAuth />
      </ButtonGroup>
    </div>
  );
};

import React from "react";
import ViewTitle from "../../common/titles/viewTitle/viewTitle";

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = (
	props: NotFoundProps
): JSX.Element => (
	<>
		<ViewTitle title={"Not Found"} />
	</>
);

export default NotFound;

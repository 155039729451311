import React, { useEffect, useState } from 'react';
import { LineItem, LineItemViewProps } from './lineItem.interface';
import { Box, Button, Grid } from '@mui/material';
import { viewStyles } from '../../styles/view.style';
import { makeStyles } from '@mui/styles';
import { ReduxState } from '../../store/store.interface';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { readById, updateRecord } from '../../store/lineItems.slice';
import ViewTitle from '../../common/titles/viewTitle/viewTitle';
import { ViewContext } from '../../common/types/view';
import { Title } from '../../common/titles/title/title';
import { timestampToTextFromSeconds } from '../../common/view/date';
import { NewsletterDetails } from '../newsletters/newsletter.interface';
import { AdUnit } from '../adUnit/adUnit.interface';
import { readAll as realAllNewsletters } from '../../store/newsletter.slice';
import { readAll as readAllAdUnits } from '../../store/adUnit.slice';
import { readAll as readAllCreatives } from '../../store/creatives.slice';
import { readAll as readAllBrands } from '../../store/brands.slice';
import { useParams } from 'react-router-dom';
import { periodToText } from '../../common/view/period';
import { Organization } from '../organizations/organization.interface';
import { currencyToText } from '../../common/view/currency';
import { useAuthentication } from '../../common/user/auth/useAuthentication.hook';
import { Creative } from '../creatives/creative.interface';
import { CreativesCompactTable } from '../creatives/creatives.compact.table';
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { FieldValueHelper } from '../../common/fields/display/fieldValueHelper';
import { Brand } from '../brands/brand.interface';

const useStyles = makeStyles(() => viewStyles);
export const LineItemView: React.FC<LineItemViewProps> = (
  props: LineItemViewProps,
): JSX.Element => {
  const { isAuthenticated } = useAuthentication();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams<{ id?: string }>();
  const lineItemId = params.id;
  const [fetchedNewletters, setFetchedNewsletters] = useState<boolean>(false);
  const [fetchedAdUnits, setFetchedAdUnits] = useState<boolean>(false);
  const [fetchedBrands, setFetchedBrands] = useState<boolean>(false);
  const [fetchedCreatives, setFetchedCreatives] = useState<boolean>(false);
  const [fetchedLineItem, setFetchedLineItem] = useState<boolean>(false);
  const [editCreatives, setEditCreatives] = useState<boolean>(false);
  const [activeLineItem, setActiveLineItem] = useState<LineItem | undefined>(
    props.lineItem,
  );
  const { adUnits, lineItems, newsletters, organizations, creatives, brands } =
    useAppSelector((state: ReduxState) => state);
  const lineItemSellerName: string | undefined = organizations.find(
    (org: Organization) => org.id === activeLineItem?.organizationId,
  )?.name;
  const updateCreatives = (currentSelection: GridSelectionModel) => {
    const creativeIds: number[] = currentSelection.map((id: GridRowId) => +id);
    if (activeLineItem) {
      setActiveLineItem({
        ...activeLineItem,
        creativeIdsByAdUnitIndex: [creativeIds],
      });
    }
  };
  const persistActiveLineitem = async () => {
    if (activeLineItem) {
      let updatedLineItem: LineItem = { ...activeLineItem };
      delete updatedLineItem.hasBeenActive;
      const result = await dispatch(updateRecord(updatedLineItem) as any);
      if (props.notify) {
        if (!result.error) {
          props.notify('Updated creative assignments.');
        } else {
          props.notify(
            `An error occurred while updating creative assignments., ${result.error.message}`,
          );
        }
      }
      setEditCreatives(false);
    }
  };
  const formatNewsletters = (): string[] => {
    const appliedNewsletterIds: number[] | undefined =
      !!activeLineItem?.blacklistedNewsletterIds ||
      !!activeLineItem?.whitelistedNewsletterIds
        ? activeLineItem?.blacklistedNewsletterIds ||
          activeLineItem?.whitelistedNewsletterIds
        : undefined;

    return appliedNewsletterIds
      ? appliedNewsletterIds.map(
          (id: number) =>
            newsletters.find((news: NewsletterDetails) => id === news.id)
              ?.name || 'Not Found',
        )
      : newsletters.map((news: NewsletterDetails) => news.name);
  };
  const formatAdUnits = (): string[] => {
    const appliedAdUnitIds: number[] = !!activeLineItem?.adUnitIds
      ? activeLineItem.adUnitIds
      : (activeLineItem?.blacklistedNewsletterIds ||
        activeLineItem?.whitelistedNewsletterIds
          ? !!activeLineItem.whitelistedNewsletterIds
            ? adUnits.filter((a: AdUnit) =>
                (activeLineItem.whitelistedNewsletterIds || []).includes(
                  a.newsletterId,
                ),
              )
            : adUnits.filter(
                (a: AdUnit) =>
                  !(activeLineItem?.blacklistedNewsletterIds || []).includes(
                    a.newsletterId,
                  ),
              )
          : adUnits.filter((a: AdUnit) => activeLineItem?.adUnitType === a.type)
        ).map((adUnit: AdUnit) => adUnit.id);

    return !!appliedAdUnitIds
      ? appliedAdUnitIds.map((id: number) => {
          const adUnit = adUnits.find((adUnit: AdUnit) => id === adUnit.id);
          const newsletterName = newsletters.find(
            (news: NewsletterDetails) => adUnit?.newsletterId === news.id,
          )?.name;

          return adUnit?.name && newsletterName
            ? `${newsletterName}: ${adUnit.name}`
            : 'Name Not Found';
        })
      : ['None Found'];
  };
  const assignedCreatives: Creative[] =
    activeLineItem && creatives.length > 0
      ? activeLineItem.creativeIdsByAdUnitIndex.reduce(
          (collection: Creative[], item) => {
            collection = [
              ...collection,
              ...item.map((id: number) =>
                creatives.find((c: Creative) => c.id === id),
              ),
            ] as Creative[];
            return collection;
          },
          [],
        )
      : [];
  const typeFilteredCreatives: Creative[] =
    activeLineItem && creatives.length > 0
      ? creatives.filter(
          (c: Creative) =>
            c.adUnitType === activeLineItem.adUnitType &&
            c.brandId === activeLineItem.brandId,
        )
      : [];
  useEffect(() => {
    if (isAuthenticated) {
      if (!activeLineItem && lineItemId) {
        const lineItem: LineItem | undefined = lineItems.find(
          (li: LineItem) => li.id === +lineItemId,
        );
        if (lineItem) {
          setActiveLineItem(lineItem);
        } else if (!fetchedLineItem) {
          dispatch(readById(+lineItemId) as any);
          setFetchedLineItem(true);
        }
      }
      if (newsletters.length === 0 && !fetchedNewletters) {
        dispatch(realAllNewsletters() as any);
        setFetchedNewsletters(true);
      }
      if (adUnits.length === 0 && !fetchedAdUnits) {
        dispatch(readAllAdUnits() as any);
        setFetchedAdUnits(true);
      }
      if (creatives.length === 0 && !fetchedCreatives) {
        dispatch(readAllCreatives() as any);
        setFetchedCreatives(true);
      }
      if (brands.length === 0 && !fetchedBrands) {
        dispatch(readAllBrands() as any);
        setFetchedBrands(true);
      }
    }
  }, [
    activeLineItem,
    adUnits.length,
    brands.length,
    creatives.length,
    dispatch,
    fetchedAdUnits,
    fetchedBrands,
    fetchedCreatives,
    fetchedLineItem,
    fetchedNewletters,
    isAuthenticated,
    lineItemId,
    lineItems,
    newsletters.length,
  ]);
  return activeLineItem ? (
    <div className={classes.objectViewContainer}>
      {props.viewContext && props.viewContext !== ViewContext.FLOW ? (
        <ViewTitle title="Line Item Review" viewContext={props.viewContext} />
      ) : (
        <></>
      )}
      <Box sx={{ my: 3 }}>
        <Title text="BASICS" />
      </Box>
      <Grid container sx={{ py: 1 }} spacing={4}>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{ title: 'Line Item Name', value: activeLineItem.name }}
          />
        </Grid>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{ title: 'External ID', value: activeLineItem.externalId }}
          />
        </Grid>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{ title: 'Seller', value: lineItemSellerName }}
          />
        </Grid>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{
              title: 'Brand Name',
              value:
                brands.find((b: Brand) => b.id === activeLineItem.brandId)
                  ?.name || '',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldValueHelper
            {...{
              title: 'Click URL Handlebars',
              value: activeLineItem.clickUrlHandlebars,
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ my: 3 }}>
        <Title text="PRICING" />
      </Box>
      <Grid container sx={{ py: 1 }} spacing={4}>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{
              title: 'Advertiser Pricing',
              value: currencyToText(activeLineItem.pricingTerms.advertiserRate),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{
              title: 'Publisher Pricing',
              value: currencyToText(activeLineItem.pricingTerms.publisherRate),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{
              title: 'Estimated Revenue Per Click',
              value: currencyToText(activeLineItem.estimatedRevenuePerClick),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{
              title: 'Budget',
              value: currencyToText(activeLineItem.budget),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{
              title: 'Guarantee',
              value: activeLineItem.guaranteedVolume,
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ my: 3 }}>
        <Title text="DELIVERY" />
      </Box>
      <Grid container sx={{ py: 1 }} spacing={4}>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{
              title: 'Dates',
              value: `${timestampToTextFromSeconds(
                activeLineItem.startTimestamp,
              )} - ${
                !!activeLineItem.endTimestamp
                  ? timestampToTextFromSeconds(activeLineItem.endTimestamp)
                  : '\u221E'
              }`,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{
              title: 'Pacing',
              value: `${activeLineItem.pricingTerms.publisherRate} - ${
                periodToText[activeLineItem.pacingPeriod || 'NONE']
              }`,
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ my: 3 }}>
        <Title text="TARGETING" />
      </Box>
      <Grid container sx={{ py: 1 }} spacing={4}>
        <Grid item xs={4}>
          <FieldValueHelper
            {...{
              title: 'Roadblock',
              value: `${activeLineItem.isRoadblock}`,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldValueHelper
            {...{
              title: 'Ad Unit Type',
              value: activeLineItem.adUnitType,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldValueHelper
            {...{
              title: 'Ad Units',
              children: formatAdUnits().map(
                (adUnitName: string, index: number) => (
                  <Grid
                    item
                    key={`adUnitName${index}`}
                    xs={6}
                    children={adUnitName}
                  />
                ),
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldValueHelper
            {...{
              title: `Newsletter ${
                !!activeLineItem.blacklistedNewsletterIds
                  ? 'Blacklist'
                  : 'Whitelist'
              }`,
              children: formatNewsletters().map(
                (newsletterName: string, index: number) => (
                  <Grid
                    item
                    key={`newsletterName${index}`}
                    xs={6}
                    children={newsletterName}
                  />
                ),
              ),
            }}
          />
        </Grid>
      </Grid>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box sx={{ my: 3 }} display={'flex'}>
          <Title text="CREATIVES" />
        </Box>
        <Box sx={{ my: 3 }} display={'flex'}>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={() =>
              editCreatives ? persistActiveLineitem() : setEditCreatives(true)
            }
            color="info"
          >
            {editCreatives
              ? 'Save Creative Assignments'
              : 'Edit Creative Assignments'}
          </Button>
        </Box>
      </Box>
      <Grid container sx={{ py: 1 }} spacing={4}>
        <Grid item xs={12}>
          <CreativesCompactTable
            {...{
              creatives: editCreatives
                ? typeFilteredCreatives
                : assignedCreatives,
              brands,
              organizations,
              selected: activeLineItem.creativeIdsByAdUnitIndex[0],
              onSelection: updateCreatives,
              readonly: !editCreatives,
            }}
          />
        </Grid>
      </Grid>
      <Box className={classes.actions}>
        {props.toggleVisibility ? (
          <Button
            variant="outlined"
            className={classes.actionButton}
            onClick={props?.toggleVisibility}
          >
            {'Done'}
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </div>
  ) : (
    <>{'No Line Item Found'}</>
  );
};

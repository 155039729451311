import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DraggableFileUploadProps } from './draggableFileUpload.interface';
import { UploadFileIcon } from '../../common/images/icons/uploadFile';
import { IconButton, Paper } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useAppSelector } from '../../hooks/redux.hook';
import { ReduxState } from '../../store/store.interface';
import { createResource, deleteRecord } from '../../store/resources.slice';
import { ResourceDetails } from 'views/resource/resource.interface';
import { useDispatch } from 'react-redux';
import { CreativeResourceTypes } from 'views/creatives/creative.interface';
import { DisplayImage } from '../fields/display/image';
const useStyles = makeStyles(() => ({
  dropZone: {
    boxSizing: 'border-box',
    borderRadius: '5px',
    textAlign: 'center',
    padding: '2rem',
    position: 'relative',
  },
  input: {
    display: 'none',
  },
}));

export const FileUpload = ({
  label,
  resourceId,
  text,
  fieldName,
  onFileUpload,
  onFileDelete,
}: DraggableFileUploadProps) => {
  const { user } = useAppSelector((state: ReduxState) => state);
  const { activeOrganizationId } = user;
  const [attached, setAttached] = useState<string>('');
  const [activeId, setActiveId] = useState<number>();
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const dispatch = useDispatch();

  const processImage = async (imageUpload: File) => {
    const form = new FormData();
    form.append('content', imageUpload);
    form.append('organizationId', `${activeOrganizationId}`);
    createResource(form, user.accessToken).then((response) => {
      const res: ResourceDetails = response?.data as ResourceDetails;
      setActiveId(res.id);
      onFileUpload(res.id);
    });
  };
  const removeImage = (type: 'logotypeResourceId' | 'imageResourceId') => {
    const input: any = document.getElementById(`${fieldName}-input-file`);
    if (input) {
      input.value = null;
    }
    setAttached('');
    setPreviewUrl('');
    if (activeId) dispatch(deleteRecord(activeId) as any);
    onFileDelete(type);
  };
  const onDrop = (e: Event) => {
    const event = e as Event & { dataTransfer: DataTransfer };
    event.stopPropagation();
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    processImage(file);
    return false;
  };

  const onDragOver = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
    return false;
  };

  useEffect(() => {
    document.addEventListener('dragover', onDragOver, false);
    document.addEventListener('drop', onDrop, false);
    return () => {
      document.removeEventListener('dragover', onDrop);
      document.removeEventListener('drop', onDrop);
    };
  });

  const classes = useStyles();
  const message = 'Upload';
  const hasExistingValue: boolean = !!previewUrl || !!resourceId;
  const reader = new FileReader();

  reader.onloadend = () => {
    if (reader?.result) {
      setPreviewUrl(reader.result as string);
    }
  };

  return (
    <Box sx={{ py: 4 }}>
      <Typography>{label}</Typography>
      <Paper className={classes.dropZone} id={`${fieldName}-drop-zone`}>
        <Box component="label">
          {hasExistingValue ? (
            <DisplayImage
              {...{
                alt: text || '',
                title: '',
                ...(!!resourceId && { imageId: resourceId }),
                ...(!!previewUrl && { imageUrl: previewUrl }),
              }}
            />
          ) : (
            <Box>
              <Box>
                <UploadFileIcon />
              </Box>
              <input
                data-testid="file-uploader"
                id={`${fieldName}-input-file`}
                type="file"
                name={fieldName}
                className={classes.input}
                onChange={(event: any) => {
                  let text = message;
                  if (event.target.files[0]) {
                    text = event?.target?.files[0].name || undefined;
                    setAttached(text);
                    reader.readAsDataURL(event?.target?.files[0]);
                    processImage(event?.target?.files[0]);
                  }
                }}
              />
              <Typography>{attached || message}</Typography>
            </Box>
          )}
        </Box>
        {hasExistingValue ? (
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            style={{
              position: 'absolute',
              bottom: 0,
              right: '5px',
            }}
            onClick={() => removeImage(fieldName as CreativeResourceTypes)}
          >
            <RemoveCircleIcon />
          </IconButton>
        ) : null}
      </Paper>
    </Box>
  );
};

// export as higher order component
export const DraggableFileUpload = (props: DraggableFileUploadProps) => () => {
  return <FileUpload {...props} />;
};

import { Organization } from '../organizations/organization.interface';
import { ViewContextProps } from '../../common/types/view';

export interface Brand {
  id: number;
  organizationId: number;
  name: string;
  url: string;
  valueProposition: string;
  logomarkResourceId?: number;
  logotypeResourceId?: number;
}
export interface BrandViewProps extends ViewContextProps {
  brand?: Brand;
  id?: number;
  toggleVisibility?: () => void;
}
export interface BrandFormProps {
  brand?: Brand | Partial<Brand>;
  toggleVisibility: () => void;
  notify: (message: string) => void;
}
export type BrandTableProps = {
  edit: (org?: Brand) => void;
  brands: Brand[];
  organizations: Organization[];
  view: (brand?: Brand) => void;
};
export const MAX_BRAND_NAME_LENGTH = 50;
export const MAX_VALUE_PROPOSITION_LENGTH = 100;

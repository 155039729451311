import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { User } from '../common/user/user.interface';
import { ModelTypesWithId } from '../common/types/models';
import { ApiService } from '../services/api.service';
import { ReduxState } from './store.interface';
/**
 * @class GenericCRUDActions<T>
 * @Params objectName String "Campaign, Proposal"
 */
export class GenericCRUDActions<T extends ModelTypesWithId> {
  private api: ApiService<T>;
  private apiVersion: string = 'v1';
  constructor(private objectName: string) {
    this.api = new ApiService<T>();
  }
  /**
   * @Function readById
   * @Params id Number
   * @Description Read an object of a given type from the API
   */
  readById: AsyncThunk<T, number, {}> = createAsyncThunk(
    `${this.apiVersion}/${this.objectName}/readById`,
    async (id: number, { getState }): Promise<T> => {
      const state: ReduxState = getState() as ReduxState;
      const { user }: { user: User } = state;
      const response = await this.api.get(
        `${this.apiVersion}/${this.objectName}/${id}`,
        user.accessToken,
      );
      return response.data as T;
    },
  );
  /**
   * @Function readAll
   * @Description Read all objects of a given type from the API
   */
  readAll: AsyncThunk<T[], undefined, {}> = createAsyncThunk<T[], undefined>(
    `${this.apiVersion}/${this.objectName}/readAll`,
    async (_, { getState }): Promise<T[]> => {
      const state: ReduxState = getState() as ReduxState;
      const { user }: { user: User } = state;
      const response = await this.api.get(
        `${this.apiVersion}/${this.objectName}`,
        user.accessToken,
      );
      return response.data as T[];
    },
  );
  /**
   * @Function createRecord
   * @Params object T
   * @Description Create a new record in the API
   */
  createRecord: AsyncThunk<T, Partial<T>, {}> = createAsyncThunk(
    `${this.apiVersion}/${this.objectName}/create`,
    async (object: Partial<T>, { getState }): Promise<T> => {
      const state: ReduxState = getState() as ReduxState;
      const { user }: { user: User } = state;
      const response = await this.api.post(
        `${this.apiVersion}/${this.objectName}`,
        object,
        user.accessToken,
      );
      if (response.error) throw response.error;
      return response.data as T;
    },
  );
  /**
   * @Function updateRecord
   * @Params object T
   * @Description Update a record in the API
   */
  updateRecord: AsyncThunk<T, T, {}> = createAsyncThunk(
    `${this.apiVersion}/${this.objectName}/update`,
    async (object: T, { getState }): Promise<T> => {
      const state: ReduxState = getState() as ReduxState;
      const { user }: { user: User } = state;
      const endpoint = `${this.apiVersion}/${this.objectName}/${object.id}`;
      const response = await this.api.put(endpoint, object, user.accessToken);
      if (response.error) throw new Error(response.error.message.join(';'));
      return response.data as T;
    },
  );
  /**
   * @Function deleteRecord
   * @Params id Number
   * @Description Delete a record in the API
   */
  deleteRecord: AsyncThunk<number, number, {}> = createAsyncThunk(
    `${this.apiVersion}/${this.objectName}/delete`,
    async (id: number, { getState }): Promise<number> => {
      const state: ReduxState = getState() as ReduxState;
      const { user }: { user: User } = state;
      const result: any = await this.api.delete(
        `${this.apiVersion}/${this.objectName}/${id}`,
        user.accessToken,
      );
      if (result && result.data && result.data.affected === 1) {
        return id;
      }
      return 0;
    },
  );
}
export type CrudActionKeys<T extends ModelTypesWithId> =
  keyof GenericCRUDActions<T>;
export type CrudActionEssential<T extends ModelTypesWithId> = Omit<
  GenericCRUDActions<T>,
  'objectName' | 'http'
>;
export type CrudActionEssentialKeys<T extends ModelTypesWithId> =
  keyof CrudActionEssential<T>;
export type CrudActionLimited<T extends ModelTypesWithId> = Pick<
  GenericCRUDActions<T>,
  'updateRecord' | 'readById'
>;
export type CrudActionLimitedKeys<T extends ModelTypesWithId> =
  keyof CrudActionLimited<T>;

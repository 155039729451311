import { idToReactKey } from "../../../../utilities/format.util";
import { BaseField, BaseFieldTypes } from "../../formGenerator.interface";
import TextField from "@mui/material/TextField";
import { FC } from "react";

export type TextFieldProps = BaseField & BaseFieldTypes;
export const TextInputField: FC<TextFieldProps> = ({
    label,
    id,
    onChange,
    value,
    helperText,
    minRows,
}) => {
    return (
        <TextField
            data-testid={"text-input"}
            id={idToReactKey(id)}
            key={`${idToReactKey(label)}-field`}
            label={label}
            margin="normal"
            variant="filled"
            onChange={onChange}
            fullWidth
            InputProps={{
                disableUnderline: true,
            }}
            defaultValue={value}
            helperText={helperText}
            multiline={minRows ? true : false}
            minRows={minRows}
        />
    );
};

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SectionTitle from "../../../../common/titles/sectionTitle";
import GridViewIcon from "@mui/icons-material/GridView";
import DetailsBoxTitle from "../../../../common/titles/detailsBoxTitle";
import { RfpDTO } from "../rfpDetails.interface";
import FlexibleValue from "../../../../common/flexibleValue/flexibleValue";

interface CollaborationProps {
	data: RfpDTO;
}

const Collaboration: React.FC<CollaborationProps> = ({ data }): JSX.Element => (
	<Box marginTop="1.5rem">
		<SectionTitle
			id="rfps-collaboration-title"
			icon={<GridViewIcon />}
			title="Collaboration"
		/>
		<Box marginTop="1.5rem">
			<DetailsBoxTitle
				id="rfps-responsibilites-subtitle"
				title="Responsibilites"
			/>
		</Box>

		<FlexibleValue
			id="rfps-image-provider"
			title={data.preferredCreativeTerms.imageProvider || ""}
			flexible={data.preferredCreativeTerms.isImageProviderFlexible}
			rootSx={{ marginTop: "1rem" }}
		/>

		{data.valueProposition && (
			<Box display="flex" alignItems="center" marginTop="1rem">
				<Typography
					fontSize="0.875rem"
					lineHeight="120%"
					fontWeight="300"
					marginRight="0.625rem"
				>
					{data.valueProposition}
				</Typography>
			</Box>
		)}

		<FlexibleValue
			id="rfps-copy-provider"
			title={data.preferredCreativeTerms.copyProvider || ""}
			flexible={data.preferredCreativeTerms.isCopyProviderFlexible}
			rootSx={{ marginTop: "1rem" }}
		/>

		<Typography
			id="rfps-revisions-subtitle"
			marginTop="3rem"
			fontSize="1rem"
			lineHeight="120%"
		>
			Revisions
		</Typography>
		<Box display="flex" gap="6.25rem" marginTop="0.625rem">
			<Box>
				<DetailsBoxTitle
					id="rfps-advertiser-review-days-subtitle"
					title="Advertiser Review Days"
				/>
				<Typography
					id="rfps-advertiser-review-days"
					fontSize="0.875rem"
					lineHeight="120%"
					fontWeight="300"
					marginTop="0.625rem"
				>
					{data.preferredCreativeTerms.advertiserRevisionDays}
				</Typography>
			</Box>
			<Box>
				<DetailsBoxTitle
					id="rfps-rounds-of-revisions-subtitle"
					title="Rounds of Revisions"
				/>

				<FlexibleValue
					id="rfps-rounds-of-revisions"
					title={
						data.preferredCreativeTerms.maxRevisionRoundCount?.toString() || ""
					}
					flexible={false}
					rootSx={{ marginTop: "0.625rem" }}
				/>
			</Box>
		</Box>
		<Typography
			id="rfps-landing-page-subtitle"
			marginTop="3rem"
			fontSize="1rem"
			lineHeight="120%"
		>
			Landing Page
		</Typography>
		<a
			href={data.landingPageUrl}
			target="_blank"
			rel="noreferrer"
			style={{ marginTop: "0.625rem" }}
		>
			<Typography
				id="rfps-landing-page-url"
				fontSize="0.875rem"
				lineHeight="120%"
				fontWeight="300"
				marginTop="0.625rem"
				color="#000000"
				sx={{ textDecoration: "underline" }}
			>
				{data.landingPageUrl}
			</Typography>
		</a>
	</Box>
);

export default Collaboration;

import { StyleRules } from "@mui/styles";

export const FormStyle: StyleRules = {
    input: {
        margin: 10,
        // fontStyle: 'bold'
    },
    textField: {
        marginBottom: "1rem",
    },
};

import { Box, Button, Snackbar } from '@mui/material';
import Assistance from '../../common/assistance/assistance';
import ViewTitle from '../../common/titles/viewTitle/viewTitle';
import React, { useEffect, useState } from 'react';
import { CreativeForm } from './creative.form';
import { CreativesTable } from './creatives.table';
import { Creative } from './creative.interface';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { ReduxState } from '../../store/store.interface';
import { readAll, updateRecord } from '../../store/creatives.slice';
import { PlusOne } from '@mui/icons-material';
import { useNotify } from '../../hooks/notify.hook';
import { useAuthentication } from '../../common/user/auth/useAuthentication.hook';
import { CreativeView } from './creative.view';
import { ViewContext } from '../../common/types/view';
import { viewStyles } from '../../styles/view.style';
import { makeStyles } from '@mui/styles';
import { readAll as readAllBrands } from '../../store/brands.slice';

const useStyles = makeStyles(() => viewStyles);

export const CreativesAdminView: React.FC<{}> = () => {
  // state controls
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuthentication();
  const { brands, creatives, organizations } = useAppSelector(
    (state: ReduxState) => state,
  );
  const activeOrganizationId = useAppSelector(
    (state: ReduxState) => state.user.activeOrganizationId,
  );

  const filteredCreatives: Creative[] =
    !creatives || creatives.length === 0
      ? []
      : creatives.filter(
          (creative: Creative) =>
            creative.organizationId === activeOrganizationId,
        );
  const [activeCreative, setActiveCreative] = useState<Creative>();
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [fetchedBrands, setFetchedBrands] = useState(false);
  const { notify, shouldNotify, cancel, duration, message } = useNotify();
  const toggleEdit = (creative?: Creative) => {
    if (creative) setActiveCreative(creative);
    setShowEdit(!showEdit);
    setFetched(false);
  };
  const toggleView = (creative?: Creative) => {
    if (creative) setActiveCreative(creative);
    setShowView(!showView);
    setFetched(false);
  };
  const handleCreateClick = () => {
    setActiveCreative(undefined);
    setShowEdit(true);
  };
  const toggleActive = async (id: number) => {
    const creative: Creative | undefined = filteredCreatives.find(
      (c: Creative) => c.id === id,
    );
    if (creative) {
      let updatedCreative = { ...creative, isActive: !creative.isActive };
      const result = await dispatch(updateRecord(updatedCreative) as any);
      if (result.error) return notify(result.error);
      return notify(
        `Creative ${updatedCreative.id} is now ${
          updatedCreative.isActive ? 'active' : 'inactive'
        }.`,
      );
    } else {
      return notify(`Creative not found.`);
    }
  };

  useEffect(() => {
    if (
      !showEdit &&
      isAuthenticated &&
      filteredCreatives.length === 0 &&
      !fetched
    ) {
      dispatch(readAll() as any);
      setFetched(true);
    }
    if (isAuthenticated && brands.length === 0 && !fetchedBrands) {
      dispatch(readAllBrands() as any);
      setFetchedBrands(true);
    }
  }, [
    brands.length,
    dispatch,
    fetched,
    fetchedBrands,
    filteredCreatives.length,
    isAuthenticated,
    setFetched,
    showEdit,
  ]);
  return (
    <div className={classes.viewContainer}>
      <Box sx={{ mb: '3rem', color: 'primary.main' }}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Assistance />
        </Box>
        <ViewTitle
          {...{
            title: 'Creatives',
            subTitle: 'Admin Console',
            rightAlignedContent: (
              <Button
                variant="outlined"
                startIcon={<PlusOne />}
                onClick={handleCreateClick}
                sx={{ backgroundColor: '#FFF' }}
              >
                Create Creative
              </Button>
            ),
          }}
        />
        {showEdit ? (
          <CreativeForm
            creative={activeCreative}
            toggleVisibility={toggleEdit}
            notify={notify}
          />
        ) : showView ? (
          <CreativeView
            {...{
              creative: activeCreative,
              viewContext: ViewContext.FLOW,
              toggleVisibility: toggleView,
            }}
          />
        ) : (
          <CreativesTable
            {...{
              edit: toggleEdit,
              creatives: filteredCreatives,
              toggleActive,
              brands,
              organizations,
              view: toggleView,
            }}
          />
        )}
        <Snackbar
          open={shouldNotify}
          autoHideDuration={duration}
          message={message}
          onClose={cancel}
        />
      </Box>
    </div>
  );
};

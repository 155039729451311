import { Slice } from '@reduxjs/toolkit';
import { ReduxState, ReduxStateKeys } from './store.interface';
import { GenericCRUDActions, CrudActionEssential } from './crud.actions';
import { createCollectionSlice } from './generic.slice';
import { ModelContext, ModelMap } from '../common/types/models';

const sliceName: ReduxStateKeys = 'proposals';

type SliceType = ReduxState[typeof sliceName];
type ModelType = ModelMap[ModelContext.PROPOSAL];

const initialState: SliceType = [];

export const {
  readById,
  readAll,
  createRecord,
  updateRecord,
  deleteRecord,
}: CrudActionEssential<ModelType> = new GenericCRUDActions<ModelType>(
  sliceName,
);
const crudActions: CrudActionEssential<ModelType> = {
  readById,
  readAll,
  createRecord,
  updateRecord,
  deleteRecord,
};
const proposals: Slice<ModelType[]> = createCollectionSlice<ModelType>({
  name: sliceName,
  initialState,
  crudActions,
});

export const { add, replaceAll, update, remove } = proposals.actions;
export default proposals.reducer;

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import campaignsSlice from './campaigns.slice';
import flowSlice from './flow.slice';
import newsletterSlice from './newsletter.slice';
import ordersSlice from './orders.slice';
import organizationsSlice from './organizations.slice';
import placementSlice from './placement.slice';
import proposalsSlice from './proposals.slice';
import rfpsSlice from './rfps.slice';
import thunkMiddleware from 'redux-thunk';
import userSlice from './user.slice';
import resourcesSlice from './resources.slice';
import adUnitSlice from './adUnit.slice';
import authSlice from './auth.slice';
import lineItemsSlice from './lineItems.slice';
import creativesSlice from './creatives.slice';
import brandsSlice from './brands.slice';
import errorSlice from './error.slice';

/**
 * Redux State Example
    const flowState: FlowState = {
      campaign: {},
      creative: {},
      lineitem: {},
      newsletter: {},
      order: {},
      organization: {},
      placement: {},
      proposal: {},
      rfp: {},
    };
    const initialState: ReduxState = {
      auth: {},
      campaigns: [],
      creatives: [],
      flow: flowState,
      lineitems: [],
      newsletters: [],
      orders: [],
      organizations: [],
      placements: [],
      proposals: [],
      rfps: [],
      user: {},
    };
 */
export const rootReducer = combineReducers({
  auth: authSlice,
  adUnits: adUnitSlice,
  brands: brandsSlice,
  lineItems: lineItemsSlice,
  campaigns: campaignsSlice,
  creatives: creativesSlice,
  flow: flowSlice,
  newsletters: newsletterSlice,
  orders: ordersSlice,
  organizations: organizationsSlice,
  placements: placementSlice,
  proposals: proposalsSlice,
  resources: resourcesSlice,
  rfps: rfpsSlice,
  user: userSlice,
  error: errorSlice,
});

export const wpStoreConfig = {
  middleware: [thunkMiddleware],
  reducer: rootReducer,
};
export const store = configureStore(wpStoreConfig);

export type AppDispatch = typeof store.dispatch;

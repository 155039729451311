import { ReduxCollectionModelTypes } from '../../store/store.interface';

export const sortObjectsByAttribute = <T extends ReduxCollectionModelTypes>(
  objects: T[],
  attribute: keyof T,
  order: 'asc' | 'desc' = 'asc',
): T[] => {
  var sortOrder = order === 'desc' ? -1 : 1;
  return objects.sort((a, b) => {
    var result =
      a[attribute] < b[attribute] ? -1 : a[attribute] > b[attribute] ? 1 : 0;
    return result * sortOrder;
  });
};

import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { authorizedRouteConfig } from './routes/authorizedRouteConfig';
import theme from './theme';

import './App.css';
import React from 'react';

const App = () => {
  const routes = useRoutes(authorizedRouteConfig);

  return <ThemeProvider theme={theme}>{routes}</ThemeProvider>;
};
export default App;

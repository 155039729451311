import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    wrapper: {
        border: "1px solid ",
        padding: "1rem",
        borderRadius: "10px",
    },
    title: {
        color: "#5D5FEF",
    },
}));

export const Create = () => {
    const classes = useStyles();
    return (
        <Box className={classes.wrapper}>
            <Box>
                <Typography variant="h4">Presenting Placement</Typography>
                <Typography>1.3% Click rate</Typography>
            </Box>
            <Typography>Create the first ad unit in your placement</Typography>

            <Button variant="outlined">AD UNIT</Button>
        </Box>
    );
};

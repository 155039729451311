import { ViewContextProps } from '../../common/types/view';
// TODO fetch real types
// TODO update type
// export type AdUnit = {
//   id: number;
//   branding: string;
//   image: string;
//   headline: string;
//   body: string;
//   ctaButton: string;
//   footnote: string;
//   newsletterId: number;
//   name: string;
// };
export enum AssetType {
  BRAND_NAME = 'BRAND_NAME',
  LOGOMARK = 'LOGOMARK',
  LOGOTYPE = 'LOGOTYPE',
  HEADLINE = 'HEADLINE',
  IMAGE = 'IMAGE',
  BODY_WITH_LINKS = 'BODY_WITH_LINKS',
  BODY_WITHOUT_LINKS = 'BODY_WITHOUT_LINKS',
  CTA = 'CTA',
  FOOTNOTE = 'FOOTNOTE',
}
export interface AdUnit {
  id: number;
  publisherId: number;
  newsletterId: number;
  name: string;
  type: AdUnitType;
  assetTypes: AssetType[];
  maxHeadlineLength?: number;
  isImageRequired?: boolean;
  isAnimationAllowed?: boolean;
  minImageAspectRatio?: number;
  maxImageAspectRatio?: number;
  maxBodyLength?: number;
  defaultCta?: string;
  isCtaFlexible?: boolean; // TODO Is this redundant? Asset types includes CTA if the CTA can be specified.
  maxCtaLength?: number;
  maxAdCount?: number;
  maxFootnoteLength?: number;
  templateId?: number;
  isActive: boolean;
}
export enum AdUnitType {
  PRESENTING_SPONSORSHIP = 'PRESENTING_SPONSORSHIP',
  SPONSORED_CONTENT = 'SPONSORED_CONTENT',
  LIST = 'LIST',
}

export type AdUnitKeys = {
  key: 'branding' | 'body' | 'ctaButton' | 'footnote' | 'headline' | 'image';
};

export class AdUnitProps implements ViewContextProps {}

import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { idToReactKey } from '../../utilities/format.util';
import { FormStyle } from '../../styles/form.style';
import { FormGeneratorProps } from './formGenerator.interface';
import { DateInput } from './inputs/dateField/dateInput';
import { SelectInput } from './inputs/selectField/selectField';
import { TextInputField } from './inputs/textField/textField';
import React from 'react';
import { RadioSelect } from './inputs/radioSelect/radioSelect';
import { Grid } from '@mui/material';

export const FormGenerator = ({ fields, maxWidth }: FormGeneratorProps) => {
  const useStyles = makeStyles(() => ({
    ...FormStyle,
    generatedTextFieldForm: {
      maxWidth: maxWidth || 'unset',
    },
    textFieldCustomComponentDivider: {
      textAlign: 'center',
      marginTop: '2rem',
    },
    textFieldWithCustomComponent: {
      display: 'grid',
      gridTemplateColumns: `${maxWidth} auto auto`,
      gridGap: '1.5rem',
    },
  }));
  const classes = useStyles();
  return (
    <>
      <Box className={classes.generatedTextFieldForm}>
        {fields.map((field, i) => {
          return (
            <Box
              key={`${idToReactKey(field.label)}-wrapper`}
              className={
                field.alternative
                  ? classes.textFieldWithCustomComponent
                  : classes.textFieldCustomComponentDivider
              }
            >
              {(() => {
                switch (field.type) {
                  case 'select':
                    return (
                      <SelectInput
                        id={field.id}
                        label={field.label}
                        value={field.value || ''}
                        onChange={(e) => field.onChange(e.target.value)}
                        options={field.options}
                      />
                    );
                  case 'date':
                    return (
                      <Grid container>
                        <Box display={'flex'} justifyContent="flex-end">
                          <DateInput
                            id={field.id}
                            label={field.label}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          />
                        </Box>
                      </Grid>
                    );
                  case 'radio':
                    return (
                      <Grid container>
                        <Box display={'flex'} justifyContent="flex-end">
                          <RadioSelect
                            options={field.options}
                            title={field.label}
                            onRadioChange={(e: any) => {
                              field.onChange(e);
                            }}
                            onCheckboxChange={(e: any) => {
                              field.onChange(e);
                            }}
                            isOptional={field.isOptional}
                          />
                        </Box>
                      </Grid>
                    );
                  default:
                    return (
                      <TextInputField
                        id={idToReactKey(field.id)}
                        label={field.label}
                        onChange={(e) => field.onChange(e.target.value)}
                        helperText={field.helperText}
                        minRows={field.minRows}
                        value={field.value}
                      />
                    );
                }
              })()}
              {field.alternative ? (
                <>
                  <div className={classes.textFieldCustomComponentDivider}>
                    <span>or</span>
                  </div>
                  <Box>
                    <field.alternative />
                  </Box>
                </>
              ) : null}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

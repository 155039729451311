import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FileUpload } from '../../common/draggableFileUpload/draggableFileUpload';
import { useAppSelector } from '../../hooks/redux.hook';
import { createRecord, updateRecord } from '../../store/creatives.slice';
import { ReduxState } from '../../store/store.interface';
import { enumValues } from '../../utilities/enum.util';
import { Editor } from '../../common/editor/editor';
import {
  MAX_BODY_LENGTH,
  Creative,
  CreativeFormProps,
  CreativeResourceTypes,
} from './creative.interface';
import { AdUnitType } from '../adUnit/adUnit.interface';
import { makeStyles } from '@mui/styles';
import { viewStyles } from '../../styles/view.style';
import { capitalize } from '../../utilities/format.util';
import { creativeFormValidator } from './creative.form.validator';
import { useAuthentication } from '../../common/user/auth/useAuthentication.hook';
import { readAll } from '../../store/brands.slice';
import { sortObjectsByAttribute } from '../../common/utils/sort';
import { Brand } from '../brands/brand.interface';
import { DisplayImage } from '../../common/fields/display/image';

const useStyles = makeStyles(() => viewStyles);
export const CreativeForm: React.FC<CreativeFormProps> = (
  props,
): JSX.Element => {
  const classes = useStyles();
  const { isAuthenticated } = useAuthentication();
  const [fetchedBrands, setFetchedBrands] = useState(false);
  const { brands, user } = useAppSelector((state: ReduxState) => state);
  const { activeOrganizationId } = user;
  const dispatch = useDispatch();
  const [creative, setCreative] = useState<Partial<Creative>>(
    props.creative || {
      organizationId: activeOrganizationId,
      isActive: true,
    },
  );
  const deleteAttrubute = (
    attribute: string,
    updatedCreative: Partial<Creative>,
  ) => {
    if (!!creative[attribute]) {
      delete updatedCreative[attribute];
    }
    return updatedCreative;
  };
  const setResourceId = (type: CreativeResourceTypes, resourceId: number) => {
    setCreative({ ...creative, [type]: resourceId });
  };
  const removeResource = (type: CreativeResourceTypes) => {
    let updatedCreative = { ...creative };
    delete updatedCreative[type];
    setCreative(updatedCreative);
  };

  const updateBodiesWithBRTags = (): Creative | Partial<Creative> => {
    let updatedCreative = { ...creative };
    const bodyWithLinksNeedsUpdate =
      !!updatedCreative.bodyWithLinksHandlebars &&
      updatedCreative.bodyWithLinksHandlebars.includes('</p><br/><p>');

    const bodyWithoutLinksNeedsUpdate =
      !!updatedCreative.bodyWithoutLinks &&
      updatedCreative.bodyWithoutLinks.includes('</p><br/><p>');

    if (bodyWithLinksNeedsUpdate) {
      updatedCreative.bodyWithLinksHandlebars = (
        updatedCreative.bodyWithLinksHandlebars || ''
      )
        .split('</p><br/><p>')
        .join('</p><p>');
    }
    if (bodyWithoutLinksNeedsUpdate) {
      updatedCreative.bodyWithoutLinks = (
        updatedCreative.bodyWithoutLinks || ''
      )
        .split('</p><br/><p>')
        .join('</p><p>');
    }
    return updatedCreative;
  };
  const create = async () => {
    const updatedCreative = updateBodiesWithBRTags();
    const result = await dispatch(createRecord(updatedCreative) as any);
    if (!result.error) {
      props.notify('Created a Creative.');
      props.toggleVisibility();
    } else {
      props.notify(
        `An error occurred while creating the Creative, ${result.error.message}`,
      );
    }
  };
  const update = async () => {
    const updatedCreative = updateBodiesWithBRTags();
    const result = await dispatch(
      updateRecord(updatedCreative as Creative) as any,
    );
    if (!result.error) {
      props.notify(`Updated Creative ${creative.id}`);
      props.toggleVisibility();
    } else {
      props.notify(
        `An error occurred while updating the Creative, ${result.error.message}`,
      );
    }
  };
  const handleChange = (event) => {
    let updatedCreative = { ...creative };
    const attribute = event.target.name;
    const value = event.target.value;
    if (value === '') {
      updatedCreative = deleteAttrubute(attribute, updatedCreative);
    } else {
      updatedCreative[attribute] = Number.isInteger(value) ? +value : value;
      if (attribute === 'brandId' && !creative.logotypeResourceId) {
        const assignedBrand = brands.find((b) => b.id === +value);
        updatedCreative.logotypeResourceId = assignedBrand?.logomarkResourceId;
      }
    }
    setCreative(updatedCreative);
  };
  const handleBodyChange = (
    value,
    prop: 'bodyWithLinksHandlebars' | 'bodyWithoutLinks',
  ) => {
    setCreative({
      ...creative,
      [prop]: value,
    });
  };

  const { isValidObject, isValidField, getFieldMessage, showValidations } =
    creativeFormValidator;

  const activeBrands: Brand[] =
    brands?.filter((b) => b.organizationId === activeOrganizationId) || [];

  useEffect(() => {
    if (isAuthenticated && brands.length === 0 && !fetchedBrands) {
      dispatch(readAll() as any);
      setFetchedBrands(true);
    }
  }, [isAuthenticated, brands.length, fetchedBrands, dispatch]);

  if (!isValidObject(creative)) console.log('validations: ', showValidations());
  return (
    <div className={classes.objectViewContainer}>
      <form>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <TextField
              label="External ID"
              name="externalId"
              value={creative.externalId || ''}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              error={!isValidField(creative, 'externalId')}
              helperText={getFieldMessage('externalId')}
              margin="normal"
            />
          </Grid>
          <Box width="100%" />
          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="ad-unit-type">Ad Unit Type</InputLabel>
              <Select
                labelId="ad-unit-type"
                label="Ad Unit Type"
                name="adUnitType"
                value={creative.adUnitType || 'SELECT_ONE'}
                onChange={handleChange}
                fullWidth
                required
                error={!isValidField(creative, 'adUnitType')}
              >
                {[...enumValues(AdUnitType), 'SELECT_ONE'].map((val, i) => {
                  return (
                    <MenuItem key={`adUnitType-${i}`} value={val}>
                      {val
                        .split('_')
                        .map((val: string) => capitalize(val.toLowerCase()))
                        .join(' ')}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Box width="100%" />
          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="brand-id">Brand Name</InputLabel>
              <Select
                labelId="brand-select"
                label="Brand Name"
                name="brandId"
                value={creative.brandId || '0'}
                onChange={handleChange}
                fullWidth
                required
                error={!isValidField(creative, 'brandId')}
              >
                {[
                  { id: 0, name: 'Select One', logomarkResourceId: undefined },
                  ...sortObjectsByAttribute<Brand>(activeBrands, 'name'),
                ].map((val, i) => {
                  return (
                    <MenuItem key={`brand-${i}`} value={val.id}>
                      {val.name + (val.id > 0 ? ' - ' : '')}
                      <DisplayImage
                        imageId={val.logomarkResourceId}
                        maxHeight={50}
                        maxWidth={100}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Box width="100%" />
          <Grid item xs={4}>
            <FileUpload
              label="Logo Type *"
              fieldName="logotypeResourceId"
              resourceId={creative.logotypeResourceId}
              text="Upload"
              onFileUpload={(resourceId: number) => {
                setResourceId('logotypeResourceId', resourceId);
              }}
              onFileDelete={(fieldName) =>
                removeResource(fieldName as CreativeResourceTypes)
              }
            />
          </Grid>
          <Box width="100%" />
          <Grid item xs={8}>
            <TextField
              label="Headline"
              name="headline"
              value={creative.headline || ''}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              required
              error={!isValidField(creative, 'headline')}
              helperText={getFieldMessage('headline')}
              margin="normal"
            />
          </Grid>
          <Box width="100%" />
          <Grid item xs={4}>
            <FileUpload
              label="Image"
              fieldName="imageResourceId"
              resourceId={creative.imageResourceId}
              text="Upload"
              onFileUpload={(resourceId: number) => {
                setResourceId('imageResourceId', resourceId);
              }}
              onFileDelete={(fieldName) =>
                removeResource(fieldName as CreativeResourceTypes)
              }
            />
          </Grid>
          <Box width="100%" />
          <Grid item xs={12}>
            <Editor
              label={'Body with links *'}
              value={creative.bodyWithLinksHandlebars || ''}
              setValue={(event) =>
                handleBodyChange(event, 'bodyWithLinksHandlebars')
              }
              hasLinks={true}
              isRequired={false}
              maxLength={MAX_BODY_LENGTH}
            />
          </Grid>
          <Box width="100%" />

          <Grid item xs={12}>
            <Editor
              label={'Body without links *'}
              value={creative.bodyWithoutLinks || ''}
              setValue={(event) => handleBodyChange(event, 'bodyWithoutLinks')}
              hasLinks={false}
              isRequired={false}
              maxLength={MAX_BODY_LENGTH}
            />
          </Grid>
          <Box width="100%" />

          <Grid item xs={4}>
            <TextField
              label="Call to action"
              name="cta"
              value={creative.cta || ''}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              required
              margin="normal"
              error={!isValidField(creative, 'cta')}
              helperText={getFieldMessage('cta')}
            />
          </Grid>
          <Box width="100%" />

          <Grid item xs={8}>
            <TextField
              label="Footnote"
              name="footnote"
              value={creative.footnote || ''}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              margin="normal"
              error={!isValidField(creative, 'footnote')}
              helperText={getFieldMessage('footnote')}
            />
          </Grid>
          <Box width="100%" />
          <Box className={classes.actions}>
            <Button
              variant="outlined"
              className={classes.actionButton}
              onClick={props?.toggleVisibility}
            >
              {'Cancel'}
            </Button>
            <Button
              variant="contained"
              className={classes.actionButton}
              onClick={!!creative.id ? update : create}
              disabled={!isValidObject(creative)}
            >
              {'Save'}
            </Button>
          </Box>
        </Grid>
      </form>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import ViewTitle from '../../common/titles/viewTitle/viewTitle';
import { ViewContext } from '../../common/types/view';
import { viewStyles } from '../../styles/view.style';
import Assistance from '../../common/assistance/assistance';
import { NewsletterDetails } from './newsletter.interface';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { ReduxState } from '../../store/store.interface';
import {
  readById,
  update as updateNewsletter,
} from '../../store/newsletter.slice';
import { update as updateFlow } from '../../store/flow.slice';
import { FormGenerator } from '../../common/formGenerators/formGenerator';
import { FormGeneratorProps } from '../../common/formGenerators/formGenerator.interface';

const useStyles = makeStyles(() => ({
  container: { ...viewStyles.container },
  actions: { ...viewStyles.actions },
  actionButton: { ...viewStyles.actionButton },
  formWrapper: {
    border: '1px solid #8FA1E5',
    borderRadius: '10px',
    padding: '27px 22px',
    maxWidth: '377px',
  },
}));

const NewsletterDetail: React.FC<any> = (props) => {
  const params = useParams<{ id?: string }>();
  let id: number;
  if (params.id) {
    id = +params.id;
  }
  const [tempNewsletter, setTempNewsletter] = useState({});
  const classes = useStyles();

  const newsletter = useAppSelector((state: ReduxState) =>
    state.newsletters.find((news) => news.id === id || -1),
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!tempNewsletter) {
      dispatch(readById(id) as any);
    }
  });

  const setNewsletter = (news: NewsletterDetails) => {
    const modelType = 'newsletter';
    const data = news;
    dispatch(
      !props.proposalId
        ? updateFlow({ type: modelType, data })
        : updateNewsletter({ data }),
    );
  };

  const updateNewsletterProp = (prop: string) => (value: any) => {
    setTempNewsletter({ ...tempNewsletter, [prop]: value });
  };
  const newsletterFormConfig: FormGeneratorProps = {
    fields: [
      {
        id: 'newsletterName',
        label: 'Name',
        helperText: 'The name of your newsletter',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateNewsletterProp('name')(event),
        ...(newsletter?.name && { value: newsletter.name }),
      },
      {
        id: 'newsletterWebsite',
        label: 'Website',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateNewsletterProp('website')(event),
        ...(newsletter?.name && { value: newsletter.name }),
      },
      {
        id: 'newsletterSubscriberCount',
        label: 'Subscribers',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateNewsletterProp('subscriberCount')(event),
        ...(newsletter?.subscriberCount && {
          value: newsletter.subscriberCount.toString(),
        }),
      },
      {
        id: 'newsletterContentDescription',
        label: 'Content description',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateNewsletterProp('contentDescription')(event),
        ...(newsletter?.description && {
          value: newsletter.description,
        }),
        minRows: 3,
      },
      {
        id: 'newsletterAudienceDescription',
        label: 'Audience description',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          updateNewsletterProp('audienceDescription')(event),
        ...(newsletter?.audienceDescription && {
          value: newsletter.audienceDescription,
        }),
        minRows: 3,
      },
    ],
  };
  return (
    <div className={classes.container}>
      Newsletters {params.id || ''}
      <Box sx={{ mb: '3rem' }}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Assistance />
        </Box>
        <ViewTitle
          title="Tell us about your Newsletter."
          showDivider={false}
          viewContext={ViewContext.FLOW}
        />
      </Box>
      <Box className={classes.formWrapper}>
        <FormGenerator fields={newsletterFormConfig.fields} />
      </Box>
      <Box className={classes.actions}>
        <Button variant="outlined" className={classes.actionButton}>
          Back
        </Button>
        <Button
          variant="contained"
          className={classes.actionButton}
          onClick={() => setNewsletter(tempNewsletter as NewsletterDetails)}
        >
          Save
        </Button>
      </Box>
    </div>
  );
};

export default NewsletterDetail;

import { Box, Typography } from "@mui/material";
import { SectionCard } from "../cards/sectionCard/sectionCard";
import { stateDetailStyles } from "../../styles/stateDetail.style";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => stateDetailStyles);

export type StateDetailProps = {
  icon?: JSX.Element;
  messaging?: string;
  mailto: string;
};

export const StateDetail: React.FC<StateDetailProps> = ({
  icon,
  messaging,
  mailto,
}: StateDetailProps) => {
  const classes = useStyles();
  return (
    <SectionCard data-testid={"stateDetailWrapper"} variant={"header"}>
      <Box className={classes.wrapper}>
        <Box className={classes.icon}>{icon || null}</Box>
        <Box className={classes.message}>
          {messaging ? (
            <Typography variant="h3" data-testid={"message"}>
              {messaging}
            </Typography>
          ) : null}
        </Box>
        <Box className={classes.mailTo}>
          <Typography variant="body1">
            Need something else?{" "}
            <a data-testid={"mailTo"} href={`mailto:${mailto}`}>
              <strong>Reach out.</strong>
            </a>
          </Typography>
        </Box>
      </Box>
    </SectionCard>
  );
};

import { Box } from '@mui/material';
import Assistance from '../../common/assistance/assistance';
import ViewTitle from '../../common/titles/viewTitle/viewTitle';
import React, { useEffect } from 'react';
import { ViewContext } from '../../common/types/view';
import { OrganizationForm } from './organization.form';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { Organization, OrganizationViewProps } from './organization.interface';
import { ReduxState } from '../../store/store.interface';
import { createRecord, readById } from '../../store/organizations.slice';
import { makeStyles } from '@mui/styles';
import { viewStyles } from '../../styles/view.style';

const useStyles = makeStyles(() => viewStyles);
export const OrganizationView: React.FC<OrganizationViewProps> = (
  props: OrganizationViewProps,
) => {
  const dispatch = useAppDispatch();
  const existingOrganization: Organization | undefined = useAppSelector(
    (state: ReduxState) =>
      state.organizations.find((org: Organization) => org.id === props?.id),
  );
  useEffect(() => {
    if (props?.id && !existingOrganization) {
      dispatch(readById(props.id) as any);
    }
  });

  const save = (org: Partial<Organization>) => {
    dispatch(createRecord(org) as any);
  };
  const cancel = () => {};
  const classes = useStyles();
  return (
    <div className={classes.objectViewContainer}>
      <Box sx={{ mb: '3rem', color: 'primary.main' }}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Assistance />
        </Box>
        <ViewTitle
          title="Tell us about your company."
          showDivider={false}
          viewContext={props.viewContext || ViewContext.DEFAULT}
        />
        <OrganizationForm
          {...{ organization: existingOrganization, save, cancel }}
        />
      </Box>
    </div>
  );
};

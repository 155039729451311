import React from "react";
import { styled } from "@mui/material/styles";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import {
  VisibilityTwoTone,
  EditTwoTone,
  DeleteTwoTone,
  RadioButtonChecked,
  RadioButtonUnchecked
} from "@mui/icons-material";
import { makeStyles, StyleRules } from "@mui/styles";
import cn from "classnames";

export const newsletterItemStyles: StyleRules = {
  newsletterItem: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid #8FA1E5",
    filter: "drop-shadow(0px 0.5px 3px rgba(0, 0, 0, 0.1))",
    borderRadius: "10px",
    padding: "1.125rem 1.5rem",
    position: "relative",
    cursor: "pointer",
    boxSizing: "border-box",
    marginTop: '1.875rem'
  },
  newsletterItemChecked: {
    color: "#5D5FEF",
    backgroundColor: '#E0E0FF',
    border: "2px solid #8FA1E5",
    boxShadow: "0px 0.5px 5px rgba(0, 0, 0, 0.1)"
  }
};

const RoundIcon = styled(Avatar)(({ theme }) => ({
  width: "1.25rem",
  height: "1.25rem",
  backgroundColor: '#5D5FEF',
  cursor: "pointer"
}));

const useStyles = makeStyles(() => newsletterItemStyles);

interface NewsletterItemProps {
  index: number;
  name: string;
  count: number;
  checked?: boolean;
  onSelect?: () => void;
  onView?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const NewsletterItem: React.FC<NewsletterItemProps> = ({ index, name, count, checked, onSelect,onView, onEdit, onDelete }) => {
  const classes = useStyles();

  return (
    <Box
      key={`list-item-${index}`}
      className={cn(classes.newsletterItem, {[classes.newsletterItemChecked]: checked})}
      onClick={() => onSelect && onSelect()}
    >
      {checked && <RadioButtonChecked color="inherit"/>}
      {!checked && <RadioButtonUnchecked color="inherit"/>}
      <Box marginLeft="25px">
        <Box fontSize="18px" lineHeight="22px" fontWeight={700}>{name}</Box>
        <Box fontSize="14px" lineHeight="17px" marginTop="10px">{count}</Box>
      </Box>
      <Box display="flex" flexDirection="column" position="absolute" right="-0.625rem" top={0} bottom={0} justifyContent="space-between" zIndex={1} paddingY="10px">
        <RoundIcon onClick={() => onView && onView()}>
          <VisibilityTwoTone sx={{fontSize: "0.875rem"}}/>
        </RoundIcon>
        <RoundIcon onClick={() => onEdit && onEdit()}>
          <EditTwoTone sx={{fontSize: "0.875rem"}}/>
        </RoundIcon>
        <RoundIcon onClick={() => onDelete && onDelete()}>
          <DeleteTwoTone sx={{fontSize: "0.875rem"}}/>
        </RoundIcon>
      </Box>
    </Box>
  )
}

import React from 'react';
import { makeStyles } from '@mui/styles';

import { viewStyles } from '../../styles/view.style';
interface FlowViewNavigationProps {}
const useStyles = makeStyles(viewStyles);
const FlowViewNavigation: React.FC<FlowViewNavigationProps> = (
  props: FlowViewNavigationProps,
) => {
  const classes = useStyles();
  return <div className={classes.flowViewNavigationPanel}></div>;
};
export default FlowViewNavigation;

import { FormValidator } from '../../common/forms/formValidator';
import {
  isDefined,
  isFalsey,
  maxCharLength,
  minCharLength,
  minValue,
  timestampGreaterThanOrEqualToToday,
} from '../../common/forms/validation';
import { conditions } from './conditions';
import {
  LineItem,
  MAX_ITEM_NAME_CHARS,
  PricingTerms,
} from './lineItem.interface';

export const lineItemFormValidator = new FormValidator<
  Partial<LineItem>,
  keyof LineItem
>({
  adUnitType: {
    validations: [isDefined],
    message: '',
    shouldValidate: (lineItem: Partial<LineItem>) =>
      isFalsey(lineItem.isRoadblock),
  },
  brandId: {
    validations: [minValue(1)],
    message: 'Brand is required.',
  },
  budget: {
    validations: [minValue(1)],
    message: 'Budget must be greater than 0',
  },
  clickUrlHandlebars: {
    validations: [isDefined, minCharLength(4)],
    message: 'Click URL is required.',
  },
  estimatedRevenuePerClick: {
    validations: [minValue(0.01)],
    message: 'Estimated Revenue must be greater than 0',
    shouldValidate: (lineItem: Partial<LineItem>) =>
      conditions.estimatedRevenuePerClick(lineItem),
  },
  guaranteedVolume: {
    validations: [minValue(1)],
    message: 'Guaranteed Volume must be greater than 0',
    shouldValidate: (lineItem: Partial<LineItem>) =>
      !!lineItem.guaranteedVolume,
  },
  isRoadblock: { validations: [isDefined], message: '' },
  name: {
    validations: [
      isDefined,
      minCharLength(1),
      maxCharLength(MAX_ITEM_NAME_CHARS),
    ],
    message: 'Line Item Name is required.',
  },
  pacingLimit: {
    validations: [minValue(1)],
    message: '',
    shouldValidate: (lineItem: Partial<LineItem>) => !!lineItem.pacingPeriod,
  },
  pacingPeriod: {
    validations: [isDefined],
    message: '',
    shouldValidate: (lineItem: Partial<LineItem>) => !!lineItem.pacingLimit,
  },
  startTimestamp: {
    validations: [isDefined],
    message: 'Start Date is required.',
    shouldValidate: (lineItem: Partial<LineItem>) => !lineItem.hasBeenActive,
  },
  endTimestamp: {
    validations: [timestampGreaterThanOrEqualToToday],
    message:
      'End date when defined, cannot be before start date, and must be greater than or equal to today.',
    shouldValidate: (lineItem: Partial<LineItem>) => !!lineItem.endTimestamp,
  },
});
export const pricingTermsFormValidator = new FormValidator<
  Partial<PricingTerms>,
  keyof PricingTerms
>({
  model: {
    validations: [isDefined],
    message: 'Pricing Model is required.',
  },
  advertiserRate: {
    validations: [minValue(0.01)],
    message: 'Advertiser Rate must be greater than 0',
    shouldValidate: () => false,
  },
  publisherRate: {
    validations: [minValue(0.01)],
    message: 'Publisher Rate must be greater than 0',
  },
});

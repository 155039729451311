import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import {
  ToggleOff,
  ToggleOn,
  Edit,
  Visibility,
  ContentCopy,
} from '@mui/icons-material';
import { LineItem, LineItemTableProps } from './lineItem.interface';
// import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { Organization } from '../organizations/organization.interface';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { ReduxState } from '../../store/store.interface';
import { readAll } from '../../store/newsletter.slice';
import { NewsletterDetails } from '../newsletters/newsletter.interface';
import { useAuthentication } from '../../common/user/auth/useAuthentication.hook';
import { Brand } from '../brands/brand.interface';
import { DisplayImage } from '../../common/fields/display/image';

const lineItemFields = <T extends LineItem>(
  edit: (lineItem: Partial<LineItem>) => void,
  toggleActive: (id: number) => void,
  newsletters: NewsletterDetails[],
  organizations: Organization[],
  brands: Brand[],
  view: (lineItem: LineItem) => void,
): GridColDef<T>[] => {
  return [
    {
      field: 'view',
      flex: 0.08,
      renderCell: (params: GridCellParams<T>) => (
        <>
          <Visibility
            onClick={() => view(params.row as LineItem)}
            sx={{ marginRight: 2 }}
            color="primary"
          />

          <Edit
            sx={{ marginRight: 2 }}
            onClick={() => edit(params.row as LineItem)}
            color="info"
          />

          <ContentCopy
            sx={{ marginRight: 2 }}
            onClick={() => {
              let duplicateLineItem: Partial<LineItem> = { ...params.row };
              delete duplicateLineItem.id;
              edit({
                ...duplicateLineItem,
                name: params.row.name + ' Copy',
                externalId: params.row.externalId + ' Copy',
              });
            }}
          />
        </>
      ),
      renderHeader: () => <strong>{'View / Edit / Duplicate'}</strong>,
    },
    {
      field: 'toggleActive',
      flex: 0.04,
      renderCell: (params: GridCellParams<T>) =>
        params.row.isActive ? (
          <ToggleOn onClick={() => toggleActive(+params.id)} color="success" />
        ) : (
          <ToggleOff onClick={() => toggleActive(+params.id)} color="error" />
        ),
      renderHeader: () => <strong>{'Active'}</strong>,
    },
    {
      field: 'brandId',
      flex: 0.1,
      minWidth: 120,
      renderCell: (params: GridCellParams<T['brandId']>) => {
        const brand = brands.find((b: Brand) => b.id === params.value);
        return <>{brand?.name || ''}</>;
      },
      renderHeader: () => <strong>{'Brand Name'}</strong>,
    },
    {
      field: 'brandLogo',
      flex: 0.18,
      renderHeader: () => <strong>{'Brand Logo'}</strong>,
      renderCell: (params: GridCellParams<T>) => {
        const brand = brands.find((b: Brand) => b.id === params.row.brandId);
        return (
          <DisplayImage
            {...{ maxHeight: 45, imageId: brand?.logomarkResourceId }}
          />
        );
      },
    },
    {
      field: 'whitelistedNewsletterIds',
      flex: 0.15,
      renderHeader: () => <strong>{'Newsletter'}</strong>,
      renderCell: (params) => {
        if (newsletters && !!params.value && params.value.length > 0) {
          const newsletterIds: number[] = params.value;
          return (
            <>
              {newsletterIds
                .map(
                  (id: number) =>
                    newsletters.find((news) => news.id === id)?.name,
                )
                .join(', ')}
            </>
          );
        }
      },
    },
    {
      field: 'id',
      flex: 0.02,
      renderHeader: () => <strong>{'Id'}</strong>,
    },
    {
      field: 'externalId',
      flex: 0.05,
      renderCell: (params: GridCellParams<T>) => {
        return <>{`${params.value || ''}`}</>;
      },
      renderHeader: () => <strong>{'Ext. ID'}</strong>,
    },
    {
      field: 'name',
      flex: 0.15,
      minWidth: 120,
      renderHeader: () => <strong>{'Line Item Name'}</strong>,
    },
    {
      field: 'budget',
      flex: 0.05,
      renderCell: (params: GridCellParams<T>) => {
        return (
          <>
            <NumericFormat
              value={parseInt(`${params.value}`) || 0}
              thousandSeparator={true}
              decimalScale={2}
              prefix={'$'}
              displayType="text"
              readOnly
            />
          </>
        );
      },
      renderHeader: () => <strong>{'Budget'}</strong>,
    },
    {
      field: 'startTimestamp',
      flex: 0.05,
      renderCell: (params: GridCellParams<T>) => {
        const date = new Date(parseInt(`${params.value}`) * 1000);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return <>{year + '-' + month + '-' + day}</>;
      },
      renderHeader: () => <strong>{'Start Date'}</strong>,
    },
    {
      field: 'endTimestamp',
      flex: 0.05,
      renderCell: (params: GridCellParams<T>) => {
        if (params.value) {
          const date = new Date(parseInt(`${params.value}`) * 1000);
          var year = date.getFullYear();
          var month = date.getMonth() + 1;
          var day = date.getDate();
          return <>{year + '-' + month + '-' + day}</>;
        }
      },
      renderHeader: () => <strong>{'End Date'}</strong>,
    },
    {
      field: 'creativeIdsByAdUnitIndex',
      flex: 0,
      renderHeader: () => <strong>{'Creatives'}</strong>,
    },
    {
      field: 'hasBeenActive',
      flex: 0,
      renderHeader: () => <strong>{'Has Been Active'}</strong>,
    },
    // {
    //   field: 'organizationId',
    //   flex: 0,
    //   renderCell: (params: GridCellParams<T['organizationId']>) => {
    //     const organization = organizations.find(
    //       (org: Organization) => org.id === params.value,
    //     );
    //     return <>{organization?.name || ''}</>;
    //   },
    //   renderHeader: () => <strong>{'Seller'}</strong>,
    // },
    // {
    //   field: 'isRoadblock',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Is Roadblock'}</strong>,
    // },
    // {
    //   field: 'adUnitType',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Ad Unit Type'}</strong>,
    // },
    // {
    //   field: 'adUnitIds',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Ad Unit Ids'}</strong>,
    // },
    // {
    //   field: 'blacklistedNewsletterIds',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Blacklisted Newsletters'}</strong>,
    //   renderCell: (params) => {
    //     if (newsletters && !!params.value && params.value.length > 0) {
    //       const newsletterIds: number[] = params.value;
    //       return (
    //         <>
    //           {newsletterIds
    //             .map(
    //               (id: number) =>
    //                 newsletters.find((news) => news.id === id)?.name,
    //             )
    //             .join(', ')}
    //         </>
    //       );
    //     }
    //   },
    // },

    // {
    //   field: 'isHouseCampaign',
    //   flex: 0,
    //   renderHeader: () => <strong>{'House Campaign'}</strong>,
    // },
    // {
    //   field: 'guaranteedVolume',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Guaranteed Volume'}</strong>,
    // },
    // {
    //   field: 'pacingLimit',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Pacing Limit'}</strong>,
    // },
    // {
    //   field: 'pacingPeriod',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Pacing Period'}</strong>,
    // },
    // {
    //   field: 'maxFrequency',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Max Frequency'}</strong>,
    // },
    // {
    //   field: 'cadenceNumerator',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Cadence Numerator'}</strong>,
    // },
    // {
    //   field: 'cadenceDenominator',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Cadence Denominator'}</strong>,
    // },

    // {
    //   field: 'estimatedRevenuePerClick',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Revenue / Click'}</strong>,
    // },
    // {
    //   field: 'clickUrlHandlebars',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Url'}</strong>,
    // },
  ];
};

export const LineItemsTable: React.FC<LineItemTableProps> = ({
  edit,
  lineItems,
  toggleActive,
  organizations,
  brands,
  view,
}) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuthentication();
  const { newsletters } = useAppSelector((state: ReduxState) => state);
  const [fetchedNewsletters, setFetchedNewsletters] = useState<boolean>(false);
  const fieldMap = lineItemFields(
    edit,
    toggleActive,
    newsletters,
    organizations,
    brands,
    view,
  );
  useEffect(() => {
    if (isAuthenticated && newsletters.length === 0 && !fetchedNewsletters) {
      dispatch(readAll() as any);
      setFetchedNewsletters(true);
    }
  }, [isAuthenticated, newsletters.length, fetchedNewsletters, dispatch]);
  return (
    <>
      {!lineItems || lineItems.length === 0 ? (
        <>{'No Line Items were found'}</>
      ) : (
        <DataGrid
          rows={[...lineItems].sort((a: LineItem, b: LineItem) => a.id - b.id)}
          columns={fieldMap}
          autoHeight
          sx={{ backgroundColor: '#FFF' }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'desc' }],
            },
          }}
        />
      )}
    </>
  );
};

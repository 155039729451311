import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import { ViewContextProps } from '../../../../common/types/view';
import FlowView from '../../../flow/flowView';
import { ChildFlow } from './child';
import { FlowRouter } from '../../../flow/flow.router';
import { viewStyles } from '../../../../styles/view.style';

interface ChildProps extends ViewContextProps {}

const useStyles = makeStyles(() => viewStyles);

const ParentStuff1: React.FC<ChildProps> = (props: ChildProps) => {
  console.log('Parent Stuff 1 props: ', props);
  const classes = useStyles();
  const { next, previous } = props;
  return (
    <div className={classes.actions}>
      <div>{'Parent Stuff 1'}</div>
      <Button
        className={classes.actionButton}
        variant="outlined"
        onClick={previous}
      >
        {'Cancel'}
      </Button>
      <Button
        className={classes.actionButton}
        variant="contained"
        onClick={next}
      >
        {'Continue'}
      </Button>
    </div>
  );
};
const ParentStuff2: React.FC<ChildProps> = (props: ChildProps) => {
  console.log('Parent Stuff 2 props: ', props);
  const classes = useStyles();
  const { next, previous } = props;
  return (
    <div className={classes.actions}>
      <div>{'Parent Stuff 2'}</div>
      <Button
        className={classes.actionButton}
        variant="outlined"
        onClick={previous}
      >
        {'Cancel'}
      </Button>
      <Button
        className={classes.actionButton}
        variant="contained"
        onClick={next}
      >
        {'Continue'}
      </Button>
    </div>
  );
};
const ParentStuff3: React.FC<ChildProps> = (props: ChildProps) => {
  console.log('Parent Stuff 3 props: ', props);
  const classes = useStyles();
  const { next, previous } = props;
  return (
    <div className={classes.actions}>
      <div>{'Parent Stuff 3'}</div>
      <Button
        className={classes.actionButton}
        variant="outlined"
        onClick={previous}
      >
        {'Cancel'}
      </Button>
      <Button
        className={classes.actionButton}
        variant="contained"
        onClick={next}
      >
        {'Continue'}
      </Button>
    </div>
  );
};

export const ParentFlow: React.FC<ChildProps> = () => {
  const flowRouter = new FlowRouter([
    <ParentStuff1 />,
    <ParentStuff2 />,
    <ChildFlow />,
    <ParentStuff3 />,
  ]);

  return (
    <FlowView flowRouter={flowRouter} title={'Flow Parent'} showNavigation />
  );
};

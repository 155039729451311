import { SxProps, Theme } from "@mui/material/styles";
import Colors from "../theme/palette/colors";

const styles: { [key: string]: SxProps<Theme> } = {
  container: {
    marginTop: (theme: Theme) => theme.spacing(4),
    display: "flex",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: (theme: Theme) => theme.spacing(2),
  },
  website: {
    color: Colors.RichBlack,
    marginTop: (theme: Theme) => theme.spacing(1),
    marginBottom: (theme: Theme) => theme.spacing(1),
  },
  mediaKitButton: {
    backgroundColor: Colors.Soap,
    color: Colors.RichBlack,
    textDecoration: "underline",
    maxWidth: "130px",
    maxHeight: "30px",
  },
};

export default styles;

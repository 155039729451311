import { makeStyles } from '@mui/styles';
import logo from '../../common/images/short-logo.svg';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  navigation: {
    width: 102,
    minHeight: '100vh',
    height: '100%',
    backgroundColor: '#393D5C',
    borderTopRightRadius: 5,
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 52,
  },
}));

export const FlowPanelNavigation = () => {
  const classes = useStyles();
  return (
    <div id="flow-navigation" className={classes.navigation}>
      <img
        src={logo}
        id="short-logo"
        className={classes.logo}
        alt="Wellput logo"
      />
    </div>
  );
};

import { StyleRules } from '@mui/styles';

export const sectionStyles: StyleRules = {
  sectionContainer: {
    margin: '20px',
    marginRight: '20px',
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: '#FFF',
    border: '0 1px 1px 1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    width: '100%',
  },
};

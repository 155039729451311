import { RadioProps } from '../../common/inputs/radio/radio.interface';
import { ViewProps } from '../../common/types/view';
import { LineItem } from '../lineItems/lineItem.interface';
export enum PricingModel {
  FLAT = 'FLAT',
  CPM = 'CPM',
  CPC = 'CPC',
  CPA = 'CPA',
  CPO = 'CPO',
  REV_SHARE = 'REV_SHARE',
}

export interface AbstractPricingTerms {
  model: PricingModel;
}

export interface PreferredPricingTerms extends AbstractPricingTerms {
  price?: number;
  isPriceOrRevShareFlexible: boolean;
}

export interface ProposalLineItem extends LineItem {
  preferedPricingTerms: PreferredPricingTerms;
}

export enum ModelContext {
  LINEITEM = 'lineitem',
  CAMPAIGN = 'campaign',
  RFP = 'rfp',
}

export interface PricingModelProps extends ViewProps {
  id?: number;
  modelContext?: ModelContext;
  preferenceId?: number;
  preferenceContext?: ModelContext;
  newsletterId?: number;
  pricingConfig: Array<RadioProps>;
  amount: number;
  maxAmount: number;
}

import { isWellputOrganization } from '../../common/forms/utils';
import { Organization } from '../organizations/organization.interface';
import { PricingModel } from '../pricingModel/pricingModel.interface';
import { LineItem } from './lineItem.interface';

export const conditions = {
  allowHouseCampaign: (org: Organization) => !isWellputOrganization(org),
  advertiserRate: (org: Organization) => isWellputOrganization(org),
  estimatedRevenuePerClick: (lineItem: LineItem | Partial<LineItem>) => {
    const result =
      (!!lineItem?.pricingTerms?.model &&
        [PricingModel.CPA, PricingModel.REV_SHARE].includes(
          lineItem.pricingTerms.model,
        )) ||
      lineItem?.isHouseCampaign ||
      false;
    return result;
  },
  shouldFilterNewsletters: (org: Organization) => !isWellputOrganization(org),
  guaranteedVolume: (model: PricingModel | undefined) =>
    (!!model &&
      [
        PricingModel.CPM,
        PricingModel.CPA,
        PricingModel.CPC,
        PricingModel.CPO,
      ].includes(model)) ||
    false,
  pacingPeriod: (lineItem: LineItem | Partial<LineItem>) =>
    !!lineItem?.pricingTerms?.model &&
    [PricingModel.CPM, PricingModel.CPC].includes(lineItem.pricingTerms.model),
  pacingLimit: (lineItem: LineItem | Partial<LineItem>) =>
    !!lineItem?.pacingPeriod,
  adUnitType: (lineItem: LineItem | Partial<LineItem>) => !lineItem.isRoadblock,
};

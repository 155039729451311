import React, { useEffect, useState } from "react";
import ViewTitle from "../../common/titles/viewTitle/viewTitle";
import { OrdersProps } from "./orders.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { readAll } from "../../store/orders.slice";
import { OrderForm } from "./orders.form";



const Orders: React.FC<OrdersProps> = (props: OrdersProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [fetched, setFetched] = useState(false);
  const orders = useAppSelector((state) => state.orders);

  const save = () => {};
  const cancel = () => {};

  useEffect(() => {
    if (orders.length === 0 && !fetched) {
      dispatch(readAll() as any);
      setFetched(true);
    }
  }, [dispatch, fetched, orders, setFetched]);

  return (
    <>
      <ViewTitle title={"Orders"} />
      <OrderForm save={save} cancel={cancel} />
    </>
  );
};

export default Orders;

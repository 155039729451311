import { Slice } from '@reduxjs/toolkit';
import { ReduxStateKeys } from './store.interface';
import { createTransientObjectSlice } from './generic.slice';
import { ModelContext, ModelMap } from '../common/types/models';

const sliceName: ReduxStateKeys = 'flow';

type ModelType = ModelMap[ModelContext.FLOW];

export const initialState: ModelType = {
  brand: {},
  campaign: {},
  newsletter: {},
  order: {},
  organization: {},
  placement: {},
  proposal: {},
  resource: {},
  rfp: {},
  adUnit: {},
  lineItem: {},
  creative: {},
};

const flow: Slice<ModelType> = createTransientObjectSlice<ModelType>({
  name: sliceName,
  initialState,
});

export const { update } = flow.actions;
export default flow.reducer;

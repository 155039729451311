import { Box, Button } from '@mui/material';
import Assistance from '../../common/assistance/assistance';
import ViewTitle from '../../common/titles/viewTitle/viewTitle';
import React, { useEffect, useState } from 'react';
import { OrganizationForm } from './organization.form';
import { OrganizationsTable } from './organizations.table';
import { Organization } from './organization.interface';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { ReduxState } from '../../store/store.interface';
import {
  deleteRecord,
  // createRecord,
  readAll,
  // updateRecord,
} from '../../store/organizations.slice';
import { PlusOne } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { viewStyles } from '../../styles/view.style';

const useStyles = makeStyles(() => viewStyles);
export const OrganizationsAdminView: React.FC<{}> = () => {
  // state controls
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { organizations, auth } = useAppSelector((state: ReduxState) => state);
  const [activeOrganization, setActiveOrganization] = useState<Organization>(
    {} as Organization,
  );
  const [showEdit, setShowEdit] = useState(false);
  const [fetched, setFetched] = useState(false);
  const toggleEdit = (org?: Organization) => {
    if (org) setActiveOrganization(org);
    setShowEdit(!showEdit);
  };
  const handleCreateClick = () => {
    setActiveOrganization({
      isAdvertiser: false,
      isPublisher: false,
    } as Organization);
    setShowEdit(true);
  };
  const remove = (id: number) => {
    dispatch(deleteRecord(id) as any);
  };
  // const save = (org: Partial<Organization> | Organization) => {
  //   console.log('save organization ', org);
  //   dispatch(
  //     (org.id
  //       ? updateRecord(org as Organization)
  //       : createRecord(org as Partial<Organization>)) as any,
  //   );
  //   setShowEdit(false);
  // };

  useEffect(() => {
    console.log('Organization Admin View Use Effect: ', {
      auth,
      organizations,
      fetched,
    });
    if (
      !showEdit &&
      auth.hasLoaded &&
      !auth.isLoading &&
      (!organizations || organizations?.length === 0) &&
      !fetched
    ) {
      dispatch(readAll() as any);
      setFetched(true);
    }
  }, [auth, dispatch, setFetched, organizations, fetched, showEdit]);
  return (
    <div className={classes.viewContainer}>
      <Box sx={{ mb: '3rem', color: 'primary.main' }}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Assistance />
        </Box>
        <ViewTitle
          {...{
            title: 'Organizations',
            subTitle: 'Admin Console',
            rightAlignedContent: (
              <Button
                variant="outlined"
                startIcon={<PlusOne />}
                onClick={handleCreateClick}
                sx={{ backgroundColor: '#FFF' }}
              >
                Create Organization
              </Button>
            ),
          }}
        />
        {showEdit ? (
          <OrganizationForm
            organization={activeOrganization}
            toggleVisibility={toggleEdit}
          />
        ) : (
          <OrganizationsTable
            {...{ edit: toggleEdit, organizations, remove }}
          />
        )}
      </Box>
    </div>
  );
};

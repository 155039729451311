import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import { Brand, BrandFormProps } from './brand.interface';
import { viewStyles } from '../../styles/view.style';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { createRecord, updateRecord } from '../../store/brands.slice';
import React from 'react';
import { Grid, TextField } from '@mui/material';
import { FileUpload } from '../../common/draggableFileUpload/draggableFileUpload';
import { brandFormValidator } from './brand.form.validator';
import { ReduxState } from '../../store/store.interface';

const useStyles = makeStyles(() => viewStyles);

export const BrandForm: React.FC<BrandFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const activeOrganizationId = useAppSelector(
    (state: ReduxState) => state.user.activeOrganizationId,
  );
  const [brand, setBrand] = useState(
    props.brand || {
      organizationId: activeOrganizationId,
    },
  );
  const create = async () => {
    const result = await dispatch(createRecord(brand) as any);
    if (!result.error) {
      props.notify('Created a Brand.');
      props.toggleVisibility();
    } else {
      props.notify(
        `An error occurred while creating the Brand, ${result.error.message}`,
      );
    }
  };
  const update = async () => {
    const result = await dispatch(updateRecord(brand as Brand) as any);
    if (!result.error) {
      props.notify(`Updated Brand ${brand.id}`);
      props.toggleVisibility();
    } else {
      props.notify(
        `An error occurred while updating the Brand, ${result.error.message}`,
      );
    }
  };
  const handleChange = (event) => {
    const value = event.target.value === '' ? undefined : event.target.value;
    setBrand({
      ...brand,
      [event.target.name]: value,
    });
  };
  const setResourceId = (resourceId: number) => {
    setBrand({ ...brand, logomarkResourceId: resourceId });
  };
  const removeResource = () => {
    let updatedBrand: Partial<Brand> = { ...brand };
    delete updatedBrand.logomarkResourceId;
    setBrand(updatedBrand);
  };
  const { isValidObject, isValidField, getFieldMessage } = brandFormValidator;

  const classes = useStyles();
  return (
    <div className={classes.objectViewContainer}>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <TextField
            label="Name"
            name="name"
            value={brand.name || ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
            error={!isValidField(brand, 'name')}
            helperText={getFieldMessage('name')}
            margin="normal"
          />
        </Grid>
        <Box width="100%" />
        <Grid item xs={4}>
          <TextField
            label="URL"
            name="url"
            value={brand.url || ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
            error={!isValidField(brand, 'url')}
            helperText={getFieldMessage('url')}
            margin="normal"
          />
        </Grid>
        <Box width="100%" />
        <Grid item xs={4}>
          <FileUpload
            label="Logo *"
            fieldName="logomarkResourceId"
            resourceId={brand.logomarkResourceId}
            text="Upload"
            onFileUpload={(resourceId: number) => setResourceId(resourceId)}
            onFileDelete={removeResource}
          />
        </Grid>
        <Box width="100%" />

        <Grid item xs={4}>
          <TextField
            label="Value Proposition"
            name="valueProposition"
            value={brand.valueProposition || ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required
            error={!isValidField(brand, 'url')}
            helperText={getFieldMessage('url')}
            margin="normal"
          />
        </Grid>
        <Box width="100%" />

        <Box className={classes.actions}>
          <Button
            variant="outlined"
            className={classes.actionButton}
            onClick={props?.toggleVisibility}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={!!brand.id ? update : create}
            disabled={!isValidObject(brand)}
          >
            {'Save'}
          </Button>
        </Box>
      </Grid>
    </div>
  );
};

import { FC } from 'react';
import Grid from '@mui/material/Grid';
import {
  FieldValueHelper,
  FieldValueHelperProps,
} from '../fields/display/fieldValueHelper';

export type ListItemSummaryProps = {
  items: FieldValueHelperProps[];
};

const ListItemSummary: FC<ListItemSummaryProps> = ({ items }) => {
  return (
    <Grid container columnSpacing={2} rowSpacing={4}>
      {items.map((item, index) => (
        <Grid
          key={index}
          data-testid="summary-item"
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
        >
          <FieldValueHelper {...item} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ListItemSummary;

import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { ViewContext } from '../../common/types/view';
import { viewStyles } from '../../styles/view.style';

const useStyles = makeStyles(() => viewStyles);
export type ButtonConfig = {
  text: string;
  variant?: 'text' | 'contained' | 'outlined' | undefined;
  onClick: Function;
};
export type ActionButtonSetProps = {
  config: [ButtonConfig, ButtonConfig];
};
export const ActionButtonSet: React.FC<ActionButtonSetProps> = ({
  config,
}: ActionButtonSetProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.actions}>
      {config.map(({ text, variant, onClick }) => {
        return (
          <Button
            key={`action-button-${text}`}
            variant={variant}
            className={classes.actionButton}
            onClick={() => onClick()}
          >
            {text}
          </Button>
        );
      })}
    </Box>
  );
};

export type ActionOnClick = {
  flow: Function;
  default: Function;
};

export type BuilderInput = {
  context?: ViewContext;
  handlers: [ActionOnClick, ActionOnClick];
};

export const ActionButtonSetConfigBuilder = ({
  handlers,
  context,
}: BuilderInput): [ButtonConfig, ButtonConfig] => {
  return [
    {
      text: context === ViewContext.FLOW ? 'Back' : 'Cancel',
      onClick:
        context === ViewContext.FLOW ? handlers[0].flow : handlers[0].default,
    },
    {
      text: context === ViewContext.FLOW ? 'Next' : 'Continue',
      onClick:
        context === ViewContext.FLOW ? handlers[1].flow : handlers[1].default,
    },
  ];
};

import { SxProps, Theme } from "@mui/material/styles";
import Colors from "../theme/palette/colors";

const styles: { [key: string]: SxProps<Theme> } = {
  contentContainer: {
    marginTop: (theme: Theme) => theme.spacing(4),
  },
  spendContainer: {
    padding: (theme: Theme) => theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: (theme: Theme) => theme.spacing(2),
  },
  declineProposalButton: {
    color: Colors.RichBlack,
    borderRadius: (theme: Theme) => theme.shape.borderRadius,
  },
  requestChangesButton: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    color: Colors.RichBlack,
    backgroundColor: Colors.Soap,
    borderRadius: (theme: Theme) => theme.shape.borderRadius,
  },
  acceptProposalButton: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    color: Colors.GhostWhite,
    backgroundColor: Colors.BrightPurple,
    borderRadius: (theme: Theme) => theme.shape.borderRadius,
  },
  submitChangeRequestsButton: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    color: Colors.GhostWhite,
    backgroundColor: Colors.BrightPurple,
    borderRadius: (theme: Theme) => theme.shape.borderRadius,
  },
};

export default styles;

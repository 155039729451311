import { FC, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import { Title } from "../titles/title/title";
import CardHeader from "../cards/cardHeader/cardHeader";
import { ChangeRequestWidgetProps } from "./changeRequest.interface";

import styles from "../../styles/changeRequestForm.styles";

const MAX_CHANGE_REQUEST_DESCRIPTION_LENGTH = 150;
const MAX_CHANGE_REQUEST_COMMENT_LENGTH = 300;

const ChangeRequestForm: FC<ChangeRequestWidgetProps> = ({
  index,
  changeRequest,
  cancel,
  save,
}) => {
  const [description, setDescription] = useState<string>(
    changeRequest.description
  );
  const [comment, setComment] = useState<string>(changeRequest.comment ?? "");
  const [descriptionPristine, setDescriptionPristine] = useState<boolean>(true);
  const descriptionEmpty = useMemo(
    () => description.length === 0,
    [description]
  );

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };
  const handleDescriptionBlur = () => setDescriptionPristine(false);
  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };
  const handleSave = () => {
    save && save(index, { description, comment: comment || undefined });
  };
  const handleCancel = () => {
    cancel && cancel();
  };
  return (
    <Box sx={styles.container}>
      <CardHeader
        leftAligned={
          <Title text={`Request #${index + 1}`} icon={<SwapHorizIcon />} />
        }
      />
      <Box sx={styles.content}>
        <FormControl
          fullWidth
          required
          error={descriptionEmpty && !descriptionPristine}
          sx={styles.descriptionContainer}
        >
          <FormLabel htmlFor="description">
            What do you want to change?
          </FormLabel>
          <TextField
            data-testid="description-text-field"
            id="description"
            sx={styles.textField}
            fullWidth
            hiddenLabel
            autoFocus
            value={description}
            onChange={handleDescriptionChange}
            onBlur={handleDescriptionBlur}
          />
          <FormHelperText sx={styles.textFieldHelper}>
            {`${description.length}/${MAX_CHANGE_REQUEST_DESCRIPTION_LENGTH} characters`}
          </FormHelperText>
        </FormControl>
        <Divider light />
        <FormControl fullWidth sx={styles.commentContainer}>
          <FormLabel htmlFor="comment">Comment</FormLabel>
          <TextField
            data-testid="comment-text-field"
            id="comment"
            sx={styles.textField}
            multiline
            minRows={3}
            fullWidth
            hiddenLabel
            value={comment}
            onChange={handleCommentChange}
          />
          <FormHelperText sx={styles.textFieldHelper}>
            {`${comment.length}/${MAX_CHANGE_REQUEST_COMMENT_LENGTH} characters`}
          </FormHelperText>
        </FormControl>
        <Box sx={styles.buttonContainer}>
          <Button
            data-testid="cancel-button"
            variant="outlined"
            sx={styles.cancelButton}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            data-testid="save-button"
            variant="contained"
            startIcon={<DoneIcon />}
            onClick={handleSave}
            disabled={descriptionEmpty}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangeRequestForm;

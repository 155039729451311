import React, { useEffect } from "react";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { CampaignsGridProps } from "./campaigns.interface";
import ViewTitle from "../../common/titles/viewTitle/viewTitle";
import { ReduxState } from "../../store/store.interface";
import { readAll } from "../../store/campaigns.slice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: "100%",
  marginLeft: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const campaignColumns = (): GridColDef[] => {
  return [
    { field: "name", headerName: "Name", width: 170 },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      renderCell: (params: GridCellParams) => <Chip label={params.value} />,
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 170,
      valueGetter: (params) => {
        return params.row.brand.name;
      },
    },
    { field: "dates", headerName: "Dates", width: 170 },
    {
      field: "budgetRemaining",
      headerName: "Remaining Budget",
      width: 225,
      renderCell: (params: GridCellParams) => {
        const value: number = params.value ? +params.value : 0;
        return (
          <React.Fragment>
            {`${params.value}% `}
            <BorderLinearProgress variant="determinate" value={value} />
          </React.Fragment>
        );
      },
    },
  ];
};

export const CampaignsGridComponent = (props: CampaignsGridProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(readAll() as any);
  }, [dispatch]);
  const campaigns = useAppSelector((state: ReduxState) => state.campaigns);
  if (campaigns.length) console.log("organization grid props: ", props);
  const columnMap = campaignColumns();
  return (
    <>
      <ViewTitle title={"Campaigns"} />
      <DataGrid
        rows={props.campaigns || campaigns}
        columns={columnMap}
        pageSize={5}
        checkboxSelection
        autoHeight
        rowsPerPageOptions={[5]}
      />
    </>
  );
};

export default CampaignsGridComponent;

import { PricingTerms } from 'views/lineItems/lineItem.interface';
import { ReduxCollectionModelTypes } from '../../store/store.interface';

type FormFieldValidationObject = {
  [fieldName: string]: boolean;
};
type FieldValidations<T> = {
  message: string;
  shouldValidate?: (valuesObject: T) => boolean;
  validations: ((value?: any) => boolean)[];
};
type ObjectValidations<T, K extends keyof T> = {
  [fieldName in K]?: FieldValidations<T>;
};
export class FormValidator<
  T extends Partial<ReduxCollectionModelTypes | PricingTerms>,
  K extends keyof T,
> {
  private fieldIsValidObject: FormFieldValidationObject;
  private validationObject: ObjectValidations<T, K>;

  constructor(validationObject: ObjectValidations<T, K>) {
    this.validationObject = validationObject;
    this.fieldIsValidObject = Object.keys(validationObject).reduce(
      (obj, key) => {
        obj[key] = false;
        return obj;
      },
      {} as FormFieldValidationObject,
    );
    this.formFieldValidator = this.formFieldValidator.bind(this);
    this.processAllValidations = this.processAllValidations.bind(this);
    this.processFieldValidation = this.processFieldValidation.bind(this);
    this.isValidObject = this.isValidObject.bind(this);
    this.isValidField = this.isValidField.bind(this);
    this.showValidations = this.showValidations.bind(this);
    this.getFieldMessage = this.getFieldMessage.bind(this);
  }
  formFieldValidator(
    value: T[K],
    validations: FieldValidations<T>['validations'],
    shouldValidate?: boolean,
  ) {
    const isValid: boolean[] = shouldValidate
      ? validations.map((func: (value?: any) => boolean) => {
          return func(value);
        })
      : [true];
    return !isValid.includes(false);
  }
  processAllValidations(valuesObject: T) {
    this.fieldIsValidObject = Object.keys(this.validationObject).reduce(
      (collector: FormFieldValidationObject, key: string) => {
        const validationObject: FieldValidations<T> | undefined =
          this.validationObject[key as K];
        const shouldValidate: boolean = validationObject?.shouldValidate
          ? validationObject?.shouldValidate(valuesObject)
          : true;
        collector[key] =
          valuesObject[key as keyof T] !== undefined
            ? this.formFieldValidator(
                valuesObject[key as K],
                validationObject?.validations as FieldValidations<T>['validations'],
                shouldValidate,
              )
            : !shouldValidate;
        return collector;
      },
      {} as FormFieldValidationObject,
    );
  }
  processFieldValidation(valuesObject: T, key: string) {
    const validationObject: FieldValidations<T> | undefined =
      this.validationObject[key as K];
    const shouldValidate: boolean = validationObject?.shouldValidate
      ? validationObject?.shouldValidate(valuesObject)
      : true;
    if (validationObject && validationObject.validations) {
      this.fieldIsValidObject[key] = this.formFieldValidator(
        valuesObject[key as K],
        validationObject.validations as FieldValidations<T>['validations'],
        shouldValidate,
      );
    }
  }
  isValidObject(valuesObject: T) {
    this.processAllValidations(valuesObject);
    return !Object.values(this.fieldIsValidObject).includes(false);
  }
  isValidField(valuesObject: T, key: string) {
    this.processFieldValidation(valuesObject, key);
    return this.fieldIsValidObject[key];
  }
  getFieldMessage(key: string) {
    return this.fieldIsValidObject[key]
      ? undefined
      : this.validationObject[key as K]?.message;
  }
  showValidations() {
    return this.fieldIsValidObject;
  }
}

import React from "react";
import ViewTitle from "../../common/titles/viewTitle/viewTitle";

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = (
	props: DashboardProps
): JSX.Element => (
	<>
		<ViewTitle title={"Dashboard"} />
	</>
);

export default Dashboard;

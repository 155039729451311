import { Box, SxProps } from "@mui/material";
import { FC, ReactElement } from "react";
import styles from "../../../styles/sectionCard.styles";

export type SectionCardProps = {
  children: ReactElement;
  variant?: "header" | "primary";
  hasPadding?: boolean;
};

export const SectionCard: FC<SectionCardProps> = ({ children, variant, hasPadding = false }) => {
  return (
    <Box
      data-testid={`${variant}-background`}
      sx={
        {
          ...styles.container,
          ...(variant === "header" ? styles.header : styles.primary),
          ...(hasPadding ? styles.hasPadding : {})
        } as SxProps
      }
    >
      {children}
    </Box>
  );
};

import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import { ModelTypesWithId, ModelTypesWithoutId } from '../common/types/models';
import { CrudActionEssential, CrudActionLimited } from './crud.actions';
import {
  baseCollectionReducers,
  baseObjectReducers,
  crudCollectionReducerBuilder,
  crudObjectReducerBuilder,
} from './reducer.factories';
import { ReduxCollectionModelTypes } from './store.interface';

/**
 *
 * @param name <String> slice name
 * @param initialState <T[]> initial value applied to the slice
 * @returns
 */
export const createCollectionSlice = <T extends ReduxCollectionModelTypes>({
  name,
  initialState,
  crudActions,
  reducers,
}: {
  name: string;
  initialState: T[];
  crudActions: CrudActionEssential<T>;
  reducers?: ValidateSliceCaseReducers<T[], SliceCaseReducers<T[]>>;
}) => {
  // Receive and adopt reducers, or Generate some base reducers (add, remove, replaceAll, update)
  const assembledReducers =
    reducers ||
    (baseCollectionReducers<T>() as ValidateSliceCaseReducers<
      T[],
      SliceCaseReducers<T[]>
    >);

  // Auto-Generate CaseReducers for each CRUD action.
  // ( readById readAll createRecord updateRecord deleteRecord )
  // This also produces state hooks for async operations, pending, fulfilled, rejected.
  const extraReducers: (builder: ActionReducerMapBuilder<T[]>) => void =
    crudCollectionReducerBuilder<T>(crudActions, assembledReducers);

  // Define the slice options.
  const sliceOptions: CreateSliceOptions<T[], SliceCaseReducers<T[]>, string> =
    {
      name,
      initialState,
      reducers: assembledReducers,
      extraReducers,
    };
  // Create the slice
  return createSlice(sliceOptions);
};
/**
 *
 * @param name <String> slice name
 * @param initialState <T> initial value applied to the slice
 * @returns
 */
export const createPersistentObjectSlice = <T extends ModelTypesWithId>({
  name,
  initialState,
  crudActions,
  reducers,
}: {
  name: string;
  initialState: T;
  crudActions?: CrudActionLimited<T>;
  reducers?: ValidateSliceCaseReducers<T, SliceCaseReducers<T>>;
}) => {
  // Receive and adopt reducers, or Generate some base reducers (add, remove, replaceAll, update)
  const assembledReducers =
    reducers ||
    (baseObjectReducers<T>() as ValidateSliceCaseReducers<
      T,
      SliceCaseReducers<T>
    >);

  // Auto-Generate CaseReducers for each CRUD action.
  // ( readById readAll createRecord updateRecord deleteRecord )
  // This also produces state hooks for async operations, pending, fulfilled, rejected.
  const extraReducers:
    | undefined
    | ((builder: ActionReducerMapBuilder<T>) => void) = crudActions
    ? crudObjectReducerBuilder<T>(crudActions, assembledReducers)
    : undefined;

  // Define the slice options.
  const sliceOptions: CreateSliceOptions<T, SliceCaseReducers<T>, string> = {
    name,
    initialState,
    reducers: assembledReducers,
    ...(extraReducers && { extraReducers }),
  };
  // Create the slice
  return createSlice(sliceOptions);
};
/**
 *
 * @param name <String> slice name
 * @param initialState <T> initial value applied to the slice
 * @returns
 */
export const createTransientObjectSlice = <T extends ModelTypesWithoutId>({
  name,
  initialState,
  reducers,
}: {
  name: string;
  initialState: T;
  reducers?: ValidateSliceCaseReducers<T, SliceCaseReducers<T>>;
}) => {
  // Receive and adopt reducers, or Generate some base reducers (add, remove, replaceAll, update)
  const assembledReducers =
    reducers ||
    (baseObjectReducers<T>() as ValidateSliceCaseReducers<
      T,
      SliceCaseReducers<T>
    >);

  // Define the slice options.
  const sliceOptions: CreateSliceOptions<T, SliceCaseReducers<T>, string> = {
    name,
    initialState,
    reducers: assembledReducers,
  };
  // Create the slice
  return createSlice(sliceOptions);
};

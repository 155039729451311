import { SxProps, Theme } from '@mui/material/styles';

const styles: { [key: string]: SxProps<Theme> } = {
    container: {
        borderTopLeftRadius: (theme: Theme) => theme.shape.borderRadius,
        borderTopRightRadius: (theme: Theme) => theme.shape.borderRadius,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: (theme: Theme) => theme.spacing(1),
    },
}

export default styles;
import { Slice } from '@reduxjs/toolkit';
import { camelToKebab } from '../utilities/format.util';
import { ModelContext, ModelMap } from '../common/types/models';
import { GenericCRUDActions, CrudActionEssential } from './crud.actions';
import { createCollectionSlice } from './generic.slice';
import { ReduxState, ReduxStateKeys } from './store.interface';

const sliceName: ReduxStateKeys = 'adUnits';
type SliceType = ReduxState[typeof sliceName];
type ModelType = ModelMap[ModelContext.AD_UNIT];

const initialState: SliceType = [];

export const {
  readById,
  readAll,
  createRecord,
  updateRecord,
  deleteRecord,
}: CrudActionEssential<ModelType> = new GenericCRUDActions<ModelType>(
  camelToKebab("adUnits"),
);
const crudActions: CrudActionEssential<ModelType> = {
  readById,
  readAll,
  createRecord,
  updateRecord,
  deleteRecord,
};
const adUnits: Slice<ModelType[]> = createCollectionSlice<ModelType>({
  name: sliceName,
  initialState,
  crudActions,
});

export const { add, replaceAll, update, remove } = adUnits.actions;
export default adUnits.reducer;

import { StyleRules } from '@mui/styles';

export const viewStyles: StyleRules = {
  avatar: {
    backgroundColor: '#EDEDED',
    color: 'black',
    fontSize: '36px',
    width: '88px',
    height: '88px',
    borderRadius: '5px',
  },
  viewContainer: {
    backgroundColor: '#FBFBFB',
    padding: '50px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px 0 10px 10px',
    width: '100%',
  },
  objectViewContainer: {
    backgroundColor: '#FFFFFF',
    padding: '50px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    width: '100%',
  },
  flowViewNavigationPanel: {
    width: '177px',
    height: '100%',
    minHeight: '110vh',
    background: '#E9EDFB',
    borderRadius: '10px',
  },
  avatarUploadButton: {
    marginLeft: '2rem',
    height: '25px',
    backgroundColor: '#EDEDED',
    fontSize: '14px',
    textTransform: 'unset',
    borderRadius: '10px',
    marginTop: '-25px',
    boxShadow: 'none',
  },
  actions: {
    marginTop: '3rem',
    marginLeft: 'auto',
    textAlign: 'right',
    columnGap: '28px',
  },
  actionButton: {
    marginLeft: '1.73rem',
  },
  listBubble: {
    width: '348px',
    background: '#E0E0FF',
    border: '2px solid #8FA1E5',
    boxSizing: 'border-box',
    boxShadow: '0px 0.5px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    padding: '23px 0px 23px 53px',
  },
  table: {
    backgroundColor: '#FFF',
  },
};

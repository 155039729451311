import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Grid } from '@mui/material';

import { FieldValueHelper } from '../../common/fields/display/fieldValueHelper';
import { Title } from '../../common/titles/title/title';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { ReduxState } from '../../store/store.interface';
import { useParams } from 'react-router-dom';
import { readById } from '../../store/creatives.slice';
import { Creative, CreativeViewProps } from './creative.interface';
import { useAuthentication } from '../../common/user/auth/useAuthentication.hook';
import { makeStyles } from '@mui/styles';
import { viewStyles } from '../../styles/view.style';
import {
  AirplanemodeActive,
  AirplanemodeInactive,
  InsertPhoto,
} from '@mui/icons-material';
import { getResourceUrl } from '../../store/resources.slice';
import { Brand } from '../brands/brand.interface';
import { readAll as readAllBrands } from '../../store/brands.slice';

const useStyles = makeStyles(() => viewStyles);
const TextItem = ({ title, value }: { title: string; value?: string }) => {
  return (
    <Grid item xs={12}>
      <FieldValueHelper title={title} value={value} />
    </Grid>
  );
};

const ImageItem = ({
  title,
  imageId,
  alt,
}: {
  title: string;
  imageId?: number;
  alt: string;
}) => {
  return (
    <FieldValueHelper
      title={title}
      children={[
        imageId ? (
          <img
            key={'image'}
            src={getResourceUrl(imageId)}
            alt={alt}
            style={{ maxWidth: 250 }}
          />
        ) : (
          <InsertPhoto key={'placeholder'} color="primary" />
        ),
      ]}
    />
  );
};
export const CreativeView: React.FC<CreativeViewProps> = (
  props: CreativeViewProps,
) => {
  const { isAuthenticated } = useAuthentication();
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const creativeId = params.id;
  const dispatch = useAppDispatch();
  const [fetchedCreatives, setFetchedCreatives] = useState<boolean>(false);
  const [fetchedBrands, setFetchedBrands] = useState<boolean>(false);
  const [activeCreative, setActiveCreative] = useState<Creative | undefined>(
    props.creative,
  );
  const { creatives, brands } = useAppSelector((state: ReduxState) => state);
  const activeBrand = brands.find(
    (b: Brand) => b.id === activeCreative?.brandId,
  );
  useEffect(() => {
    if (isAuthenticated) {
      if (!activeCreative && creativeId) {
        const creative: Creative | undefined = creatives.find(
          (c: Creative) => c.id === +creativeId,
        );
        if (creative) {
          setActiveCreative(creative);
        } else if (!fetchedCreatives) {
          dispatch(readById(+creativeId) as any);
          setFetchedCreatives(true);
        }
      }
      if (brands.length === 0 && !fetchedBrands) {
        dispatch(readAllBrands() as any);
        setFetchedBrands(true);
      }
    }
  }, [
    activeCreative,
    brands.length,
    creativeId,
    creatives,
    dispatch,
    fetchedBrands,
    fetchedCreatives,
    isAuthenticated,
  ]);

  return (
    <div className={classes.objectViewContainer}>
      <Grid container spacing={8}>
        <Grid item xs={4}>
          <Box display={'flex'} sx={{ my: 3 }}>
            <Title text={activeCreative?.externalId || 'Creative'} />
          </Box>
        </Grid>
        <Grid item xs={6} marginTop={2}>
          <Chip
            label="Is Active"
            icon={
              activeCreative?.isActive ? (
                <AirplanemodeActive style={{ fill: 'green' }} />
              ) : (
                <AirplanemodeInactive style={{ fill: 'red' }} />
              )
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextItem title={'Ad Unit Type'} value={activeCreative?.adUnitType} />
        </Grid>
        <Grid item xs={6}>
          <TextItem
            title={'Brand Name'}
            value={!!activeBrand ? activeBrand.name : ''}
          />
        </Grid>
        <Grid item xs={4}>
          <ImageItem
            title={'Logo'}
            imageId={activeCreative?.logotypeResourceId}
            alt={'logo'}
          />
        </Grid>
        <Grid item xs={6}>
          <ImageItem
            title={'Image (Optional)'}
            imageId={activeCreative?.imageResourceId}
            alt={"creative's asset"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextItem title={'Headline'} value={activeCreative?.headline} />
        </Grid>
        <Grid item xs={12}>
          <TextItem
            title={'Body'}
            value={activeCreative?.bodyWithLinksHandlebars}
          />
        </Grid>
        <Grid item xs={12}>
          <TextItem
            title={'Body without links'}
            value={activeCreative?.bodyWithoutLinks}
          />
        </Grid>
        <Grid item xs={12}>
          <TextItem title={'Footnote'} value={activeCreative?.footnote} />
        </Grid>
        <Box className={classes.actions}>
          {props.toggleVisibility ? (
            <Button
              variant="outlined"
              className={classes.actionButton}
              onClick={props?.toggleVisibility}
            >
              {'Done'}
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </Grid>
    </div>
  );
};

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
interface SectionTitleProps {
  id?: string;
  icon?: JSX.Element;
  title: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  id,
  icon,
  title,
}: SectionTitleProps): JSX.Element => (
  <Box display="flex">
    {icon ? icon : <></>}
    <Typography
      id={id}
      component="h2"
      fontSize="1.25rem"
      lineHeight="115%"
      fontWeight="bold"
      marginLeft="0.625rem"
    >
      {title}
    </Typography>
  </Box>
);

export default SectionTitle;

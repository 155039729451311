import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Title } from '../../../common/titles/title/title';
import { LineItem } from '../lineItem.interface';
import { SectionProps } from './sectionProps';
import { conditions } from '../conditions';
import { Conditional } from '../../../common/conditional/conditional';
import { useAppSelector } from '../../../hooks/redux.hook';
import { ReduxState } from '../../../store/store.interface';
import { Organization } from '../../organizations/organization.interface';
import { Brand } from '../../brands/brand.interface';
import { sortObjectsByAttribute } from '../../../common/utils/sort';

export const BasicSection = ({
  lineItem,
  brands,
  setLineItem,
  isValidField,
  getFieldMessage,
}: SectionProps) => {
  const activeOrganizationId: number = useAppSelector(
    (state: ReduxState) => state.user.activeOrganizationId,
  );
  const activeOrganization: Organization | undefined = useAppSelector(
    (state: ReduxState) =>
      state.organizations.find((org) => org.id === activeOrganizationId),
  );
  let allowHouseCampaign: boolean = false;
  if (activeOrganization) {
    allowHouseCampaign = conditions.allowHouseCampaign(activeOrganization);
  }
  const removeHouseCampaignFieldValue = () => {
    const updatedLineItem: Partial<LineItem> = {
      ...lineItem,
    };
    if (updatedLineItem.isHouseCampaign && !allowHouseCampaign) {
      updatedLineItem.isHouseCampaign = false;
      setLineItem(updatedLineItem);
    }
  };
  const updateField = (attribute: keyof LineItem) => (eve) => {
    let updatedLineItem: Partial<LineItem> = { ...lineItem };
    if (['name', 'clickUrlHandlebars', 'externalId'].includes(attribute)) {
      updatedLineItem[attribute] = eve.target.value;
    } else if (attribute === 'isHouseCampaign' && allowHouseCampaign) {
      updatedLineItem.isHouseCampaign = eve.target.checked;
    } else if (attribute === 'brandId') {
      updatedLineItem[attribute] = +eve.target.value;
    }

    setLineItem(updatedLineItem);
  };
  const activeBrands: Brand[] =
    brands?.filter((b) => b.organizationId === activeOrganizationId) || [];
  return (
    <>
      <Box sx={{ my: 3 }}>
        <Title text="BASICS" />
      </Box>

      <Grid container sx={{ py: 4 }} spacing={4}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            label="Line Item Name"
            error={!isValidField(lineItem, 'name')}
            helperText={getFieldMessage('name')}
            onChange={updateField('name')}
            value={lineItem.name || ''}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="External Id (Optional)"
            onChange={updateField('externalId')}
            value={lineItem.externalId || ''}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ py: 1 }} spacing={4}>
        <Conditional
          predicate={allowHouseCampaign}
          onHide={removeHouseCampaignFieldValue}
        >
          <Grid item xs={4}>
            <FormControlLabel
              style={{ alignItems: 'flex-start', margin: 0 }}
              control={
                <Checkbox
                  style={{
                    paddingLeft: '0',
                  }}
                  onChange={updateField('isHouseCampaign')}
                  checked={lineItem.isHouseCampaign || false}
                />
              }
              label="House Campaign"
              labelPlacement="top"
            />
          </Grid>
        </Conditional>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="brand-id">Brand Name</InputLabel>
            <Select
              labelId="brand-select"
              label="Brand Name"
              name="brandId"
              value={lineItem.brandId || '0'}
              onChange={updateField('brandId')}
              fullWidth
              required
              error={!isValidField(lineItem, 'brandId')}
            >
              {[
                { id: 0, name: 'Select One' },
                ...sortObjectsByAttribute<Brand>(activeBrands, 'name'),
              ].map((val, i) => {
                return (
                  <MenuItem key={`brand-${i}`} value={val.id}>
                    {val.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            label="Click URL (Handlebars)"
            error={!isValidField(lineItem, 'clickUrlHandlebars')}
            helperText={getFieldMessage('clickUrlHandlebars')}
            onChange={updateField('clickUrlHandlebars')}
            value={lineItem.clickUrlHandlebars || ''}
          />
        </Grid>
      </Grid>
    </>
  );
};

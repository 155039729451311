import React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { RadioSelectProps } from './radioSelect.interface';
import { chipStyles } from '../../../../styles/chip.styles';
import { radioStyles } from '../../../../styles/radio.styles';

const useStyles = makeStyles(() => ({ ...chipStyles, ...radioStyles }));

export const RadioSelect = ({
  title,
  chip,
  options,
  onRadioChange,
  onCheckboxChange,
  logo,
  isOptional = true,
}: RadioSelectProps) => {
  const classes = useStyles();
  return (
    <Box data-testid={'radio-selector'} sx={{ mb: '4rem' }}>
      <FormControl>
        <FormLabel>
          <Box sx={{ mb: 3 }} display={'flex'}>
            <Typography>{title}</Typography>
            {chip && (
              <Box className={classes.chip}>
                {logo && <img src={logo} alt="small logo" />}
                <span className={classes.chipText}>{chip}</span>
              </Box>
            )}
          </Box>
        </FormLabel>
        <RadioGroup name={`${title}-radio-select`}>
          {options.map(({ value, label, description }, i) => {
            return (
              <Box
                key={`${title}-radio-select-${i}`}
                sx={{ p: 1, mb: '2rem' }}
                className={classes.radio}
                display={'flex'}
                alignItems={'center'}
              >
                <Box>
                  <Radio
                    value={value || ''}
                    onChange={() => onRadioChange(value)}
                  />
                </Box>
                <Box>
                  <Box>{label}</Box>
                  <Box>{description}</Box>
                </Box>
              </Box>
            );
          })}
        </RadioGroup>
      </FormControl>

      {isOptional && (
        <Box>
          <Checkbox onChange={(e) => onCheckboxChange(e.target.checked)} />
          <span>I'm flexible</span>
        </Box>
      )}
    </Box>
  );
};

import React, { ChangeEvent } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

import { Currency, formatMoney } from "utilities/money.util";
import { radioStyles } from "styles/radio.styles";

type PricingModelProps = {
    amount: number;
    price: number;
    preferedPricingTerms: string;
    validateAndSetPrice: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    unpick: () => void;
};

export const PricingModelCard = ({
    amount,
    price,
    preferedPricingTerms,
    validateAndSetPrice,
    unpick,
}: PricingModelProps) => {
    const useStyles = makeStyles(() => radioStyles);
    const classes = useStyles();
    return (
        <>
            <Box display={"flex"} alignItems={"center"}>
                <Box sx={{ mt: 5 }}>
                    <Box>Model</Box>
                    <Box
                        sx={{ p: 1, mb: "2rem" }}
                        className={classes.radio}
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <Box>
                            <Radio
                                checked={true}
                                value={preferedPricingTerms}
                                onClick={unpick}
                            />
                        </Box>
                        <Box>
                            <Box>{preferedPricingTerms}</Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box>Price</Box>
                    <TextField
                        margin="normal"
                        variant="outlined"
                        onChange={validateAndSetPrice}
                        fullWidth
                        type={"number"}
                    />
                </Box>
                <Box sx={{ ml: 2 }}>per mailing</Box>
            </Box>
            <Divider />
            <Grid container justifyContent="flex-end">
                <Box sx={{ mb: 5, mr: 8 }}>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ padding: 6, mr: 4 }}>{amount}</Box>
                        <ListItemText
                            primary="Mailings"
                            secondary={"Frequency"}
                        />
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ padding: 6, mr: 4 }}>
                            {formatMoney(Currency.USD, amount * price)}
                        </Box>
                        <ListItemText
                            primary="Cost per mailing"
                            secondary={"Flat rate"}
                        />
                    </Box>
                </Box>
            </Grid>
        </>
    );
};

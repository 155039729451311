import { useParams } from 'react-router';

import { RadioProps } from '../../common/inputs/radio/radio.interface';
import { ViewContext } from '../../common/types/view';
import { useAppSelector } from '../../hooks/redux.hook';
import { PricingModel } from './pricingModel';
import { NewsletterDetails } from '../newsletters/newsletter.interface';
import { PricingModel as PricingModelSpec } from './pricingModel.interface';
import React from 'react';

export const OptionPricingModel = () => {
  const flow = useAppSelector((state) => state.flow);
  const newsletters = useAppSelector((state) => state.newsletters);

  const extractCount = (
    arr: Array<any>,
    count: 'mailingCount' | 'subscriberCount',
  ) => (arr.length ? arr[0][count] : 1);

  const { id, modelContext } = useParams<{
    id?: string;
    modelContext: string;
  }>();
  const viewContext = id ? ViewContext.DEFAULT : ViewContext.FLOW;

  type PricingModelProps = {
    pricingConfig: Array<RadioProps>;
    amount: number;
    maxAmount: number;
  };
  const lineItemConfig: PricingModelProps = {
    pricingConfig: [
      {
        value: PricingModelSpec.FLAT,
        label: 'Flat rate',
      },
      {
        value: PricingModelSpec.CPM,
        label: 'CPM',
      },
      {
        value: PricingModelSpec.CPC,
        label: 'CPC',
      },
    ],
    amount:
      viewContext === ViewContext.FLOW
        ? flow.newsletter.mailingCount
        : extractCount(
            newsletters.filter(
              (newsletter: NewsletterDetails) =>
                newsletter.id === parseInt(id ?? ''),
            ),
            'mailingCount',
          ),
    maxAmount:
      viewContext === ViewContext.FLOW
        ? flow.newsletter.subscriberCount
        : extractCount(
            newsletters.filter(
              (newsletter: NewsletterDetails) =>
                newsletter.id === parseInt(id ?? ''),
            ),
            'subscriberCount',
          ),
  };

  const getConfig = (modelContext: string): PricingModelProps => {
    switch (modelContext) {
      case 'line-item':
        return lineItemConfig;

      default:
        return lineItemConfig;
    }
  };

  const config = getConfig(modelContext || 'lineitem');

  return (
    <PricingModel
      amount={config.amount}
      maxAmount={config.maxAmount}
      pricingConfig={config.pricingConfig}
    />
  );
};

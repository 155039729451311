import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@mui/styles';

import { NumberFieldProps } from "./numberField.interface";
import { chipStyles } from "../../../../styles/chip.styles";

const useStyles = makeStyles(() => ({
    chip: chipStyles.chip,
    chipText: chipStyles.chipText,
    minusButton: {
        border: "1px #5D5FEF solid",
        backgroundColor: "#fff",
        color: "#5D5FEF",
        borderRadius: "50%",
        fontSize: "1rem",
        textAlign: "center",
        marginRight: "1rem",
        height: "2rem",
        width: "2rem",
    },
    plusButton: {
        border: "1px #5D5FEF solid",
        backgroundColor: "#5D5FEF",
        color: "#fff",
        borderRadius: "50%",
        fontSize: "1rem",
        textAlign: "center",
        height: "2rem",
        width: "2rem",
        marginLeft: "1rem",
        marginRight: "2rem",
    },
    numberInput: {
        maxWidth: "3.5rem",
        textAlign: "center",
    },
}));

export const NumberField = ({
    title,
    initialValue,
    chipText,
    description,
    onChange,
    logo,
}: NumberFieldProps) => {
    const classes = useStyles();
    const [value, setValue] = useState(initialValue || 0);
    const increase = () => setValue(value + 1);
    const decrease = () => {
        if (value > 0) setValue(value - 1);
    };

    useCallback(() => onChange(value), [value, onChange]);

    return (
        <Box
            id={`number-input-${title.split(" ")[0].toLocaleLowerCase()}`}
            sx={{ mb: "2.5rem" }}
        >
            <Box sx={{ mb: 3 }} display={"flex"}>
                <Typography>{title}</Typography>
                {chipText && (
                    <Box className={classes.chip}>
                        <img src={logo} alt="small logo" />
                        <span className={classes.chipText}>{chipText}</span>
                    </Box>
                )}
            </Box>
            <button className={classes.minusButton} onClick={decrease}>
                -
            </button>
            <OutlinedInput className={classes.numberInput} value={value} />
            <button className={classes.plusButton} onClick={increase}>
                +
            </button>
            <span>{description}</span>
        </Box>
    );
};

import { FormValidator } from '../../common/forms/formValidator';
import {
  isValidUrl,
  maxCharLength,
  minCharLength,
  minValue,
} from '../../common/forms/validation';
import {
  Brand,
  MAX_BRAND_NAME_LENGTH,
  MAX_VALUE_PROPOSITION_LENGTH,
} from './brand.interface';

export const brandFormValidator = new FormValidator<
  Partial<Brand>,
  keyof Brand
>({
  organizationId: {
    validations: [minValue(1)],
    message: '',
  },
  url: {
    validations: [isValidUrl],
    message: 'A valid URL is required',
  },
  name: {
    validations: [minCharLength(1), maxCharLength(MAX_BRAND_NAME_LENGTH)],
    message: 'Brand name is a required field.',
  },
  logomarkResourceId: {
    validations: [minValue(1)],
    message: 'Logo is a required field.',
  },
  valueProposition: {
    validations: [
      minCharLength(1),
      maxCharLength(MAX_VALUE_PROPOSITION_LENGTH),
    ],
    message: 'A Value Proposition is required.',
    shouldValidate: (brand: Partial<Brand>) => !!brand.valueProposition,
  },
});

import React from "react";
import Assistance from "../../common/assistance/assistance";
import {
  ActionButtonSet,
  ActionButtonSetConfigBuilder,
} from "../../common/buttons/actionButtonSet";
import ViewTitle from "../../common/titles/viewTitle/viewTitle";
import Breadcrumbs from "../../common/breadcrumbs/breadcrumbs";
import { Create } from "./create";
import { Review } from "./review";
import { PlacementProps } from "./placement.interface";
import { FC } from "react";

export const Placement: FC<PlacementProps> = ({ viewContext }) => {
  const adUnits: any[] = [1];
  const config = ActionButtonSetConfigBuilder({
    context: viewContext,
    handlers: [
      {
        flow: () => console.log("go back"),
        default: () => console.log("cancel"),
      },
      {
        flow: () => console.log("flow"),
        default: () => console.log("default"),
      },
    ],
  });
  return (
    <>
      <Breadcrumbs items={["Ad unit"]} />
      <Assistance />
      <ViewTitle
        title="Create an ad unit."
        subTitle="A placement consists of one or more ad units sold together. Create your first placement to get started"
      />

      {adUnits.length ? adUnits.map(Review) : Create}

      <ActionButtonSet config={config} />
    </>
  );
};

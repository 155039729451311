import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { idToReactKey } from '../../../../utilities/format.util';
import { BaseField, SelectRequiredFields } from '../../formGenerator.interface';

type SelectFieldProps = Omit<BaseField & SelectRequiredFields, 'type'>;
export const SelectInput = ({
  id,
  label,
  options,
  value,
  onChange,
}: SelectFieldProps) => {
  return (
    <FormControl data-testid={'select-input'} fullWidth>
      <InputLabel id={idToReactKey(`input_${id}`)}>{label}</InputLabel>
      <Select
        labelId={idToReactKey(`label_id_${id}`)}
        id={idToReactKey(`id_${id}`)}
        value={value || ''}
        label={idToReactKey(`label_${id}`)}
        onChange={(x) => {
          if (x.target.value !== '-1') onChange(x);
        }}
        displayEmpty
      >
        {options?.length ? (
          options.map((op, key) => (
            <MenuItem key={`option-${key}`} value={op.id}>
              {op.value}
            </MenuItem>
          ))
        ) : (
          <MenuItem key={'option-0'} value={-1}>
            No {label}s
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

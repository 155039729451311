import React, { useState } from "react";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";

import ViewTitle from "../../common/titles/viewTitle/viewTitle";
import Assistance from "../../common/assistance/assistance";
import { FormTitle } from "../../common/formTitle/formTitle";
import { Radio } from "../../common/inputs/radio/radio";
import { PricingModel as PricingModelSpec } from "../proposals/lineItems/lineItems.dto";
import { PricingModelProps } from "./pricingModel.interface";
import {
  ActionButtonSet,
  ActionButtonSetConfigBuilder,
} from "../../common/buttons/actionButtonSet";
import { PricingModelCard } from "./pricingModelCard";
import { Pricing } from "./pricing";

export const PricingModel = ({
  viewContext,
  pricingConfig,
  amount,
  maxAmount,
}: PricingModelProps) => {
  const actionButtonsConfig = ActionButtonSetConfigBuilder({
    context: viewContext,
    handlers: [
      {
        flow: () => console.log("go back"),
        default: () => console.log("cancel"),
      },
      {
        flow: () => console.log("flow"),
        default: () => console.log("default"),
      },
    ],
  });
  const [preferedPricingTerms, setPreferedPricingTerms] =
    useState<PricingModelSpec | null>();
  const [price, setPrice] = useState(0);

  const validateAndSetPrice = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    console.log("validate and change price");
    const val = parseInt(e.target.value);
    if (isNaN(val)) setPrice(0);
    else setPrice(val);
  };

  const unpick = () => setPreferedPricingTerms(null);

  return (
    <>
      <Assistance />
      <ViewTitle
        title="Tell us about your pricing"
        subTitle="A placement consists of one or more ad units sold together"
      />

      <RadioGroup>
        {preferedPricingTerms ? (
          <PricingModelCard
            price={price}
            amount={amount || 0}
            preferedPricingTerms={preferedPricingTerms}
            validateAndSetPrice={validateAndSetPrice}
            unpick={unpick}
          />
        ) : (
          <>
            <FormTitle
              title="Model"
              description="The advertiver prefers flat rate over CPM"
            />
            <RadioGroup>
              <Box display={"flex"} alignItems={"center"}>
                {pricingConfig.map(({ value, label }) => (
                  <Radio
                    key={`${label}_radio_selector`}
                    value={value}
                    label={label}
                    onChange={() => setPreferedPricingTerms(value)}
                  />
                ))}
              </Box>
            </RadioGroup>
          </>
        )}

        {price && amount && maxAmount ? (
          <Pricing price={price} amount={amount} maxAmount={maxAmount} />
        ) : null}
      </RadioGroup>

      <ActionButtonSet config={actionButtonsConfig} />
    </>
  );
};

import { AxiosInstance, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ModelTypesWithId } from '../common/types/models';
import AxiosClient from './axios-client.service';

export type AuthOptions = { headers: AxiosRequestHeaders } | undefined;
export type HttpResponse<T> = {
  data?: T | T[] | number;
  error?: any;
};
export class ApiService<T extends ModelTypesWithId> {
  private httpClient: AxiosInstance;
  public baseUrl: string;
  constructor() {
    this.httpClient = AxiosClient.instance;
    this.baseUrl = AxiosClient.baseUrl;
  }

  setAuthToken(
    token: string,
    additionalHeaders?: { [key: string]: any },
  ): AuthOptions {
    return {
      headers: {
        ...(additionalHeaders && additionalHeaders),
        Authorization: `Bearer ${token}`,
      },
    };
  }
  processResponse(
    response: Promise<AxiosResponse<T>>,
  ): Promise<HttpResponse<T>> {
    return response
      .then((res) => {
        return { data: res.data } as HttpResponse<T>;
      })
      .catch((err) => {
        err.response.data.message = [err.message, ...err.response.data.message];
        return { error: err.response.data } as HttpResponse<T>;
      });
  }
  get(endpoint: string, token?: string): Promise<HttpResponse<T>> {
    const requestOptions: AuthOptions = token
      ? this.setAuthToken(token)
      : undefined;
    return this.processResponse(this.httpClient.get(endpoint, requestOptions));
  }
  put(endpoint: string, data: any, token?: string): Promise<HttpResponse<T>> {
    const requestOptions: AuthOptions = token
      ? this.setAuthToken(token)
      : undefined;
    return this.processResponse(
      this.httpClient.put(endpoint, data, requestOptions),
    );
  }
  post(endpoint: string, data: any, token?: string): Promise<HttpResponse<T>> {
    const requestOptions: AuthOptions = token
      ? this.setAuthToken(token)
      : undefined;
    return this.processResponse(
      this.httpClient.post(endpoint, data, requestOptions),
    );
  }
  submitForm(endpoint: string, data: any, token?: string) {
    const requestOptions: AuthOptions = token
      ? this.setAuthToken(token, { 'Content-Type': 'multipart/form-data' })
      : undefined;

    return this.processResponse(
      this.httpClient.post(endpoint, data, requestOptions),
    );
  }
  async delete(endpoint: string, token?: string): Promise<HttpResponse<T>> {
    const requestOptions: AuthOptions = token
      ? this.setAuthToken(token)
      : undefined;
    const response = await this.processResponse(
      this.httpClient.delete(endpoint, requestOptions),
    );
    return response;
  }
}

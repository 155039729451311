enum Colors {
  AliceBlue = '#F4F6FC',
  AmericanPurple = '#404573',
  AshGreen = '#AEC3B0',
  Black = '#000000',
  BrightPurple = '#7951C1',
  BrightPurple50 = '#BCA8E0',
  DarkCharcoal = '#303030',
  DarkPurple = '#412E63',
  DarkSilver = '#6D6D6D',
  GhostWhite = '#F9FAFE',
  IguanaGreen = '#7DB882',
  Isabelline = '#F6F3EC',
  Jacarta = '#393D5C',
  LapisLazuli = '#2680A1',
  Lavender = '#E3E8F9',
  LightCobaltBlue = '#8FA1E5',
  LightMossGreen = '#AFD9B3',
  LightMossGreen50 = '#AFD9B3',
  MidnightGreen = '#124559',
  MustarYellow = '#E9AC0D',
  RichBlack = '#01161E',
  SilverSand = '#C5C5C5',
  Soap = '#C7D0F2',
  TerraCottaDark = '#D85634',
  TerraCotta = '#E07A5f',
  TerraCotta20 = '#F9E4DF',
  White = '#FFFFFF',
}

export default Colors;

import React from "react";
import ViewTitle from "../../common/titles/viewTitle/viewTitle";

interface ReportingProps {}

const Reporting: React.FC<ReportingProps> = (
	props: ReportingProps
): JSX.Element => (
	<>
		<ViewTitle title={"Reporting"} />
	</>
);

export default Reporting;

import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from '../user.interface';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hook';
import { ReduxState } from '../../../store/store.interface';
import {
  initialUserState,
  update as userUpdate,
  fetchUserViaToken,
} from '../../../store/user.slice';
import {
  initialAuthState,
  update as authUpdate,
} from '../../../store/auth.slice';
import { AnyAction, Dispatch } from 'redux';
import * as crypto from 'crypto-ts';

// Auth0 Audience
const audience: string =
  process.env.REACT_APP_AUTH0_AUDIENCE || 'http://localhost:3001';
// Where the user is directed upon logout
const logoutReturnToUri: string =
  process.env.REACT_APP_AUTH0_LOGOUT_RETURN || window.location.origin;
// Where the user is directed upon successful login
const redirectUri: string =
  process.env.REACT_APP_AUTH0_REDIRECT || 'https://localhost:3000';
// Global crypto key
const cryptoKey: string | undefined =
  process.env.REACT_APP_CRYPTO_KEY || 'localcryptokey';
// Basic Auth0 request configuration
const authConfig = {
  audience,
  scope: 'read:everything',
};
/**
 * @description
 * Auth0 User authentication component / widget
 * @returns JSX.Element
 */
export const UserAuth: React.FC = (): JSX.Element => {
  const dispatch: Dispatch<AnyAction> = useAppDispatch();
  // User state as found in Redux
  const { auth: authState, user: userState } = useAppSelector(
    (state: ReduxState) => state,
  );
  // Auth state as found in Redux
  // const authState: AuthState = useAppSelector(
  //   (state: ReduxState) => state.auth,
  // );

  // Auth0 variables
  const {
    user: auth0User, // unused at the moment, user specific information from Auth0, name, picture, etc.
    logout,
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenWithPopup,
    getAccessTokenSilently,
  } = useAuth0();
  // Removes User from session store.
  const deleteUserInSessionStorage = (): void => {
    sessionStorage.removeItem('user');
  };
  // logs the user out and redirects them
  const logoutUser = () => {
    deleteUserInSessionStorage();
    dispatch(userUpdate(initialUserState));
    dispatch(authUpdate(initialAuthState));
    logout({ returnTo: logoutReturnToUri });
  };
  // fetches the User object from our API and stores it in Redux
  const getUserFromApi = async (jwt: string) => {
    try {
      const fetchedResponse = await dispatch(fetchUserViaToken(jwt) as any);
      const fetchedUser: User = { ...fetchedResponse.payload };
      dispatch(userUpdate(fetchedUser));
      dispatch(authUpdate({ isLoading: false }));
      setUserInSessionStorage(fetchedUser);
    } catch (error) {
      console.error('There was an error fetching your user profile: ', error);
      logoutUser();
    }
  };
  // Attempts to fetch the User object from browser session store.
  const getUserFromSessionStorage = async (): Promise<User | undefined> => {
    const sessionUser: string | null = sessionStorage.getItem('user');
    if (sessionUser && cryptoKey) {
      const decryptedBytes = crypto.AES.decrypt(sessionUser, cryptoKey);
      const decryptedUser: User = JSON.parse(
        decryptedBytes.toString(crypto.enc.Utf8),
      );
      await decryptedUser;
      if (decryptedUser.accessToken) {
        await dispatch(userUpdate(decryptedUser));
        await dispatch(authUpdate({ isAuthenticated: true, hasLoaded: true }));
      }
      return decryptedUser;
    }
    return;
  };
  // Encrypts and sets User in session store.
  const setUserInSessionStorage = (sessionUser: User): void => {
    if (sessionUser && cryptoKey) {
      const userBytes = crypto.AES.encrypt(
        JSON.stringify(sessionUser),
        cryptoKey,
      );
      sessionStorage.setItem('user', userBytes.toString());
    }
  };
  // fetches the JWT token from Auth0 upon successful login otherwise logs the user out.
  const getJWT = async () => {
    let incounteredAnError: boolean = false;
    let jwt: string | undefined;

    try {
      jwt = audience.includes('local')
        ? await getAccessTokenWithPopup(authConfig)
        : await getAccessTokenSilently(authConfig);
      dispatch(
        authUpdate({ jwt, user: auth0User, hasLoaded: true, isAuthenticated }),
      );
    } catch (error) {
      console.error('There was an error getting the JWT: ', error);
      incounteredAnError = true;
    } finally {
      if (!incounteredAnError && jwt) {
        getUserFromApi(jwt);
      } else if (incounteredAnError) {
        logoutUser();
      }
    }
  };
  // logs the user out of Auth0 and redirects them.
  const loginUser = () => {
    loginWithRedirect({ redirectUri });
  };
  // Fires on every rerender, and checks the status of authentication
  useEffect(() => {
    (async () => {
      if (!userState.accessToken && !authState.isLoading) {
        await getUserFromSessionStorage();
      }
      if (
        isAuthenticated &&
        !authState.jwt &&
        !authState.isLoading &&
        !userState.accessToken
      ) {
        dispatch(authUpdate({ isLoading: true }));
        await getJWT();
      }
    })();
  });
  // The rendered portion of the component, the very basics.
  return (
    <>
      <Button
        onClick={() => {
          authState.isAuthenticated ? logoutUser() : loginUser();
        }}
        color="primary"
        data-testid="auth-button"
        variant="text"
        sx={{
          borderLeft: '1px solid rgb(0, 0, 0, 0.1)',
          outline: 'none !important',
        }}
      >
        {authState.isAuthenticated ? 'Logout' : 'Login'}
      </Button>
    </>
  );
};

import { FC, ReactNode } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

export type FieldValueHelperProps = {
  title: string;
  value?: string | number;
  helper?: string;
  children?: ReactNode[];
};

export const FieldValueHelper: FC<FieldValueHelperProps> = ({
  title,
  value,
  helper,
  children,
}) => {
  return (
    <Box>
      <Box display="flex">
        <Typography variant="h5" color="primary.dark">
          {title}
        </Typography>
        {helper ? <Helper text={helper} /> : null}
      </Box>
      <Box mt={1}>
        {children ? (
          children
        ) : (
          <Typography variant="body2" color="primary">
            {value || ''}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

type HelperProps = { text: string };
const Helper: FC<HelperProps> = ({ text }) => {
  return (
    <Box ml={1}>
      <Tooltip title={text} placement="right-start">
        <HelpIcon sx={{ fontSize: 12, color: 'primary.dark' }} />
      </Tooltip>
    </Box>
  );
};

import { FC, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Title } from "../titles/title/title";
import ChangeRequestForm from "./changeRequestForm";
import ChangeRequestWidget from "./changeRequestWidget";
import {
  ChangeRequest,
  ChangeRequestWidgetsProps,
} from "./changeRequest.interface";

import styles from "../../styles/changeRequestWidgets.styles";

const ChangeRequestWidgets: FC<ChangeRequestWidgetsProps> = (props) => {
  const [changeRequests, setChangeRequests] = useState(props.changeRequests);
  const [formIndex, setFormIndex] = useState(-1);
  const [adding, setAdding] = useState(false);
  const isEmpty = changeRequests.length === 0;
  const remove = (index: number) => {
    changeRequests.splice(index, 1);
    setChangeRequests([...changeRequests]);
  };
  const edit = (index: number) => {
    if (adding) {
      changeRequests.pop();
      setAdding(false);
    }
    setFormIndex(index);
  };
  const cancel = () => {
    if (adding) {
      changeRequests.pop();
      setAdding(false);
    }
    setFormIndex(-1);
  };
  const save = (index: number, changeRequest: ChangeRequest) => {
    if (index === -1) {
      changeRequests.push(changeRequest);
      setChangeRequests([...changeRequests]);
    } else {
      changeRequests[index] = changeRequest;
      setFormIndex(-1);
    }
  };
  const add = () => {
    changeRequests.push({ description: "" });
    setChangeRequests([...changeRequests]);
    setAdding(true);
    setFormIndex(changeRequests.length - 1);
  };
  return (
    <Box>
      <Title
        text={"Change requests"}
        icon={<EditOutlinedIcon color="primary" />}
      />
      <Box sx={styles.requestsContainer}>
        {isEmpty && props.editable && (
          <ChangeRequestForm
            index={-1}
            changeRequest={{ description: "" }}
            save={save}
          />
        )}
        {changeRequests.map((changeRequest, index) => (
          <ChangeRequestWidget
            key={index}
            index={index}
            changeRequest={changeRequest}
            showForm={formIndex === index}
            editable={props.editable}
            edit={edit}
            remove={remove}
            cancel={cancel}
            save={save}
          />
        ))}
        {props.editable && (
          <Box>
            <Button
              variant="contained"
              sx={styles.addRequestButton}
              startIcon={<AddIcon />}
              onClick={add}
            >
              Add request
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChangeRequestWidgets;

import { Slice } from '@reduxjs/toolkit';
import { ReduxStateKeys } from './store.interface';
import { createTransientObjectSlice } from './generic.slice';
import { ModelContext, ModelMap } from '../common/types/models';

const sliceName: ReduxStateKeys = 'auth';
type ModelType = ModelMap[ModelContext.AUTH];

export const initialAuthState: ModelType = {
  isAuthenticated: false,
  isLoading: false,
  hasLoaded: false,
};

const auth: Slice<ModelType> = createTransientObjectSlice<ModelType>({
  name: sliceName,
  initialState: initialAuthState,
});

export const { update } = auth.actions;

export default auth.reducer;

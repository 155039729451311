// hooks
import { useEffect, useState } from 'react';
// sections
import {
  BasicSection,
  PricingSection,
  DeliverySection,
  TargetingSection,
} from './sections';
// mui
import { Box, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// redux
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/redux.hook';
import { createRecord, updateRecord } from '../../store/lineItems.slice';
import { ReduxState } from '../../store/store.interface';
// utils
import React from 'react';
import { LineItem, LineItemFormProps } from './lineItem.interface';
import { viewStyles } from '../../styles/view.style';
import { makeStyles } from '@mui/styles';
import { PricingModel } from '../pricingModel/pricingModel.interface';
import {
  lineItemFormValidator,
  pricingTermsFormValidator,
} from './lineItem.form.validator';
import { readAll } from '../../store/creatives.slice';
import { CreativeAssignment } from '../creatives/creativeAssignment';

const useStyles = makeStyles(() => viewStyles);

export const LineItemForm: React.FC<LineItemFormProps> = (
  props,
): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isValidObject, isValidField, getFieldMessage } =
    lineItemFormValidator;
  const [fetchedCreatives, setFetchedCreatives] = useState<boolean>(false);

  const { creatives, organizations } = useAppSelector(
    (state: ReduxState) => state,
  );

  const [lineItem, setLineItem] = useState<Partial<LineItem>>(
    !!props?.lineItem?.id || !!props?.lineItem?.budget
      ? props.lineItem
      : {
          isHouseCampaign: false,
          isRoadblock: false,
          isActive: false,
          creativeIdsByAdUnitIndex: [[]],
          pricingTerms: {
            publisherRate: 0,
            advertiserRate: 0,
            model: PricingModel.CPC,
          },
        },
  );

  const activeOrganizationId = useAppSelector(
    (state: ReduxState) => state.user.activeOrganizationId,
  );

  // serilize line item, create and open notificatin snackbar
  const create = async () => {
    lineItem.organizationId = activeOrganizationId;
    const result = await dispatch(createRecord(lineItem) as any);
    if (!result.error) {
      props.notify('Created a line item.');
      props.toggleVisibility();
    } else {
      props.notify(
        `An error occurred while creating the Line Item, ${result.error.message}`,
      );
    }
  };
  const update = async () => {
    let updatedLineItem: Partial<LineItem> = { ...lineItem };
    delete updatedLineItem.hasBeenActive;
    const result = await dispatch(
      updateRecord(updatedLineItem as LineItem) as any,
    );
    if (!result.error) {
      props.notify(`Updated line item ${updatedLineItem.id}`);
      props.toggleVisibility();
    } else {
      props.notify(
        `An error occurred while updating the Line Item, ${result.error.message}`,
      );
    }
  };
  const locale = navigator.language.slice(0, 2);

  useEffect(() => {
    if (creatives.length === 0 && !fetchedCreatives) {
      dispatch(readAll() as any);
      setFetchedCreatives(true);
    }
  }, [creatives.length, dispatch, fetchedCreatives, setFetchedCreatives]);

  return (
    <div className={classes.objectViewContainer}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <Box display={'flex'}>
          <Box>
            <BasicSection
              lineItem={lineItem}
              brands={props.brands}
              setLineItem={setLineItem}
              isValidField={isValidField}
              getFieldMessage={getFieldMessage}
            />
            <TargetingSection
              lineItem={lineItem}
              setLineItem={setLineItem}
              isValidField={isValidField}
              getFieldMessage={getFieldMessage}
              creatives={creatives}
            />
            <PricingSection
              lineItem={lineItem}
              setLineItem={setLineItem}
              lineItemValidator={lineItemFormValidator}
              pricingTermsValidator={pricingTermsFormValidator}
            />
            <DeliverySection
              lineItem={lineItem}
              setLineItem={setLineItem}
              isValidField={isValidField}
              getFieldMessage={getFieldMessage}
            />
            <CreativeAssignment
              creatives={creatives}
              brands={props.brands}
              organizations={organizations}
              lineItem={lineItem}
              setLineItem={setLineItem}
            />
          </Box>
        </Box>
        <Box className={classes.actions}>
          <Button
            variant="outlined"
            className={classes.actionButton}
            onClick={props?.toggleVisibility}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={!!lineItem.id ? update : create}
            disabled={
              !isValidObject(lineItem) ||
              !pricingTermsFormValidator.isValidObject(
                lineItem.pricingTerms || {},
              )
            }
          >
            {'Save'}
          </Button>
        </Box>
      </LocalizationProvider>
    </div>
  );
};

import React from "react";
import { ViewContextProps } from "../../../common/types/view";
import ViewTitle from "../../../common/titles/viewTitle/viewTitle";

export const NewslettersTitle: React.FC<ViewContextProps> = ({
  viewContext,
}) => (
  <ViewTitle
    title={
      viewContext === "Flow"
        ? "Select or create a newsletter."
        : "View or create a Newsletter "
    }
    subTitle="The first step in creating a line item is selecting a newsletter. "
    viewContext={viewContext}
  />
);

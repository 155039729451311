import { Brand } from '../brands/brand.interface';
import { ViewContextProps } from '../../common/types/view';
import { Organization } from '../organizations/organization.interface';
import { RfpDTO } from 'views/rfps/rfpDetails/rfpDetails.interface';
import { LineItem } from '../lineItems/lineItem.interface';

export interface ProposalsProps extends ViewContextProps {}

export enum Party {
  ADVERTISER = 'ADVERTISER',
  PUBLISHER = 'PUBLISHER',
}

export enum ProposalStage {
  DRAFT = 'DRAFT',
  NEW = 'NEW',
  REVISION = 'REVISION',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}

export type ProposalDetailsDTO = {
  id: number;
  url: string;
  name: string;
  creationTimestamp: number;
  expirationTimestamp: number;
  stage: ProposalStage;
  publisher: Organization;
  brand: Brand;
  rfp: RfpDTO;
  lineItems: LineItem[];
  creativeTerms: CreativeTerms;
};

export interface AbstractCreativeTerms {
  advertiserRevisionDays: number;
  advertiserRevisionDaysText: string;
  copyProvider: Party;
  creativeBriefProviderText?: string;
  maxRevisionRoundCount: number;
  maxRevisionRoundCountText: string;
  publisherRevisionDays: number;
  publisherRevisionDaysText: string;
  imageProvider?: Party;
  isCopyProviderFlexible: boolean;
  isImageProviderFlexible: boolean;
  imageProviderText?: string;
  copyProviderText: string;
}

export interface CreativeTerms extends Partial<AbstractCreativeTerms> {
  mailingLeadTimeDays?: number;
  mailingLeadTimeDaysText?: string;
}

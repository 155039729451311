import { Organization } from '../organizations/organization.interface';
import { AdUnitType } from '../adUnit/adUnit.interface';
import { ViewContextProps } from '../../common/types/view';
import { GridSelectionModel } from '@mui/x-data-grid';
import { Brand } from '../brands/brand.interface';
import { LineItem } from '../lineItems/lineItem.interface';

export interface Creative {
  adUnitType: AdUnitType;
  bodyWithLinksHandlebars?: string;
  bodyWithoutLinks?: string;
  brandId?: number;
  clickUrl?: string;
  cta?: string;
  externalId?: string;
  footnote?: string;
  headline?: string;
  id: number;
  imageResourceId?: number;
  isActive: boolean;
  logotypeResourceId?: number;
  organizationId: number;
}

export type CreativeTableProps = {
  edit: (creative?: Creative) => void;
  creatives: Creative[];
  toggleActive: (id: number) => void;
  brands: Brand[];
  organizations: Organization[];
  view: (creative: Creative) => void;
};
export type CreativeCompactTableProps = {
  creatives: Creative[];
  brands: Brand[];
  organizations: Organization[];
  selected: number[];
  onSelection: (ids: GridSelectionModel) => void;
  readonly: boolean;
};
export type CreativeAssignmentProps = {
  creatives: Creative[];
  brands: Brand[];
  organizations: Organization[];
  lineItem: Partial<LineItem>;
  setLineItem: (lineItem: Partial<LineItem>) => void;
};
export interface CreativeViewProps extends ViewContextProps {
  creative?: Creative;
  toggleVisibility?: () => void;
}
export interface CreativeFormProps {
  creative?: Creative | Partial<Creative>;
  toggleVisibility: () => void;
  notify: (message: string) => void;
}
export type CreativeResourceTypes = 'logotypeResourceId' | 'imageResourceId';

export const MAX_CREATIVE_EXTERNAL_ID_LENGTH = 36;
export const MAX_CLICK_URL_LENGTH = 250;
export const MAX_HEADLINE_LENGTH = 100;
export const MAX_BODY_LENGTH = 10 * 1000;
export const MAX_FOOTNOTE_LENGTH = 1000;
export const MAX_CTA_LENGTH = 50;

import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { FieldValueHelper } from '../../common/fields/display/fieldValueHelper';
import { Title } from '../../common/titles/title/title';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { ReduxState } from '../../store/store.interface';
import { useParams } from 'react-router-dom';
import { readById } from '../../store/brands.slice';
import { Brand, BrandViewProps } from './brand.interface';
import { useAuthentication } from '../../common/user/auth/useAuthentication.hook';
import { makeStyles } from '@mui/styles';
import { viewStyles } from '../../styles/view.style';
import { useFindReduxObjects } from '../../store/hooks/useFindReduxObjects.hook';
import { Organization } from '../organizations/organization.interface';
import { DisplayImage } from '../../common/fields/display/image';

const useStyles = makeStyles(() => viewStyles);
const TextItem = ({ title, value }: { title: string; value?: string }) => {
  return (
    <Grid item xs={12}>
      <FieldValueHelper title={title} value={value} />
    </Grid>
  );
};

export const BrandView: React.FC<BrandViewProps> = (props: BrandViewProps) => {
  const { isAuthenticated } = useAuthentication();
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const brandId = params.id;
  const dispatch = useAppDispatch();
  const [fetchedBrand, setFetchedBrand] = useState<boolean>(false);
  const [activeBrand, setActiveBrand] = useState<Brand | undefined>(
    props.brand,
  );
  const organization: Organization | undefined = useFindReduxObjects(
    'organizations',
    activeBrand?.organizationId,
  );
  const brands: Brand[] = useAppSelector((state: ReduxState) => state.brands);

  useEffect(() => {
    if (isAuthenticated) {
      if (!activeBrand && brandId) {
        const brand: Brand | undefined = brands.find(
          (c: Brand) => c.id === +brandId,
        );
        if (brand) {
          setActiveBrand(brand);
        } else if (!fetchedBrand) {
          dispatch(readById(+brandId) as any);
          setFetchedBrand(true);
        }
      }
    }
  }, [activeBrand, brandId, brands, dispatch, fetchedBrand, isAuthenticated]);

  return (
    <div className={classes.objectViewContainer}>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <DisplayImage
            title={''}
            imageId={activeBrand?.logomarkResourceId}
            alt={'logo'}
            maxHeight={75}
          />
        </Grid>
        <Grid item xs={9}>
          <Box display={'flex'} sx={{ my: 3 }}>
            <Title text={activeBrand?.name || ''} />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <TextItem title={'URL'} value={activeBrand?.url || ''} />
        </Grid>
        <Grid item xs={3}>
          <TextItem
            title={'Value Proposition'}
            value={activeBrand?.valueProposition || ''}
          />
        </Grid>
        <Grid item xs={3}>
          <TextItem title={'Organization'} value={organization?.name || ''} />
        </Grid>
        <Box width="100%" />
        <Box className={classes.actions}>
          {props.toggleVisibility ? (
            <Button
              variant="outlined"
              className={classes.actionButton}
              onClick={props?.toggleVisibility}
            >
              {'Done'}
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </Grid>
    </div>
  );
};

import Home from '../views/home';
import StandardLayout from '../layouts/standard/standard.layout';
import NotFound from '../views/notFound/notFound';
import Dashboard from '../views/dashboard/dashboard';
import RfpDetails from '../views/rfps/rfpDetails/rfpDetails';
import Proposals from '../views/proposals/proposals';
import Orders from '../views/orders/orders.view';
import Reporting from '../views/reporting/reporting';
import Campaigns from '../views/campaigns/campaigns';
import FlowLayout from '../layouts/flow/flow.layout';
import FlowView from '../views/flow/flowView';

import Rfps from '../views/rfps/rfps';
import {
  // Outlet,
  // Link,
  // useRoutes,
  // useParams,
  RouteObject,
} from 'react-router-dom';
import { Placement } from '../views/placements/placements';
import { ProposalFlow } from '../common/flows/proposal.flow';
import ProposalDetails from '../views/proposals/proposalDetails/proposalDetails';
import CreativePreferences from '../views/proposals/creativePreferences/creativePreferences';
import ProposalRevisionTimeline from '../views/proposals/revisionTimeline/proposalRevisionTimeline';
import Newsletters from '../views/newsletters/newsletters';
import NewsletterCreate from '../views/newsletters/newsletterCreate/newsletterCreate';
import NewsletterDetails from '../views/newsletters/newsletterDetails';
import { FlowRouter } from '../views/flow/flow.router';
import { ParentFlow } from '../views/flow/test/components/parent';
import { ChildFlow } from '../views/flow/test/components/child';
import { OptionPricingModel } from '../views/pricingModel/optionPricingModel';

import { StateDetail } from '../common/stateDetail/stateDetail';
import { OrganizationsAdminView } from '../views/organizations/organizations.admin.view';
import { OrganizationView } from '../views/organizations/organization.view';
import React from 'react';

import { LineItemsAdminView } from '../views/lineItems/lineItem.admin.view';
import { ProtectedRoute } from './protectedRoute.component';
import { LineItemView } from '../views/lineItems/lineItem.view';
import { ViewContext } from '../common/types/view';
import { CreativesAdminView } from '../views/creatives/creatives.admin.view';
import { CreativeView } from '../views/creatives/creative.view';
import { BrandsAdminView } from '../views/brands/brands.admin.view';
import { BrandView } from '../views/brands/brand.view';

const proposalFlowRouter: FlowRouter = new FlowRouter(ProposalFlow);

export const authorizedRouteConfig: RouteObject[] = [
  //
  {
    path: '/secure',
    element: <ProtectedRoute />,
    // children: [{ index: true, element: <Dashboard /> }],
  },
  //
  {
    path: '/layouts/flow',
    element: <FlowLayout />,
  },
  {
    path: '/layouts/standard',
    element: <StandardLayout />,
  },
  {
    path: '/',
    element: <StandardLayout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'creatives',
        element: <CreativesAdminView />,
      },
      {
        path: 'creative/:id',
        element: <CreativeView viewContext={ViewContext.DEFAULT} />,
      },
      {
        path: 'placements',
        element: <LineItemsAdminView />,
      },
      {
        path: 'placement/:id',
        element: <LineItemView viewContext={ViewContext.DEFAULT} />,
      },
      {
        path: 'brands',
        element: <BrandsAdminView />,
      },
      {
        path: 'brand/:id',
        element: <BrandView viewContext={ViewContext.DEFAULT} />,
      },
      {
        path: 'newsletters',
        children: [
          { index: true, element: <Newsletters /> },
          { element: <NewsletterCreate />, path: 'create' },
          { element: <NewsletterDetails />, path: ':id' },
        ],
      },
      {
        path: 'rfps',
        children: [
          { index: true, element: <Rfps /> },
          { element: <RfpDetails />, path: ':id' },
        ],
      },
      {
        path: 'proposals',
        children: [
          { index: true, element: <Proposals /> },
          {
            element: (
              <FlowView
                flowRouter={proposalFlowRouter}
                title="Create Proposal"
                showNavigation
              />
            ),
            path: 'create',
          },
          { element: <ProposalDetails />, path: ':id' },
          {
            element: <ProposalRevisionTimeline />,
            path: 'revisions',
          },
          {
            element: <CreativePreferences />,
            path: 'creativePreferences',
          },
          {
            element: <CreativePreferences />,
            path: ':id/creativePreferences',
          },
        ],
      },
      {
        path: 'orders',
        element: <Orders />,
      },
      {
        path: 'reporting',
        element: <Reporting />,
      },
      {
        path: 'campaigns',
        element: <Campaigns />,
      },
      {
        element: <Placement />,
        path: 'placements',
      },
      {
        element: <OrganizationView />,
        path: 'organization',
      },
      // testing
      {
        element: <StateDetail mailto="test@test.te" messaging="test" />,
        path: 'testing',
      },
      {
        path: 'pricing-model',
        element: <OptionPricingModel />,
      },
      {
        path: 'newsletters',
        children: [
          { index: true, element: <Newsletters /> },
          {
            path: ':id',
            element: <NewsletterDetails />,
          },
        ],
      },
      {
        path: 'test',
        children: [
          { index: true, element: <Home /> },
          {
            path: 'flow',
            element: <ParentFlow />,
          },
          {
            path: 'flow/child',
            element: <ChildFlow />,
          },
        ],
      },
      {
        path: 'organizations',
        element: <OrganizationsAdminView />,
      },
    ],
  },

  { path: '*', element: NotFound },
];

import { ModelContext, ModelMap } from '../common/types/models';
import { GenericCRUDActions, CrudActionEssential } from './crud.actions';
import { createCollectionSlice } from './generic.slice';
import { ReduxState, ReduxStateKeys } from './store.interface';

const sliceName: ReduxStateKeys = 'newsletters';
type SliceType = ReduxState[typeof sliceName];
type ModelType = ModelMap[ModelContext.NEWSLETTER];

const initialState: SliceType = [];

export const {
  readById,
  readAll,
  createRecord,
  updateRecord,
  deleteRecord,
}: CrudActionEssential<ModelType> = new GenericCRUDActions<ModelType>(
  sliceName,
);
const crudActions: CrudActionEssential<ModelType> = {
  readById,
  readAll,
  createRecord,
  updateRecord,
  deleteRecord,
};
const newsletters = createCollectionSlice<ModelType>({
  name: sliceName,
  initialState,
  crudActions,
});

export const { add, replaceAll, update, remove } = newsletters.actions;
export default newsletters.reducer;

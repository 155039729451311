import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks/redux.hook';
import { ReduxState } from '../../../store/store.interface';
type Authentication = {
  isAuthenticated: boolean;
};
export const useAuthentication = (): Authentication => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const { auth, user } = useAppSelector((state: ReduxState) => state);
  useEffect(() => {
    if (
      auth.isAuthenticated &&
      auth.hasLoaded &&
      user.accessToken &&
      user.accessToken !== 'default'
    ) {
      setIsAuthenticated(true);
    } else if (isAuthenticated) {
      setIsAuthenticated(false);
    }
  }, [auth, user, isAuthenticated]);
  return { isAuthenticated };
};

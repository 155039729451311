import { useAppSelector } from '../../hooks/redux.hook';
import {
  ReduxCollectionModelTypes,
  ReduxCollectionStateKeys,
  ReduxCollectionStateMap,
} from '../store.interface';

export const useFindReduxObjects = <T extends ReduxCollectionModelTypes>(
  modelKey: ReduxCollectionStateKeys,
  id?: number,
): T | undefined => {
  const objectCollection: T[] | undefined = [
    ...useAppSelector(
      (state: ReduxCollectionStateMap) => state[modelKey] as T[],
    ),
  ];
  if (!id || !objectCollection) return undefined;
  return objectCollection.find((obj: T) => obj.id === id);
};

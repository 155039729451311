import { useState } from 'react';

export type NotificationState = {
  isVisible: boolean;
  message: string;
  secondaryMessage?: string;
  durationInMiliseconds: number;
};

export const useNotify = () => {
  const [notification, setNotification] = useState<NotificationState>({
    isVisible: false,
    message: '',
    durationInMiliseconds: 6000,
  });
  return {
    cancel: () =>
      setNotification({
        ...notification,
        isVisible: false,
      }),
    duration: notification.durationInMiliseconds,
    message: notification.message,
    notify: (message: string) =>
      setNotification({
        ...notification,
        message,
        isVisible: true,
      }),
    shouldNotify: notification.isVisible,
  };
};

import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { TitleStyles } from "../../../styles/title.style";
import { makeStyles } from "@mui/styles";

export type TitleProps = {
  text: string;
  icon?: JSX.Element;
};

const useStyles = makeStyles(() => TitleStyles);

export const Title: FC<TitleProps> = ({ text, icon }) => {
  const classes = useStyles();
  return (
    <Box display={"flex"}>
      {icon || null}
      <Typography
        variant={"h3"}
        data-testid={"title"}
        className={icon ? classes.withIcon : classes.withoutIcon}
      >
        {text}
      </Typography>
    </Box>
  );
};

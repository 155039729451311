import { Variant } from "@mui/material/styles/createTypography";
import { ViewContext } from "../../types/view";

export enum ViewTitleType {
  PRIMARY = "Primary",
  SECONDARY = "Secondary",
}
export interface ViewTitleStyleVariants {
  title: Variant; // h1, h2 etc.
  subTitle: Variant;
}
export interface ViewTitleProps {
  title?: string; // xyz page.
  subTitle?: string;
  showDivider?: boolean;
  viewContext?: ViewContext;
  rightAlignedContent?: JSX.Element;
}

import { Organization } from "views/organizations/organization.interface";
import { ViewContextProps } from "../../common/types/view";
import { Brand } from "../../views/brands/brand.interface";

export interface OrdersProps {}

export enum OrderStages {
    NEW = "New",
    NEGOTIATION = "Negotiation",
    EXPIRED = "Expired",
    DECLINED = "Declined",
}

export type Order = {
    id: number;
    name: string;
    publisher: PublisherDetail;
    brand: Brand;
    creationTimestamp: string;
    projectedSpend: string;
    maximumSpend: string;
    startTimestamp: string;
    endTimestamp: string;
    stage: OrderStages;
    ecpc: string;
    alert?: string;
};

export interface OrderProps extends ViewContextProps {
    order?: Order | Partial<Order>;
    cancel: () => void;
    save: (org: Partial<Order>) => void;
}

export type OrderTableProps = {
    edit: (org?: Order) => void;
    orders: Order[];
    brands: Array<Brand>;
    organizations: Array<Organization>;
    remove: (id: number) => void;
};

type PublisherDetail = {
    id: number;
    name: string;
};

export type OrderAdminViewProps = {
    id?: number | string;
};

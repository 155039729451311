import { ThemeOptions, createTheme } from '@mui/material/styles';

import components from './components';
import palette from './palette';
import typography from './typography';

const themeOptions: ThemeOptions = {
  components,
  palette,
  shape: {
    borderRadius: 2,
  },
  typography,
};

export default createTheme(themeOptions);

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import SectionTitle from "../../../../common/titles/sectionTitle";
import GridViewIcon from '@mui/icons-material/GridView';
import DetailsBoxTitle from "../../../../common/titles/detailsBoxTitle";
import {CheckCircleRounded} from "@mui/icons-material";
import {RfpDTO} from "../rfpDetails.interface";
import FlexibleValue from "../../../../common/flexibleValue/flexibleValue";

interface PricingProps {
	data: RfpDTO;
}

const Pricing: React.FC<PricingProps> = ({data}: PricingProps): JSX.Element => (
	<Box marginTop="1.5rem">
		<SectionTitle id="rfps-pricing-title" icon={(<GridViewIcon/>)} title="Pricing"/>
		<Box display="flex" justifyContent="space-between" marginTop="2.5rem">
			<Box>
				<DetailsBoxTitle id="rfps-maximum-spend-subtitle" title="Maximum Spend"/>
				<Typography id="rfps-maximum-spend" marginTop="0.625rem" fontSize="0.875rem" lineHeight="120%" fontWeight="300">
					{data.maximumSpend}
				</Typography>
			</Box>
			<Box>
				<DetailsBoxTitle id="rfps-approved-models-subtitle" title="Approved Models"/>
				<Box id="rfps-approved-models">
					{
						data.preferredPricingTerms.map((p: any) => (
							<Box key={p.pricingModel} marginTop="0.625rem">
								<Typography fontSize="0.875rem" lineHeight="120%" fontWeight="300">
									{p.pricingModel}
								</Typography>
								{
									p.price && (
										<FlexibleValue
											title={p.price}
											flexible={p?.isPriceFlexible}
											rootSx={{marginTop: "0.625rem"}}
										/>
									)
								}
							</Box>
						))
					}
				</Box>
			</Box>
			{
				data.isPricingModelFlexible && (
					<Box>
						<DetailsBoxTitle id="rfps-open-to-other-models-title" title="Open to Other Models?"/>
						<Box display="flex" alignItems="center" marginTop="0.625rem">
							<Box display="flex" alignItems="center" marginTop="0.625rem">
								<CheckCircleRounded sx={{color: '#C4C4C4'}}/>
								<Typography id="rfps-open-to-other-models" fontSize="0.875rem" lineHeight="120%" fontWeight="300"
														marginLeft="0.625rem">Yes</Typography>
							</Box>
						</Box>
					</Box>
				)
			}
		</Box>
	</Box>
);

export default Pricing;

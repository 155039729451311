import { SxProps, Theme } from "@mui/material/styles";
import Colors from "../theme/palette/colors";

const styles: { [key: string]: SxProps<Theme> } = {
  container: {},
  requestsContainer: {
    display: "grid",
    rowGap: (theme: Theme) => theme.spacing(3),
    padding: (theme: Theme) => `${theme.spacing(3)} ${theme.spacing(4.375)}`,
  },
  addRequestButton: {
    color: Colors.AliceBlue,
    bgcolor: Colors.BrightPurple,
  },
};

export default styles;

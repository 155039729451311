import React from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
import MuiDrawer from '@mui/material/Drawer';
import { styled, CSSObject, Theme } from '@mui/material/styles';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import MenuIcon from '@mui/icons-material/Menu';

import { PanelSectionProps } from './panel.interface';
import StandardNavigation from '../../navigation/standardNavigation';
import { makeStyles, useTheme } from '@mui/styles';
import logo from '../../images/short-logo.svg';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#393D5C',
  borderTopRightRadius: 5,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  backgroundColor: '#393D5C',
  borderTopRightRadius: 5,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
const useStyles = makeStyles((theme: any) => ({
  navigation: {
    width: 102,
    minHeight: '100vh',
    height: '100%',
    backgroundColor: theme.palette.primary.dark,
    borderTopRightRadius: 5,
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
    paddingTop: 30,
  },
}));
const PanelSection: React.FC<PanelSectionProps> = (
  props: PanelSectionProps,
) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box id={'panel'} sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            src={logo}
            id="short-logo"
            className={classes.logo}
            alt="Wellput logo"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
          />
          {/* {open ? closePanelButton() : openPanelButton()} */}
        </DrawerHeader>
        <Divider />
        <StandardNavigation />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
      </Box>
    </Box>
  );
};

const mapStateToProps: any = (state: any) => ({});

const mapDispatchToProps = (dispatch: Function, getState: Function) => ({});

export default connect<PanelSectionProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PanelSection);

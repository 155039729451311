import React from "react";
import ViewTitle from "../../common/titles/viewTitle/viewTitle";

interface RfpsProps {}

const Rfps: React.FC<RfpsProps> = (props: RfpsProps): JSX.Element => (
	<>
		<ViewTitle title={"Requests for Proposal"} />
	</>
);

export default Rfps;

import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
// import { ToggleOff, ToggleOn, Edit, Visibility } from '@mui/icons-material';
import React from 'react';
import {
  Creative,
  CreativeCompactTableProps,
  // CreativeTableProps,
} from './creative.interface';
// import { Link } from 'react-router-dom';
import { Organization } from '../organizations/organization.interface';
import { AirplanemodeActive, AirplanemodeInactive } from '@mui/icons-material';
import { Brand } from '../brands/brand.interface';

const creativeFields = <T extends Creative>(
  brands: Brand[],
  organizations: Organization[],
): GridColDef<T>[] => {
  return [
    {
      field: 'isActive',
      headerName: 'Status',
      width: 55,
      align: 'center',
      renderCell: (params: GridCellParams<T['isActive']>) => {
        return params.value ? (
          <AirplanemodeActive style={{ fill: 'green' }} />
        ) : (
          <AirplanemodeInactive style={{ fill: 'red' }} />
        );
      },
      renderHeader: () => <strong>{'Status'}</strong>,
    },
    {
      field: 'id',
      flex: 0.02,
      renderHeader: () => <strong>{'ID'}</strong>,
    },
    {
      field: 'externalId',
      headerName: 'External ID',
      flex: 0.12,
      renderHeader: () => <strong>{'External ID'}</strong>,
    },
    {
      field: 'organizationId',
      flex: 0.2,
      renderCell: (params: GridCellParams<T['organizationId']>) => {
        const organization = organizations.find(
          (org: Organization) => org.id === params.value,
        );
        return <>{organization?.name || ''}</>;
      },
      renderHeader: () => <strong>{'Organization'}</strong>,
    },
    {
      field: 'brandId',
      flex: 0.2,
      renderCell: (params: GridCellParams<T['brandId']>) => {
        const brand = brands.find((b: Brand) => b.id === params.value);
        return <>{brand?.name || ''}</>;
      },
      renderHeader: () => <strong>{'Brand'}</strong>,
    },
    {
      field: 'adUnitType',
      flex: 0.2,
      renderHeader: () => <strong>{'Ad Unit Type'}</strong>,
    },
    {
      field: 'bodyWithLinksHandlebars',
      flex: 1,
      renderHeader: () => <strong>{'Body'}</strong>,
    },
  ];
};

export const CreativesCompactTable: React.FC<CreativeCompactTableProps> = ({
  creatives,
  brands,
  organizations,
  selected,
  onSelection,
  readonly,
}) => {
  const fieldMap = creativeFields(brands, organizations);
  return (
    <>
      {!creatives || creatives.length === 0 ? (
        <>{'No Creatives were found'}</>
      ) : (
        <DataGrid
          {...{
            rows: [...creatives].sort(
              (a: Creative, b: Creative) => a.id - b.id,
            ),
            columns: fieldMap,
            autoHeight: true,
            checkboxSelection: !readonly,
            hideFooter: true,
            ...(!readonly && {
              onSelectionModelChange: onSelection,
              selectionModel: selected || [],
            }),
          }}
        />
      )}
    </>
  );
};

import React from 'react';
import { FieldValueHelper } from './fieldValueHelper';
import { InsertPhoto } from '@mui/icons-material';
import { getResourceUrl } from '../../../store/resources.slice';

export type DisplayImageProps = {
  title?: string;
  imageId?: number;
  imageUrl?: string;
  maxWidth?: number;
  maxHeight?: number;
  alt?: string;
};
export const DisplayImage: React.FC<DisplayImageProps> = (
  props,
): JSX.Element => {
  const url = props.imageUrl
    ? props.imageUrl
    : props.imageId
    ? getResourceUrl(props.imageId)
    : undefined;
  return props.title ? (
    <FieldValueHelper
      title={props.title}
      children={[
        url ? (
          <img
            key={props.title}
            src={url}
            alt={props.alt || ''}
            style={{
              maxWidth: props.maxWidth || 250,
              maxHeight: props.maxHeight || 250,
            }}
          />
        ) : (
          <InsertPhoto key={'placeholder'} color="primary" />
        ),
      ]}
    />
  ) : (
    <img
      src={url}
      alt={props.alt || ''}
      style={{
        maxWidth: props.maxWidth || 250,
        maxHeight: props.maxHeight || 250,
      }}
    />
  );
};

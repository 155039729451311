import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { chipStyles } from "styles/chip.styles";
import { formTitleProps } from "./formTitle.interface";

export const FormTitle: React.FC<formTitleProps> = ({ title, description }) => {
    const useStyles = makeStyles(chipStyles);
    const classes = useStyles();
    return (<Box sx={{ mb: 3 }} display={"flex"}>
        <Typography>{title}</Typography>
        {description ? <Box className={classes.chip}>
            <span className={classes.chipText}>description</span>
        </Box> : null}
    </Box>);
};

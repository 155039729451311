import { SxProps, Theme } from "@mui/material/styles";
import Colors from "../theme/palette/colors";

const styles: { [key: string]: SxProps<Theme> } = {
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: (theme: Theme) => theme.shape.borderRadius,
    border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
  },
  content: {
    padding: (theme: Theme) => theme.spacing(4),
  },
  descriptionContainer: {
    maxWidth: "400px",
    marginBottom: (theme: Theme) => theme.spacing(3),
  },
  commentContainer: {
    maxWidth: "400px",
    marginTop: (theme: Theme) => theme.spacing(2),
  },
  textField: {
    "& textarea": {
      zIndex: 1,
    },
    "& input": {
      zIndex: 1,
    },
    "& fieldset": {
      backgroundColor: Colors.AliceBlue,
      border: "none",
      borderRadius: (theme: Theme) => theme.shape.borderRadius,
    },
  },
  textFieldHelper: {
    color: Colors.RichBlack,
    marginX: 0,
    textAlign: "right",
  },
  buttonContainer: {
    textAlign: "right",
  },
  cancelButton: {
    marginRight: (theme: Theme) => theme.spacing(2),
    color: Colors.RichBlack,
  },
};

export default styles;

import * as React from 'react';
import { Navigate } from 'react-router-dom';
import NotFound from '../views/notFound/notFound';
import { useAuthentication } from '../common/user/auth/useAuthentication.hook';

export type ProtectedRouteProps = {
  outlet?: JSX.Element;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (
  props: ProtectedRouteProps,
): JSX.Element => {
  const { isAuthenticated } = useAuthentication();
  if (isAuthenticated) {
    return props.outlet || <NotFound />;
  } else {
    return <Navigate to={{ pathname: '/' }} />;
  }
};

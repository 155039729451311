import { FC, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import styles from 'styles/cardHeader.styles';

export type CardHeaderProps = {
  variant?: string;
  leftAligned?: ReactElement;
  rightAligned?: string;
};

const CardHeader: FC<CardHeaderProps> = ({
  variant = 'primary.main',
  leftAligned = null,
  rightAligned = '',
}) => {
  return (
    <Box sx={styles.container} bgcolor={variant}>
      <div>{leftAligned || <></>}</div>
      <Typography>{rightAligned || ''}</Typography>
    </Box>
  );
};

export default CardHeader;


import * as React from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";

import { FlowLayoutProps } from "./flowLayout.interface";
import { FlowPanelSection } from "../../common/sections/panel/flowPanel.section";
import FlowViewSection from "../../common/sections/view/flowView.section";
import { ViewContext } from "../../common/types/view";

const FlowLayout: React.FC<FlowLayoutProps> = (
	props: FlowLayoutProps
): JSX.Element => {
	return (
		<Box display={"flex"}>
			<FlowPanelSection />
			<FlowViewSection viewContext={ViewContext.DEFAULT} outlet={<Outlet />} />
		</Box>
	);
};

const mapStateToProps: any = (state: any) => ({});
const mapDispatchToProps: any = (
	dispatch: Function,
	getState: Function
) => ({});

export default connect<FlowLayoutProps>(
	mapStateToProps,
	mapDispatchToProps
)(FlowLayout);

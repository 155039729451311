import { Box } from "@mui/material";
import { Title, TitleProps } from "../titles/title";
import React from "react";

export interface SectionProps {
  title?: JSX.Element;
  titleProps?: TitleProps;
  content: JSX.Element | string;
}
export const Section: React.FC<SectionProps> = (
  props: SectionProps
): JSX.Element => {
  const theTitle = props.title ? (
    props.title
  ) : (
    <Title {...(props.titleProps as TitleProps)} />
  );
  return (
    <Box>
      {theTitle}
      <Box>{!!props.content ? props.content : "Content not provided."}</Box>
    </Box>
  );
};

export const Auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || 'wellput.us.auth0.com',
    clientId: process.env.REACT_APP_AUTH0_CLIENTID || 'ujdfl6qk32ZMq0CLWkNccKsYth6FFfN2',
    redirectUri: 
      process.env.REACT_APP_AUTH0_REDIRECT ||
      `https://localhost:3000/`
    ,
    returnTo: 
      process.env.REACT_APP_AUTH0_REDIRECT ||
      `https://localhost:3000/`
    ,
    scope: "read:current_user email"
}
import { FormValidator } from '../../common/forms/formValidator';
import {
  includesLinkTag,
  isDefined,
  isMemberOfEnum,
  maxCharLength,
  minCharLength,
  minValue,
} from '../../common/forms/validation';
import { AdUnitType } from '../adUnit/adUnit.interface';
import {
  Creative,
  MAX_BODY_LENGTH,
  MAX_CREATIVE_EXTERNAL_ID_LENGTH,
  MAX_CTA_LENGTH,
  MAX_FOOTNOTE_LENGTH,
  MAX_HEADLINE_LENGTH,
} from './creative.interface';

export const creativeFormValidator = new FormValidator<
  Partial<Creative>,
  keyof Creative
>({
  externalId: {
    validations: [
      minCharLength(1),
      maxCharLength(MAX_CREATIVE_EXTERNAL_ID_LENGTH),
    ],
    message: '',
    shouldValidate: (creative: Partial<Creative>) =>
      isDefined(creative.externalId),
  },
  organizationId: {
    validations: [minValue(1)],
    message: '',
  },
  adUnitType: {
    validations: [isMemberOfEnum(AdUnitType)],
    message: '',
  },
  brandId: {
    validations: [minValue(1)],
    message: 'Brand name is a required field.',
  },
  logotypeResourceId: {
    validations: [minValue(1)],
    message: 'Logo is a required field.',
  },
  headline: {
    validations: [minCharLength(1), maxCharLength(MAX_HEADLINE_LENGTH)],
    message: 'A Headline is required.',
  },
  imageResourceId: {
    validations: [minValue(1)],
    message: '',
    shouldValidate: (creative: Partial<Creative>) => !!creative.imageResourceId,
  },
  bodyWithLinksHandlebars: {
    validations: [
      minCharLength(1),
      includesLinkTag,
      maxCharLength(MAX_BODY_LENGTH),
    ],
    message: 'A body with links is required.',
  },
  bodyWithoutLinks: {
    validations: [minCharLength(1), maxCharLength(MAX_BODY_LENGTH)],
    message: 'A body without links is required.',
  },
  cta: {
    validations: [minCharLength(1), maxCharLength(MAX_CTA_LENGTH)],
    message: 'A call to action is required.',
  },
  footnote: {
    validations: [minCharLength(1), maxCharLength(MAX_FOOTNOTE_LENGTH)],
    message: '',
    shouldValidate: (creative: Partial<Creative>) => !!creative.footnote,
  },
});

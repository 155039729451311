import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import { FlowViewSectionProps } from './flowView.interface';
import { Grid } from '@mui/material';
import { UserWidget } from '../../user/user.widget.component';
import { sectionStyles } from '../../../styles/section.style';

const useStyles = makeStyles(() => sectionStyles);

const FlowViewSection: React.FC<FlowViewSectionProps> = (
  props: FlowViewSectionProps,
) => {
  const classes = useStyles();
  return (
    <Box id={'flow-view'} flexGrow={1} className={classes.sectionContainer}>
      <Grid container justifyContent="flex-end">
        <UserWidget />
      </Grid>
      <Outlet />
    </Box>
  );
};

const mapStateToProps: any = (state: any) => ({});

const mapDispatchToProps = (dispatch: Function, getState: Function) => ({});

export default connect<FlowViewSectionProps>(
  mapStateToProps,
  mapDispatchToProps,
)(FlowViewSection);

import React from "react";
import ViewTitle from "../../../common/titles/viewTitle/viewTitle";

interface NewsletterCreateProps {}

const NewsletterCreate: React.FC<NewsletterCreateProps> = (
  props: NewsletterCreateProps
): JSX.Element => (
  <>
    <ViewTitle title={"Create Newsletter"} />
  </>
);

export default NewsletterCreate;

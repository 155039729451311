import SvgIcon from "@mui/material/SvgIcon";

export const UploadFileIcon: React.FC = (): JSX.Element => (
	<SvgIcon width="45" height="43" viewBox="0 0 45 43" fill="none">
		<path
			d="M9.44166 11.1146C10.4891 5.1361 15.7088 0.625 21.9482 0.625C28.1877 0.625 33.4073 5.1361 34.4549 11.1146H34.6462C39.5246 11.1146 43.4795 15.0694 43.4795 19.9479C43.4795 20.3611 43.4512 20.7677 43.3962 21.1658C42.3934 19.9819 41.2023 18.9626 39.8691 18.1543C39.1244 15.9856 37.0674 14.4271 34.6462 14.4271L32.9764 14.4271C32.0933 14.4272 31.3655 13.7344 31.3222 12.8523C31.0768 7.87109 26.9567 3.9375 21.9482 3.9375C16.9398 3.9375 12.8196 7.87109 12.5744 12.8523C12.531 13.7344 11.8032 14.4272 10.9201 14.4271L9.25033 14.4271C6.20126 14.4271 3.72949 16.8989 3.72949 19.9479C3.72949 22.997 6.20126 25.4688 9.25033 25.4688H18.967C18.5788 26.5206 18.3105 27.6303 18.1782 28.7813H9.25033C4.37181 28.7813 0.416992 24.8263 0.416992 19.9479C0.416992 15.0694 4.37181 11.1146 9.25037 11.1146H9.44166ZM44.5837 30.4375C44.5837 37.1455 39.1459 42.5833 32.4378 42.5833C25.7298 42.5833 20.292 37.1455 20.292 30.4375C20.292 23.7295 25.7298 18.2917 32.4378 18.2917C39.1459 18.2917 44.5837 23.7295 44.5837 30.4375ZM31.3337 26.4782V37.0625C31.3337 37.6722 31.8281 38.1667 32.4378 38.1667C33.0476 38.1667 33.542 37.6722 33.542 37.0625V26.4782L37.1778 30.1142C37.6091 30.5453 38.3082 30.5453 38.7395 30.1142C39.1706 29.6829 39.1706 28.9838 38.7395 28.5525L33.2187 23.0316C32.7874 22.6006 32.0882 22.6006 31.657 23.0316L26.1361 28.5525C25.7051 28.9838 25.7051 29.6829 26.1361 30.1142C26.5674 30.5453 27.2666 30.5453 27.6979 30.1142L31.3337 26.4782Z"
			fill="#212121"
			fillOpacity="0.6"
		/>
	</SvgIcon>
);

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { PlacementLine } from "./placementLine";
import { ChipAdUnitButtons } from "./chipAdUnitButtons";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { ReduxState } from "store/store.interface";
import { readAll } from "store/placement.slice";
import { AdUnitKeys } from "views/adUnit/adUnit.interface";
import { reviewCardStyles } from "styles/reviewCard.style";

const useStyles = makeStyles(() => reviewCardStyles);

export const Review = () => {
  const placementId = useParams<{ id?: string }>();
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const [fetched, setFetched] = useState(false);

  const placements = useAppSelector((state: ReduxState) => state.placements);
  const flowPlacement = useAppSelector(
    (state: ReduxState) => state.flow.placement
  );
  useEffect(() => {
    if (placements.length === 0 && !fetched) {
      dispatch(readAll() as any);
      setFetched(true);
    }
  }, [placements.length, fetched, dispatch]);

  const extractKeyFromFlow = ({ key }: AdUnitKeys) => {
    if (flowPlacement.adUnits?.length) {
      return flowPlacement.adUnits[0][key];
    }
    return "";
  };

  const extractKeyById = (index: number, { key }: AdUnitKeys) => {
    return placements.filter((p) => p.id === placementId)[0].adUnits[index][
      key
    ];
  };

  const placementLines = (index = 0) => [
    {
      name: "branding",
      description: placementId
        ? extractKeyFromFlow({ key: "branding" })
        : extractKeyById(index, { key: "branding" }),
    },
    {
      name: "image",
      description: placementId
        ? extractKeyFromFlow({ key: "image" })
        : extractKeyById(index, { key: "image" }),
    },
    {
      name: "headline",
      description: placementId
        ? extractKeyFromFlow({ key: "headline" })
        : extractKeyById(index, { key: "headline" }),
    },
    {
      name: "CTA Button",
      description: placementId
        ? extractKeyFromFlow({ key: "ctaButton" })
        : extractKeyById(index, { key: "ctaButton" }),
    },
    {
      name: "footnote",
      description: placementId
        ? extractKeyFromFlow({ key: "footnote" })
        : extractKeyById(index, { key: "footnote" }),
    },
  ];

  return (
    <Box className={classes.container}>
      <Box>
        <Typography className={classes.title} variant="h4">
          Presenting Placement
        </Typography>
        <Typography>3% click rate in The Daily</Typography>
        {placements
          .filter((p) => p.id === placementId)[0]
          .adUnits.map((_, i) => (
            <Box className={classes.wrapper}>
              <Box>
                <Box>
                  <Typography className={classes.title}>
                    Sponsored Content # {i + 1}
                  </Typography>
                  <Divider />
                </Box>

                <Box className={classes.actions}>
                  <ChipAdUnitButtons />
                </Box>
              </Box>

              {placementLines(i).map(({ name, description }) => (
                <PlacementLine name={name} description={description} />
              ))}
            </Box>
          ))}
      </Box>
      <Button variant="outlined">AD UNIT</Button>
    </Box>
  );
};

import React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { TitleStyles } from '../../../styles/title.style';
import { ViewContext } from '../../types/view';
import {
  ViewTitleProps,
  ViewTitleStyleVariants,
  ViewTitleType,
} from './viewTitle.interface';
import { Box } from '@mui/material';

const useStyles = makeStyles(TitleStyles);
const ViewTitle: React.FC<ViewTitleProps> = ({
  title = '',
  subTitle,
  showDivider = true,
  viewContext = ViewContext.DEFAULT,
  rightAlignedContent,
}: ViewTitleProps) => {
  const classes = useStyles();
  const isDefaultContext = viewContext === ViewContext.DEFAULT;
  showDivider = showDivider !== undefined ? showDivider : isDefaultContext;
  const typeLabel = viewContext.toLowerCase();
  const titleType: ViewTitleType = isDefaultContext
    ? ViewTitleType.PRIMARY
    : ViewTitleType.SECONDARY;
  const viewTitleVariants: { [titleType: string]: ViewTitleStyleVariants } = {
    Primary: {
      title: 'h1',
      subTitle: 'subtitle2',
    },
    Secondary: {
      title: 'h2',
      subTitle: 'subtitle2',
    },
  };
  return (
    <Box className={classes.ViewTitle}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'}>
          <Typography
            id={`${typeLabel}-view-title`}
            {...{
              variant: viewTitleVariants[titleType].title,
            }}
          >
            {title}
          </Typography>
        </Box>
        {rightAlignedContent ? (
          <Box display={'flex'}>{rightAlignedContent}</Box>
        ) : null}
      </Box>
      {subTitle ? (
        <Typography
          id={`${typeLabel}-sub-title`}
          {...{
            variant: viewTitleVariants[titleType].subTitle,
          }}
        >
          {subTitle}
        </Typography>
      ) : null}

      {showDivider ? <Divider /> : null}
    </Box>
  );
};
export default ViewTitle;

import { ModelTypesWithIdAndName } from '../types/models';
import { Organization } from '../../views/organizations/organization.interface';

export const entityIdToName = <T extends ModelTypesWithIdAndName>(
  id: number,
  entities: T[],
): string | undefined => {
  const found = entities.filter((entity) => entity.id === id);
  if (found.length > 0 && found[0].hasOwnProperty('name')) return found[0].name;
};

export const isWellputOrganization = (organization: Organization): boolean =>
  organization.name.toLowerCase().includes('wellput');

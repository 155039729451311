import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

import Flexible from "./flexible";

interface SecondTitleProps {
	id?: string;
	title: string;
	flexible?: boolean;
	rootSx?: SxProps<Theme>;
}

const FlexibleValue: React.FC<SecondTitleProps> = ({
	id,
	title,
	rootSx,
	flexible = false
}: SecondTitleProps): JSX.Element => (
	<Box display="flex" alignItems="center" sx={rootSx}>
		<Typography id={id} fontSize="0.875rem" lineHeight="120%" fontWeight="300" marginRight="0.625rem">
			{title}
		</Typography>
		{
			flexible && <Flexible/>
		}
	</Box>
);

export default FlexibleValue;

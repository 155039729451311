import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ToggleOff, ToggleOn, Edit, Visibility } from '@mui/icons-material';
import React from 'react';
import { Creative, CreativeTableProps } from './creative.interface';
// import { Link } from 'react-router-dom';
import { Organization } from '../organizations/organization.interface';
import { viewStyles } from '../../styles/view.style';
import { Brand } from '../brands/brand.interface';
import { DisplayImage } from '../../common/fields/display/image';

const creativeFields = <T extends Creative>(
  edit: (creative: Creative) => void,
  toggleActive: (id: number) => void,
  brands: Brand[],
  organizations: Organization[],
  view: (creative: Creative) => void,
): GridColDef<T>[] => {
  return [
    {
      field: 'view',
      flex: 0.06,
      renderCell: (params: GridCellParams<T>) => (
        <>
          <Visibility
            onClick={() => view(params.row as Creative)}
            sx={{ marginRight: 2 }}
            color="primary"
          />
          <Edit onClick={() => edit(params.row as Creative)} color="info" />
        </>
      ),
      renderHeader: () => <strong>{'View / Edit'}</strong>,
    },
    {
      field: 'toggleActive',
      flex: 0.04,
      renderCell: (params: GridCellParams<T>) =>
        params.row.isActive ? (
          <ToggleOn onClick={() => toggleActive(+params.id)} color="success" />
        ) : (
          <ToggleOff onClick={() => toggleActive(+params.id)} color="error" />
        ),
      renderHeader: () => <strong>{'Active'}</strong>,
    },
    {
      field: 'brandId',
      flex: 0.15,
      renderHeader: () => <strong>{'Brand Name'}</strong>,
      renderCell: (params: GridCellParams<T['brandId']>) => {
        const brand = brands.find((b: Brand) => b.id === params.value);
        return <>{brand?.name || ''}</>;
      },
    },
    {
      field: 'organizationId',
      flex: 0.15,
      renderCell: (params: GridCellParams<T['organizationId']>) => {
        const organization = organizations.find(
          (org: Organization) => org.id === params.value,
        );
        return <>{organization?.name || ''}</>;
      },
      renderHeader: () => <strong>{'Seller'}</strong>,
    },
    {
      field: 'id',
      flex: 0.02,
      renderHeader: () => <strong>{'Id'}</strong>,
    },
    {
      field: 'externalId',
      flex: 0.1,
      renderHeader: () => <strong>{'External ID'}</strong>,
    },
    {
      field: 'logotypeResourceId',
      flex: 0.18,
      renderHeader: () => <strong>{'Logo'}</strong>,
      renderCell: (params: GridCellParams<T['logotypeResourceId']>) => {
        return <DisplayImage {...{ maxHeight: 45, imageId: params.value }} />;
      },
    },
    {
      field: 'headline',
      flex: 0.3,
      renderHeader: () => <strong>{'Headline'}</strong>,
    },
    // {
    //   field: 'imageResourceId',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Image'}</strong>,
    // },
    // {
    //   field: 'bodyWithLinksHandlebars',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Body With Links'}</strong>,
    // },
    // {
    //   field: 'bodyWithoutLinks',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Body Without Links'}</strong>,
    // },
    // {
    //   field: 'cta',
    //   flex: 0,
    //   renderHeader: () => <strong>{'CTA'}</strong>,
    // },
    // {
    //   field: 'clickUrl',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Click Url'}</strong>,
    // },
    // {
    //   field: 'footnote',
    //   flex: 0,
    //   renderHeader: () => <strong>{'Footnote'}</strong>,
    // },
  ];
};
export const CreativesTable: React.FC<CreativeTableProps> = ({
  edit,
  creatives,
  toggleActive,
  brands,
  organizations,
  view,
}) => {
  const fieldMap = creativeFields(
    edit,
    toggleActive,
    brands,
    organizations,
    view,
  );

  return (
    <>
      {!creatives || creatives.length === 0 ? (
        <>{'No Creatives were found'}</>
      ) : (
        <DataGrid
          rows={[...creatives].sort((a: Creative, b: Creative) => a.id - b.id)}
          columns={fieldMap}
          autoHeight
          sx={{ ...viewStyles.table }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'desc' }],
            },
          }}
        />
      )}
    </>
  );
};

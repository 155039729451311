import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";

export type PlacementLineProps = {
    name: string;
    description: string;
};

export const PlacementLine = ({ name, description }: PlacementLineProps) => {
    return (
        <ListItem>
            <CheckIcon />
            <ListItemText primary={name} secondary={description} />
        </ListItem>
    );
};

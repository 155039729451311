import * as React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import PanelSection from '../../common/sections/panel/panel.section';
// import ViewSection from '../../common/sections/view/view.section';
import { StandardLayoutProps } from './standardLayout.interface';
import FlowViewSection from '../../common/sections/view/flowView.section';
import { ViewContext } from '../../common/types/view';

const useStyles = makeStyles((theme) => ({
  page: {
    width: '100%',
  },
  root: {
    display: 'flex',

    // height:"calc(100% - 32px)" //height of toolbar if you know it beforehand
  },
  panelSection: {
    display: 'flex',
  },
  viewSection: {
    // border: "2px solid red",
  },
}));

const StandardLayout: React.FC<StandardLayoutProps> = (
  props: StandardLayoutProps,
): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PanelSection />
      {/* <ViewSection outlet={<Outlet />} /> */}
      <FlowViewSection viewContext={ViewContext.DEFAULT} outlet={<Outlet />} />
    </div>
  );
};
// we can specify which props to load from Redux into our component
const mapStateToProps: any = (state: any) => ({});
// this maps local triggers to Redux Reducer functions. You must use
/**
 * @description
 * This function maps local triggers / props to Redux Reducers, Actions, or any other functions.
 * We are utilizing middleware (Thunk) to perform async operations otherwise this mapping function
 * does not support returning promises.
 *
 * @param dispatch
 * @param getState
 * @returns
 */
const mapDispatchToProps: any = (dispatch: Function, getState: Function) => ({
  /**
   * example
   *
   *  getJWT: async (options: AuthProps): Promise<void> => {
   *    const middlewareFunction = await UserActions.fetchJWT(options);
   *    dispatch(middlewareFunction);
   *  },
   */
});
// connect to redux and provide context.
export default connect<StandardLayoutProps>(
  mapStateToProps,
  mapDispatchToProps,
)(StandardLayout);

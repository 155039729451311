import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";

interface DetailsBoxTitleProps {
	id?: string;
	title: string;
	showIcon?: boolean;
}

const DetailsBoxTitle: React.FC<DetailsBoxTitleProps> = ({id, title, showIcon = true}): JSX.Element => (
	<Box display="flex" alignItems="center">
		<Typography id={id} marginRight="0.3125rem" fontSize="0.875rem" lineHeight="1rem">
			{title}
		</Typography>
		{
			showIcon && <HelpIcon sx={{width: '13px', height: '13px', color: "#C4C4C4"}} />
		}
	</Box>
);

export default DetailsBoxTitle;

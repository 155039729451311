import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface BrandProps {
	name: string;
	websiteUrl: string;
	imageUrl: string;
}

const Brand: React.FC<BrandProps> = ({name, websiteUrl, imageUrl}): JSX.Element => (
	<Box display="flex">
		<img src={imageUrl} style={{width: "115px", height: "115px"}} alt="Brand"/>
		<Box marginLeft="1.125rem">
			<Typography id="rfps-brand-name" fontSize="1.25rem">
				{name}
			</Typography>
			<a href={websiteUrl}>
				<Typography id="rfps-website-url" fontSize="0.875rem" lineHeight="121%" color="#000000"
										sx={{textDecoration: "underline"}}>
					{websiteUrl}
				</Typography>
			</a>
		</Box>
	</Box>
);

export default Brand;

import React, { useState } from "react";
import Box from "@mui/material/Box";

import ViewTitle from "../../common/titles/viewTitle/viewTitle";
import { ViewContext } from "../../common/types/view";
import { FlowViewProps } from "./flowView.interface";
import FlowViewNavigation from "../../common/navigation/flowView.navigation";

const FlowView: React.FC<FlowViewProps> = ({
	title,
	subTitle,
	flowRouter,
	parentPrevious,
	parentNext,
	showNavigation,
}: FlowViewProps) => {
	const [currentStep, setCurrentStep] = useState(flowRouter.currentStep);
	const next = () => {
		console.log("hit flow next: ", currentStep);
		flowRouter.navigateForward();
		setCurrentStep(flowRouter.currentStep);
	};
	const previous = () => {
		console.log("hit flow previous: ", currentStep);
		flowRouter.navigateBackward();
		setCurrentStep(flowRouter.currentStep);
	};
	const currentView = flowRouter.renderCurrentControls({
		next,
		previous,
		...(parentNext && parentPrevious && { parentNext, parentPrevious }),
	});
	// flowRouter.navigateToStep(1);
	// let currentView: JSX.Element = flowRouter.renderCurrent();
	return (
		<>
			<ViewTitle
				{...{
					title,
					...(subTitle && { subTitle }),
					showDivider: true,
					viewContext: ViewContext.DEFAULT,
				}}
			/>
			<Box sx={{ display: "flex" }}>
				{showNavigation ? <FlowViewNavigation /> : <></>}
				{/* Flow View Children */}
				{/* <Outlet context={ViewContext.FLOW} /> */}
				{currentView}
				{<>{`Current Step: ${currentStep}`}</>}
			</Box>
		</>
	);
};
export default FlowView;

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";

import { Currency, formatMoney } from "utilities/money.util";

type PricingProps = {
    amount: number;
    price: number;
    maxAmount: number;
};

export const Pricing = ({
    price,
    amount,
    maxAmount,
}: PricingProps) => {
    return (
        <Grid container justifyContent="flex-end">
            <Box>
                <Paper
                    style={{
                        padding: 8,
                        backgroundColor: "#5D5FEF38",
                        marginBottom: 8,
                    }}
                >
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ padding: 6, mr: 4 }}>
                            {formatMoney(Currency.USD, amount * price)}
                        </Box>
                        <ListItemText
                            primary="Project spend"
                            secondary={"Calculated from the above"}
                        />
                    </Box>
                </Paper>
                <Paper
                    style={{
                        padding: 8,
                        backgroundColor: "#8FA1E521",
                    }}
                >
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ padding: 6, mr: 4 }}>
                            {formatMoney(Currency.USD, maxAmount * price)}
                        </Box>
                        <ListItemText
                            primary="Maximum spend"
                            secondary={"Frequency x cost per mailing"}
                        />
                    </Box>
                </Paper>
            </Box>
        </Grid>
    );
};

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SectionTitle from "../../../../common/titles/sectionTitle";
import GridViewIcon from '@mui/icons-material/GridView';
import DetailsBoxTitle from "../../../../common/titles/detailsBoxTitle";
import {RfpDTO} from "../rfpDetails.interface";
import FlexibleValue from "../../../../common/flexibleValue/flexibleValue";

interface OverviewProps {
	data: RfpDTO;
}

const Overview: React.FC<OverviewProps> = ({data}): JSX.Element => (
	<Box marginTop="1.5rem">
		<SectionTitle id="rfps-overview-title" icon={(<GridViewIcon/>)} title="Overview"/>
		<Box marginTop="1.5rem">
			<DetailsBoxTitle id="rfps-value-proposition-subtitle" title="Value Proposition"/>
			<Typography id="rfps-value-proposition" marginTop="0.625rem" fontSize="0.875rem" lineHeight="120%"
									fontWeight="300">
				{data.valueProposition}
			</Typography>
		</Box>
		<Box marginTop="2.5rem">
			<DetailsBoxTitle id="rfps-objectives-subtitle" title="Objectives"/>
			<Typography id="rfps-objectives" marginTop="0.625rem" fontSize="0.875rem" lineHeight="120%" fontWeight="300">
				{data.objectives}
			</Typography>
		</Box>
		<Box display="flex" justifyContent="space-between" marginTop="2.5rem">
			<Box>
				<DetailsBoxTitle id="rfps-date-range-subtitle" title="Date Range"/>
				<Typography id="rfps-date-range" marginTop="0.625rem" fontSize="0.875rem" lineHeight="120%" fontWeight="300">
					{data.preferredScheduleTerms.startDate} - {data.preferredScheduleTerms.endDate}
				</Typography>
			</Box>
			<Box>
				<DetailsBoxTitle id="rfps-frequency-subtitle" title="Frequency"/>
				<FlexibleValue
					id="rfps-frequency"
					title={data.preferredScheduleTerms.frequency.toString()}
					flexible={data.preferredScheduleTerms.isFrequencyFlexible}
					rootSx={{marginTop: "1rem"}}
				/>
			</Box>
			<Box>
				<DetailsBoxTitle id="rfps-cadence-subtitle" title="Cadence"/>

				<FlexibleValue
					id="rfps-cadence"
					title={data.preferredScheduleTerms.cadence}
					flexible={data.preferredScheduleTerms.isCadenceFlexible}
					rootSx={{marginTop: "0.625rem"}}
				/>
			</Box>
		</Box>
	</Box>
);

export default Overview;

import React from 'react';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WidgetsIcon from '@mui/icons-material/Widgets';
// import Link from '@mui/material/Link';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import BarChartIcon from '@mui/icons-material/BarChart';
// import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// import CampaignIcon from '@mui/icons-material/Campaign';
// import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
// import PagesIcon from '@mui/icons-material/Pages';
// import RequestIcon from '@mui/icons-material/RequestQuote';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HubIcon from '@mui/icons-material/Hub';
import Article from '@mui/icons-material/Article';
import { StandardNavigationProps } from './standardNavigation.interface';
import { Link } from 'react-router-dom';

const navigationItems: { [label: string]: JSX.Element } = {
  Organizations: <HubIcon />,
  Brands: <WidgetsIcon />,
  Placements: <FormatListBulletedIcon />,
  Creatives: <Article />,

  // Campaigns: <CampaignIcon />,
  // RFPs: <DoubleArrowIcon />,
  // Proposals: <PagesIcon />,
  // Orders: <RequestIcon />,
  // Reporting: <BarChartIcon />,
};

const StandardNavigation: React.FC<StandardNavigationProps> = (
  props: StandardNavigationProps,
) => {
  return (
    <List id="navigation-list">
      {Object.keys(navigationItems).map((navItem: string, index: number) => (
        <Link
          to={`/${navItem.toLowerCase()}`}
          style={{ textDecoration: 'none', color: '#fff' }}
          key={`nav${index}`}
        >
          <ListItemButton>
            <ListItemIcon style={{ color: '#fff' }}>
              {navigationItems[navItem]}
            </ListItemIcon>
            <ListItemText
              id={`nav-${index}`}
              primary={navItem}
              color="secondary"
            />
          </ListItemButton>
        </Link>
      ))}
    </List>
  );
};

const mapStateToProps: any = (state: any) => ({});

const mapDispatchToProps = (dispatch: Function, getState: Function) => ({});

export default connect<StandardNavigationProps>(
  mapStateToProps,
  mapDispatchToProps,
)(StandardNavigation);

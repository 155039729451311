const toLongdate = (date: Date) =>
    `${date.toLocaleString("en-US", { month: "long" })} ${date.toLocaleString(
        "en-US",
        { day: "numeric" }
    )}, ${date.toLocaleString("en-US", { year: "numeric" })}`;

export const formatTimestamp = (timestamp: string) => {
    return [timestamp]
        .map(parseInt)
        .map((val) => val * 1000)
        .map((val) => new Date(val))
        .map(toLongdate)
        .join();
};
import { SxProps, Theme } from "@mui/material/styles";
import Colors from "../theme/palette/colors";

const styles: { [key: string]: SxProps<Theme> } = {
  container: {
    borderRadius: (theme: Theme) => theme.shape.borderRadius,
    margin: "1rem",
  },
  primary: {
    background: Colors.GhostWhite,
    border: `1px solid ${Colors.Lavender}`,
  },
  header: {
    background: Colors.Soap,
  },
  hasPadding: {
    padding: (theme: Theme) => `${theme.spacing(5)} ${theme.spacing(4)}`,
  }
};

export default styles;
import { AdUnitType } from '../adUnit/adUnit.interface';
import { PricingModel } from '../pricingModel/pricingModel.interface';
import { RequiredFieldsOnly } from '../../common/types/utils';
import { Organization } from '../organizations/organization.interface';
import { ViewContextProps } from '../../common/types/view';
import { Brand } from '../brands/brand.interface';
export const MAX_ITEM_NAME_CHARS = 50; // TODO get from api

export type PricingTerms = {
  publisherRate: number;
  advertiserRate: number;
  model: PricingModel;
};

export interface LineItem {
  id: number;
  name?: string;
  budget: number;
  externalId?: string;
  organizationId: number;
  brandId: number;
  isRoadblock: boolean;
  adUnitType?: AdUnitType;
  adUnitIds?: number[];
  whitelistedNewsletterIds?: number[];
  blacklistedNewsletterIds?: number[];
  pricingTerms: PricingTerms;
  isHouseCampaign: boolean;
  guaranteedVolume?: number;
  pacingLimit?: number;
  pacingPeriod?: string;
  maxFrequency?: number;
  cadenceNumerator?: number;
  cadenceDenominator?: string;
  startTimestamp: number;
  endTimestamp?: number;
  estimatedRevenuePerClick?: number;
  clickUrlHandlebars: string;
  creativeIdsByAdUnitIndex: number[][];
  isActive: boolean;
  hasBeenActive?: boolean;
}
export type LineItemDTO = Omit<LineItem, 'id'>;
export type LineItemFormValidation = RequiredFieldsOnly<LineItemDTO>;

export type LineItemTableProps = {
  edit: (lineItem?: Partial<LineItem>) => void;
  lineItems: LineItem[];
  toggleActive: (id: number) => void;
  organizations: Organization[];
  brands: Brand[];
  view: (lineItem: LineItem) => void;
};
export interface LineItemFormProps {
  lineItem?: LineItem | Partial<LineItem>;
  brands: Brand[];
  toggleVisibility: () => void;
  notify: (message: string) => void;
}
export interface LineItemViewProps extends ViewContextProps {
  lineItem?: LineItem;
  toggleVisibility?: () => void;
  notify?: (message: string) => void;
}

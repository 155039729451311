import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import FlowView from '../../../flow/flowView';
import { FlowRouteParams } from '../../../flow/flow.router';
import { FlowRouter } from '../../flow.router';

import { ViewContextProps } from '../../../../common/types/view';
import { viewStyles } from '../../../../styles/view.style';
interface ChildProps extends ViewContextProps {}

const useStyles = makeStyles(() => viewStyles);

export const Stuff1: React.FC<ChildProps> = (props: ChildProps) => {
  const classes = useStyles();
  const { next, previous, parentPrevious } = props;
  return (
    <div className={classes.actions}>
      <div>{'Cool Stuff 1'}</div>
      <Button
        className={classes.actionButton}
        variant="outlined"
        onClick={parentPrevious || previous}
      >
        {'Cancel'}
      </Button>
      <Button
        className={classes.actionButton}
        variant="contained"
        onClick={next}
      >
        {'Continue'}
      </Button>
    </div>
  );
};

export const Stuff2: React.FC<ChildProps> = (props: ChildProps) => {
  const classes = useStyles();
  const { next, previous } = props;
  return (
    <div className={classes.actions}>
      <div>{'Cool Stuff 2'}</div>
      <Button
        className={classes.actionButton}
        variant="outlined"
        onClick={previous}
      >
        {'Cancel'}
      </Button>
      <Button
        className={classes.actionButton}
        variant="contained"
        onClick={next}
      >
        {'Continue'}
      </Button>
    </div>
  );
};
export const Stuff3: React.FC<ChildProps> = (props: ChildProps) => {
  const classes = useStyles();
  const { next, previous, parentNext } = props;
  return (
    <div className={classes.actions}>
      <div>{'Cool Stuff 3'}</div>
      <Button
        className={classes.actionButton}
        variant="outlined"
        onClick={previous}
      >
        {'Cancel'}
      </Button>
      <Button
        className={classes.actionButton}
        variant="contained"
        onClick={parentNext || next}
      >
        {'Continue'}
      </Button>
    </div>
  );
};

export const ChildFlow: React.FC<ChildProps> = (props: FlowRouteParams) => {
  const flowRouter = new FlowRouter([<Stuff1 />, <Stuff2 />, <Stuff3 />]);
  return (
    <FlowView
      flowRouter={flowRouter}
      title={'Flow Child'}
      parentNext={props.next}
      parentPrevious={props.previous}
    />
  );
};

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {ReactComponent as FlexibleSvg} from 'images/flexible.svg';

interface FlexibleProps {
}

const Flexible: React.FC<FlexibleProps> = (props: FlexibleProps): JSX.Element => (
	<Box display="flex" alignItems="center" borderRadius="1.875rem" style={{background: "rgba(0, 0, 0, 0.08)"}}
			 padding="0.3125rem">
		<FlexibleSvg style={{width: '11px', height: '8px'}}/>
		<Typography sx={{marginLeft: "3px"}} fontSize="0.625rem" lineHeight="120%">
			Flexible
		</Typography>
	</Box>
);

export default Flexible;

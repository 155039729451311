import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import ViewTitle from '../../../common/titles/viewTitle/viewTitle';
import { ViewContext } from '../../../common/types/view';
import { viewStyles } from '../../../styles/view.style';
import { CreativePreferencesProps } from './creativePreferences.interface';
import { RadioSelect } from '../../../common/formGenerators/inputs/radioSelect/radioSelect';
import { CreativeTerms, Party } from '../proposals.interface';
import { useAppDispatch } from 'hooks/redux.hook';
import { update as updateFlow } from 'store/flow.slice';
import { update as updateProposal } from 'store/proposals.slice';

const useStyles = makeStyles(viewStyles);

const CreativePreferences: React.FC<CreativePreferencesProps> = (
  props: CreativePreferencesProps,
) => {
  const dispatch = useAppDispatch();
  const [creativePreferences, setPreferences] = useState<CreativeTerms>({
    isCopyProviderFlexible: false,
    isImageProviderFlexible: false,
  });
  const setCreativePreferences = (creativeTerms: CreativeTerms) => {
    const modelType = 'proposal';
    dispatch(
      !props.proposalId
        ? updateFlow({ type: modelType, data: { creativeTerms } })
        : updateProposal({ data: { id: props.proposalId, creativeTerms } }),
    );
    if (props.next) props.next();
  };
  const updateCreativePreferencesProp = (prop: string) => (value: any) => {
    setPreferences({ ...creativePreferences, [prop]: value });
  };
  useEffect(() => {
    setPreferences(creativePreferences);
  }, [creativePreferences, setPreferences]);
  const classes = useStyles();
  return (
    <div className={classes.viewContainer}>
      <Box sx={{ mb: '3rem' }}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Typography>Want assistance?</Typography>
          <Link href="#" underline="always">
            Get Help
          </Link>
        </Box>
        <ViewTitle
          title="Tell us about your cretive preferences."
          subTitle="Help us understand your preferences better."
          showDivider={false}
          viewContext={ViewContext.FLOW}
        />
      </Box>

      <RadioSelect
        title="Copy"
        chip="The advertiser prefers to write the copy"
        onRadioChange={updateCreativePreferencesProp('copyProvider')}
        onCheckboxChange={updateCreativePreferencesProp(
          'isCopyProviderFlexible',
        )}
        options={[
          {
            value: Party.ADVERTISER,
            label: 'Advertiser writes the copy',
          },
          {
            value: Party.PUBLISHER,
            label: 'Publisher writes the copy',
            description: 'The advertiser fills put the creative brief',
          },
        ]}
      />

      <RadioSelect
        title="Images"
        chip="The advertiser prefers to provide the images"
        onRadioChange={updateCreativePreferencesProp('imageProvider')}
        onCheckboxChange={updateCreativePreferencesProp(
          'isImageProviderFlexible',
        )}
        options={[
          {
            value: Party.ADVERTISER,
            label: 'Advertiser provides any images',
          },
          {
            value: Party.PUBLISHER,
            label: 'Publisher provides the images',
            description: 'The advertiser will provide the images',
          },
        ]}
      />

      <Box className={classes.actions}>
        <Button
          variant="outlined"
          className={classes.actionButton}
          onClick={props.previous}
        >
          {props.viewContext === ViewContext.FLOW ? 'Back' : 'Cancel'}
        </Button>
        <Button
          variant="contained"
          className={classes.actionButton}
          onClick={() => setCreativePreferences(creativePreferences)}
        >
          {props.viewContext === ViewContext.FLOW ? 'Next' : 'Continue'}
        </Button>
      </Box>
    </div>
  );
};

export default CreativePreferences;

import { Slice } from '@reduxjs/toolkit';
import { ReduxState, ReduxStateKeys } from './store.interface';
import { GenericCRUDActions, CrudActionEssential } from './crud.actions';
import { createCollectionSlice } from './generic.slice';
import { ModelContext, ModelMap } from '../common/types/models';
import { ResourceDetails } from '../views/resource/resource.interface';
import { ApiService } from '../services/api.service';

const sliceName: ReduxStateKeys = 'resources';

type SliceType = ReduxState[typeof sliceName];
type ModelType = ModelMap[ModelContext.RESOURCE];

const initialState: SliceType = [];
const api = new ApiService<ResourceDetails>();

export const {
  readById,
  readAll,
  createRecord,
  updateRecord,
  deleteRecord,
}: CrudActionEssential<ModelType> = new GenericCRUDActions<ModelType>(
  sliceName,
);
const crudActions: CrudActionEssential<ModelType> = {
  readById,
  readAll,
  createRecord,
  updateRecord,
  deleteRecord,
};
const resources: Slice<ModelType[]> = createCollectionSlice<ModelType>({
  name: sliceName,
  initialState,
  crudActions,
});

export const getResourceUrl = (id: number) =>
  `${api.baseUrl}/v1/resources/${id}/content`;
export const createResource = (data: any, token?: string) => {
  return api.submitForm('/v1/resources', data, token);
};

export const { add, replaceAll, update, remove } = resources.actions;
export default resources.reducer;

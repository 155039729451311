import Colors from '../theme/palette/colors';

export const TitleStyles = {
  ViewTitle: {
    marginBottom: '50px',
  },
  withIcon: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: Colors.Jacarta,
    marginLeft: '13px',
  },
  withoutIcon: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    color: Colors.RichBlack,
  },
};

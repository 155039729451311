import { StyleRules } from "@mui/styles";

export const chipStyles: StyleRules = {
    chip: {
        background: "#E3E8F8",
        border: "1px solid #F4F6FC",
        borderRadius: "20px",
        padding: ".2rem .6rem",
    },
    chipText: {
        fontSize: "12px",
        lineHeight: "15px",
        marginLeft: ".3rem",
    },
};

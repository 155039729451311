import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Title } from '../../../common/titles/title/title';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hook';
import { ReduxState } from '../../../store/store.interface';
import { AdUnit, AdUnitType } from '../../adUnit/adUnit.interface';
import { NewsletterDetails } from '../../newsletters/newsletter.interface';
import { LineItem } from '../lineItem.interface';
import { SectionProps } from './sectionProps';
import { entityIdToName } from '../../../common/forms/utils';
import { readAll as readAllAdUnits } from '../../../store/adUnit.slice';
import { readAll as readAllNewsletters } from '../../../store/newsletter.slice';
import { Conditional } from '../../../common/conditional/conditional';
import { conditions } from '../conditions';
import { Organization } from '../../organizations/organization.interface';
import { Creative } from '../../creatives/creative.interface';
import { useAuthentication } from '../../../common/user/auth/useAuthentication.hook';

type NewsletterSelectionConstraints = 'all' | 'whitelist' | 'blacklist';
type AdUnitsSelectionConstraints = 'all' | 'whitelist';

export const TargetingSection: React.FC<SectionProps> = ({
  lineItem,
  setLineItem,
  isValidField,
  getFieldMessage,
  creatives,
}: SectionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuthentication();
  // Used for limiting the attempts to fetch these from the API.
  const [fetchedAdunits, setFetchedAdUnits] = useState(false);
  // Used for limiting the attempts to fetch these from the API.
  const [fetchedNewsletters, setFetchedNewsletters] = useState(false);
  // Used for determining which option is selected in the Newsletter section.
  const [newslettersSection, setNewslettersSection] =
    useState<NewsletterSelectionConstraints>(
      !!lineItem.whitelistedNewsletterIds
        ? 'whitelist'
        : !!lineItem.blacklistedNewsletterIds
        ? 'blacklist'
        : 'all',
    );
  // Used for determining which option is selected in the AdUnits section.
  const [adUnitsSection, setAdUnitsSection] =
    useState<AdUnitsSelectionConstraints>(
      lineItem.adUnitIds ? 'whitelist' : 'all',
    );
  // Updates the Newsletter section selection, additionally deletes white/blacklisted values if set to all.
  const updateNewslettersSection = (value: NewsletterSelectionConstraints) => {
    setNewslettersSection(value);
    let updatedLineItem: Partial<LineItem> = { ...lineItem };
    if (value === 'all') {
      if (lineItem.whitelistedNewsletterIds) {
        delete updatedLineItem.whitelistedNewsletterIds;
      }
      if (lineItem.blacklistedNewsletterIds) {
        delete updatedLineItem.blacklistedNewsletterIds;
      }
      setLineItem(updatedLineItem);
    }
  };
  // Updates the individual selections for white/black lists.
  const updateSelectedNewsletters = (value) => {
    let updatedLineItem: Partial<LineItem> = { ...lineItem };
    if (newslettersSection === 'whitelist') {
      if (lineItem.blacklistedNewsletterIds) {
        delete updatedLineItem.blacklistedNewsletterIds;
      }
    } else if (newslettersSection === 'blacklist') {
      if (lineItem.whitelistedNewsletterIds) {
        delete updatedLineItem.whitelistedNewsletterIds;
      }
    }
    updatedLineItem[`${newslettersSection}edNewsletterIds`] = value;
    setLineItem(updatedLineItem);
  };
  // Updates the AdUnits section selection, additionally deletes whitelisted values if set to all and is not a roadblock.
  // Maps creativeIdsByAdUnitIndex if all is selected in different ways when isRoadblock vs not.
  const updateAdUnitsSection = (value: AdUnitsSelectionConstraints) => {
    // sets the radio value for the AdUnits section
    setAdUnitsSection(value);
    let updatedLineItem: Partial<LineItem> = { ...lineItem };
    if (value === 'all') {
      if (updatedLineItem.isRoadblock) {
        updatedLineItem.adUnitIds = filteredAdUnits.map((ad: AdUnit) => ad.id);
        updatedLineItem.creativeIdsByAdUnitIndex =
          updatedLineItem.adUnitIds.map(() => []);
      } else {
        delete updatedLineItem.adUnitIds;
        updatedLineItem.creativeIdsByAdUnitIndex =
          updatedLineItem.creativeIdsByAdUnitIndex &&
          updatedLineItem.creativeIdsByAdUnitIndex[0].length > 0
            ? updatedLineItem.creativeIdsByAdUnitIndex
            : [[]];
      }
      setLineItem(updatedLineItem);
    }
  };
  // Updates the individual AdUnits selection when whitelisting.
  // Maps creativeIdsByAdUnitIndex in different ways when isRoadblock vs not.
  const updateAdUnits = (value) => {
    let updatedLineItem: Partial<LineItem> = { ...lineItem, adUnitIds: value };
    if (updatedLineItem.isRoadblock) {
      updatedLineItem.creativeIdsByAdUnitIndex = (
        updatedLineItem.adUnitIds || []
      ).map(() => []);
    } else {
      const { adUnitType, creativeIdsByAdUnitIndex } = updatedLineItem;
      updatedLineItem.creativeIdsByAdUnitIndex =
        creativeIdsByAdUnitIndex && creativeIdsByAdUnitIndex[0].length > 0
          ? creatives &&
            creatives.length > 0 &&
            adUnitType ===
              creatives?.find((c: Creative) =>
                creativeIdsByAdUnitIndex[0].includes(c.id),
              )?.adUnitType
            ? creativeIdsByAdUnitIndex
            : [[]]
          : [[]];
    }
    setLineItem(updatedLineItem);
  };
  // Gets Newsletters from state
  const newsletters: NewsletterDetails[] = useAppSelector(
    (state: ReduxState) => state.newsletters,
  );
  // Gets AdUnits from state
  const adUnits: AdUnit[] = useAppSelector(
    (state: ReduxState) => state.adUnits,
  );
  // Gets active organization from user state
  const activeOrganizationId: number = useAppSelector(
    (state: ReduxState) => state.user.activeOrganizationId,
  );
  // TODO: consolidate all three of these ^^ into a single call.

  const activeOrganization: Organization | undefined = useAppSelector(
    (state: ReduxState) =>
      state.organizations.find((org) => org.id === activeOrganizationId),
  );
  // We should filter newsletters based on the active organization,
  // When active organization is Wellput we show all newsletters.
  let shouldFilterNewsletters: boolean = false;
  if (activeOrganization) {
    shouldFilterNewsletters =
      conditions.shouldFilterNewsletters(activeOrganization);
  }
  // Defines columns for individual selections.
  const adUnitColumns: GridColDef[] = [
    { field: 'name', headerName: 'Ad Unit', flex: 1 },
    { field: 'newsletter', headerName: 'Newsletter', flex: 1 },
  ];
  // Defines columns for individual selections.
  const newsletterColumns: GridColDef[] = [
    { field: 'name', headerName: 'Newsletter', flex: 1 },
  ];

  useEffect(() => {
    if (isAuthenticated) {
      if (newsletters.length === 0 && !fetchedNewsletters) {
        dispatch(readAllNewsletters() as any);
        setFetchedNewsletters(true);
      }
      if (adUnits.length === 0 && !fetchedAdunits) {
        dispatch(readAllAdUnits() as any);
        setFetchedAdUnits(true);
      }
    }
  }, [
    adUnits,
    dispatch,
    fetchedAdunits,
    fetchedNewsletters,
    newsletters,
    isAuthenticated,
  ]);

  const filteredNewsLettersByActiveOrg: NewsletterDetails[] =
    !shouldFilterNewsletters
      ? newsletters
      : newsletters.filter(
          (news: NewsletterDetails) =>
            news.publisherId === activeOrganizationId,
        );

  const filteredNewsletterIdsByOrgId: number[] =
    filteredNewsLettersByActiveOrg.map((news: NewsletterDetails) => news.id);

  const filteredAdUnits: AdUnit[] = (
    newslettersSection === 'all'
      ? adUnits.filter((ad: AdUnit) =>
          filteredNewsletterIdsByOrgId.includes(ad.newsletterId),
        )
      : newslettersSection === 'whitelist'
      ? adUnits?.filter((ad: AdUnit) =>
          (lineItem.whitelistedNewsletterIds || []).includes(ad.newsletterId),
        )
      : adUnits?.filter(
          (ad: AdUnit) =>
            !(lineItem.blacklistedNewsletterIds || []).includes(
              ad.newsletterId,
            ),
        )
  ).filter((adUnit: AdUnit) =>
    !!lineItem.adUnitType ? adUnit.type === lineItem.adUnitType : true,
  );
  const adUnitRows =
    filteredAdUnits?.map((adUnit, i) => ({
      id: adUnit.id,
      name: adUnit.name,
      newsletterId: adUnit.newsletterId,
      newsletter:
        entityIdToName(adUnit.newsletterId, filteredNewsLettersByActiveOrg) ??
        'Not found',
    })) || [];
  // Updates targeting section fields
  // TODO: DRY this logic
  const updateField = (attribute: string) => (eve) => {
    let updatedLineItem: Partial<LineItem>;
    if (['isRoadblock'].includes(attribute)) {
      updatedLineItem = {
        ...lineItem,
        [attribute]: eve.target.value === 'true',
      };
      if (updatedLineItem.isRoadblock && updatedLineItem.adUnitType) {
        delete updatedLineItem.adUnitType;
      }
      if (adUnitsSection === 'all' && updatedLineItem.isRoadblock) {
        updatedLineItem.adUnitIds = filteredAdUnits.map((ad: AdUnit) => ad.id);
      }
      setLineItem(updatedLineItem);
    } else if (['adUnitType'].includes(attribute)) {
      updatedLineItem = {
        ...lineItem,
        [attribute]: eve.target.value,
      };
      setLineItem(updatedLineItem);
    }
  };
  return (
    <>
      <Box sx={{ my: 3 }}>
        <Title text="TARGETING" />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel required>Roadblock</FormLabel>
            <RadioGroup
              onChange={updateField('isRoadblock')}
              value={lineItem.isRoadblock}
            >
              <FormControlLabel control={<Radio />} label="No" value={false} />
              <FormControlLabel control={<Radio />} label="Yes" value={true} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Conditional
          predicate={conditions.adUnitType(lineItem)}
          // onHide={() => removeField('adUnitType')}
        >
          <Grid item xs={12}>
            <FormControl>
              <FormLabel required error={!isValidField(lineItem, 'adUnitType')}>
                Ad Unit Type
              </FormLabel>
              <RadioGroup
                onChange={updateField('adUnitType')}
                value={lineItem.adUnitType}
              >
                <FormControlLabel
                  value={AdUnitType.SPONSORED_CONTENT}
                  control={<Radio />}
                  label="Sponsored Content"
                />
                <FormControlLabel
                  value={AdUnitType.LIST}
                  control={<Radio />}
                  label="List"
                />
              </RadioGroup>
              <FormHelperText error>
                {getFieldMessage('adUnitType')}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Conditional>
        <Conditional
          predicate={
            lineItem.isRoadblock !== undefined || !!lineItem.adUnitType
          }
        >
          <Grid item xs={12}>
            <FormControl>
              <FormLabel
                required
                error={
                  !filteredNewsLettersByActiveOrg ||
                  filteredNewsLettersByActiveOrg.length === 0 ||
                  (newslettersSection === 'whitelist' &&
                    (!lineItem.whitelistedNewsletterIds ||
                      lineItem?.whitelistedNewsletterIds?.length === 0)) ||
                  (newslettersSection === 'blacklist' &&
                    (!lineItem.blacklistedNewsletterIds ||
                      lineItem?.blacklistedNewsletterIds?.length === 0))
                }
              >
                Newsletters
              </FormLabel>
              <RadioGroup
                value={newslettersSection || 'all'}
                onChange={(e) =>
                  updateNewslettersSection(
                    e.target.value as NewsletterSelectionConstraints,
                  )
                }
              >
                <FormControlLabel
                  value={'all'} // no constrains
                  control={<Radio />}
                  label="All newsletters"
                />
                <FormControlLabel
                  value={'whitelist'}
                  control={<Radio />}
                  label="Specific newsletters (whitelist)"
                />
                <FormControlLabel
                  value={'blacklist'}
                  control={<Radio />}
                  label="All except specific newsletters (blacklist)"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {['whitelist', 'blacklist'].includes(newslettersSection) ? (
              <Grid
                container
                style={{
                  height: 400,
                  width: '100%',
                }}
              >
                <DataGrid
                  rows={filteredNewsLettersByActiveOrg}
                  columns={newsletterColumns}
                  checkboxSelection
                  onSelectionModelChange={(selected) => {
                    if (selected.length > 1) {
                      const result = selected.filter(
                        (s) =>
                          !(lineItem.whitelistedNewsletterIds || []).includes(
                            s as number,
                          ),
                      );

                      updateSelectedNewsletters(result);
                    } else {
                      updateSelectedNewsletters(selected);
                    }
                  }}
                  selectionModel={
                    newslettersSection === 'whitelist'
                      ? lineItem.whitelistedNewsletterIds || []
                      : lineItem.blacklistedNewsletterIds || []
                  }
                />
              </Grid>
            ) : null}
          </Grid>
        </Conditional>
        <Conditional predicate={lineItem.isRoadblock !== undefined}>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel
                required
                error={
                  !adUnitRows ||
                  adUnitRows.length === 0 ||
                  (adUnitsSection === 'whitelist' &&
                    (!lineItem.adUnitIds || lineItem?.adUnitIds?.length === 0))
                }
              >
                Ad Units
              </FormLabel>
              <RadioGroup
                value={adUnitsSection || 'all'}
                onChange={(e) =>
                  updateAdUnitsSection(
                    e.target.value as AdUnitsSelectionConstraints,
                  )
                }
              >
                <FormControlLabel
                  value={'all'}
                  control={<Radio />}
                  label="All Ad Units"
                />
                <FormControlLabel
                  value={'whitelist'}
                  control={<Radio />}
                  label="Specific Ad Units (whitelist)"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {adUnitsSection === 'whitelist' ? (
            <Grid item xs={12}>
              <Grid
                container
                style={{
                  height: 400,
                  width: '100%',
                }}
              >
                <DataGrid
                  rows={adUnitRows}
                  columns={adUnitColumns}
                  checkboxSelection
                  onSelectionModelChange={(selected) => updateAdUnits(selected)}
                  selectionModel={lineItem.adUnitIds || []}
                />
              </Grid>
            </Grid>
          ) : null}
        </Conditional>
      </Grid>
    </>
  );
};

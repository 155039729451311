import { FC, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { SectionCard } from "../cards/sectionCard/sectionCard";
import CardHeader, { CardHeaderProps } from "../cards/cardHeader/cardHeader";
import { Title, TitleProps } from "../titles/title";

export type ListItemProps = {
  header?: CardHeaderProps;
  title?: TitleProps;
  content: JSX.Element;
  contentButtonLabel?: string;
  alternateContent?: JSX.Element;
  alternateButtonLabel?: string;
};

const ListItem: FC<ListItemProps> = ({
  header,
  title,
  content,
  contentButtonLabel = "More details",
  alternateContent,
  alternateButtonLabel = "Fewer details",
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleSwap = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <SectionCard>
      <>
        {header && <CardHeader {...header} />}
        <Box px={4} py={5}>
          {title && <Title {...title} />}
          <Box px={4} py={3}>
            {isCollapsed ? content : alternateContent}
          </Box>
          <Box px={4} pt={2}>
            <Button
              variant="outlined"
              onClick={handleSwap}
              endIcon={
                isCollapsed ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
              }
            >
              {isCollapsed ? contentButtonLabel : alternateButtonLabel}
            </Button>
          </Box>
        </Box>
      </>
    </SectionCard>
  );
};

export default ListItem;

import { StyleRules } from '@mui/styles';

export const ButtonStyles: StyleRules = {
  AddButton: {
    padding: '6px 6px 6px 8px',
    width: '180px',
    height: '36px',
    border: '1px solid #8FA1E5',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginTop: '60px',
  },
  userWidget: {
    borderRadius: '0 10px 0 0',
  },
};

import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Title } from '../../../common/titles/title/title';
import { LineItem, PricingTerms } from '../lineItem.interface';
import { PricingSectionProps } from './sectionProps';

import { NumericFormat } from 'react-number-format';
import { conditions } from '../conditions';
import { Conditional } from '../../../common/conditional/conditional';
import React from 'react';
import { useAppSelector } from '../../../hooks/redux.hook';
import { ReduxState } from '../../../store/store.interface';
import { PricingModel } from '../../pricingModel/pricingModel.interface';
import { Organization } from '../../organizations/organization.interface';

export const PricingSection = ({
  lineItem,
  setLineItem,
  lineItemValidator,
  pricingTermsValidator,
}: PricingSectionProps) => {
  // deletes a given attribute from the model if it exists
  // TODO: move to a utility and make generic as this logic will be reused elsewhere.
  const deleteAttrubute = (
    attribute: string,
    updatedLineItem: Partial<LineItem>,
  ) => {
    if (!!lineItem[attribute]) {
      delete updatedLineItem[attribute];
    }
    return updatedLineItem;
  };
  // Refactored for CPM & CPO based on publisherRate and budget
  const computeGuaranteed = (
    budget: number,
    advertiserRate?: number,
    model?: PricingModel,
  ) => {
    const pricingModel: PricingModel | undefined =
      model || lineItem?.pricingTerms?.model;
    const rate = advertiserRate || lineItem.pricingTerms?.advertiserRate;
    if (rate && pricingModel && [PricingModel.CPM].includes(pricingModel)) {
      return Math.floor((budget / rate) * 1000);
    } else if (
      rate &&
      pricingModel &&
      [PricingModel.CPA, PricingModel.CPC, PricingModel.CPO].includes(
        pricingModel,
      )
    ) {
      return Math.floor(budget / rate);
    }
    return lineItem.guaranteedVolume;
  };
  // Refactored for CPM & CPO based on publisherRate and guaranteedVolume
  const computeBudget = (guaranteedVolume: number, advertiserRate?: number) => {
    const pricingModel: PricingModel | undefined =
      lineItem?.pricingTerms?.model;
    const rate = advertiserRate || lineItem.pricingTerms?.advertiserRate;

    if (rate && pricingModel && [PricingModel.CPM].includes(pricingModel)) {
      return Math.floor((guaranteedVolume / 1000) * rate);
    } else if (
      rate &&
      pricingModel &&
      [PricingModel.CPA, PricingModel.CPC, PricingModel.CPO].includes(
        pricingModel,
      )
    ) {
      return Math.floor(guaranteedVolume * rate);
    }
    return lineItem.budget;
  };
  // Remove or recalculate values based on pricing model changes.
  const applyPricingModelChange = (
    value: PricingModel,
    updatedLineItem: Partial<LineItem>,
  ): Partial<LineItem> => {
    if (
      conditions.guaranteedVolume(lineItem.pricingTerms?.model) &&
      !conditions.guaranteedVolume(value)
    ) {
      return deleteAttrubute('guaranteedVolume', updatedLineItem);
    } else if (
      !conditions.guaranteedVolume(lineItem.pricingTerms?.model) &&
      conditions.guaranteedVolume(value)
    ) {
      return {
        ...updatedLineItem,
        ...(updatedLineItem.pricingTerms?.model === PricingModel.CPM && {
          guaranteedVolume: computeGuaranteed(
            updatedLineItem.budget || 0,
            updatedLineItem.pricingTerms?.advertiserRate,
            value,
          ),
        }),
      };
    }
    if (
      conditions.estimatedRevenuePerClick(lineItem) &&
      !conditions.estimatedRevenuePerClick(updatedLineItem)
    ) {
      console.log('should delete estimatedRevenuePerClick');
      const result = deleteAttrubute(
        'estimatedRevenuePerClick',
        updatedLineItem,
      );
      console.log('updatedLineItem: ', updatedLineItem);
      return result;
    }
    return updatedLineItem;
  };
  // Find the active organization id
  const activeOrganizationId: number = useAppSelector(
    (state: ReduxState) => state.user.activeOrganizationId,
  );
  // Lookup the active organization object
  const activeOrganization: Organization | undefined = useAppSelector(
    (state: ReduxState) =>
      state.organizations.find((org) => org.id === activeOrganizationId),
  );
  // Advertiser rate is only shown in certain circumstances
  let allowAdvertiserRate: boolean = false;
  // TODO: this should happen in useEffect
  if (activeOrganization) {
    allowAdvertiserRate =
      conditions.advertiserRate(activeOrganization) &&
      !!lineItem?.pricingTerms?.model &&
      ![PricingModel.CPM].includes(lineItem.pricingTerms.model);
  }
  // function that updates the pricing terms object of a lineItem
  const updatePricingTerms = (attribute: keyof PricingTerms) => (event) => {
    const isAdvertiserOrPublisher: boolean = [
      'advertiserRate',
      'publisherRate',
    ].includes(attribute);

    const value: PricingModel | number = isAdvertiserOrPublisher
      ? +event.target.value.split(',').join('')
      : event.target.value;

    const updatedPricingTerms: PricingTerms = {
      ...(lineItem.pricingTerms || {
        advertiserRate: 0,
        publisherRate: 0,
        model: '' as PricingModel,
      }),
      [attribute]: value,
      ...(attribute === 'publisherRate' &&
        !allowAdvertiserRate && { advertiserRate: +value }),
    };
    let updatedLineItem: Partial<LineItem> = {
      ...lineItem,
      pricingTerms: updatedPricingTerms,
      ...(attribute === 'advertiserRate' &&
        updatedPricingTerms.model === PricingModel.CPM && {
          budget: computeBudget(lineItem.budget || 0, +value),
          guaranteedVolume: computeGuaranteed(
            lineItem.guaranteedVolume || 0,
            +value,
          ),
        }),
    };
    if (attribute === 'model') {
      return setLineItem(
        applyPricingModelChange(value as PricingModel, updatedLineItem),
      );
    }
    setLineItem(updatedLineItem);
  };
  // Updates any other fields of a lineItems' pricing section.
  const updateField = (attribute: string) => (event) => {
    const value: number = +event.target.value.split(',').join('');
    let updatedLineItem = {
      ...lineItem,
      ...(!!value && { [attribute]: value }),
      ...(attribute === 'budget' &&
        lineItem.pricingTerms?.model === PricingModel.CPM && {
          // if we are setting the budget, calculate the guaranteedVolume if pricing model is CPM
          guaranteedVolume: computeGuaranteed(value),
        }),
      ...(attribute === 'guaranteedVolume' &&
        lineItem.pricingTerms?.model === PricingModel.CPM && {
          // if we are setting the guaranteedVolume, calculate the budget if pricing model is CPM
          budget: computeBudget(value),
        }),
    };
    setLineItem(updatedLineItem);
  };
  return (
    <>
      <Box sx={{ my: 3 }}>
        <Title text="PRICING" />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <FormControl required fullWidth>
            <InputLabel>Pricing Model</InputLabel>
            <Select
              label="Pricing Model"
              value={lineItem?.pricingTerms?.model || 'Select a pricing model'}
              error={
                !pricingTermsValidator.isValidField(
                  lineItem.pricingTerms || {},
                  'model',
                )
              }
              onChange={updatePricingTerms('model')}
            >
              {['Select a pricing model', ...Object.values(PricingModel)].map(
                (model: string, index: number) => {
                  return (
                    <MenuItem key={`model${index}`} value={model}>
                      {model}
                    </MenuItem>
                  );
                },
              )}
            </Select>
            <FormHelperText error>
              {pricingTermsValidator.getFieldMessage('model')}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Conditional predicate={allowAdvertiserRate}>
          <Grid item xs={4}>
            <NumericFormat
              fullWidth
              customInput={TextField}
              required
              variant="outlined"
              label="Advertiser Rate"
              thousandSeparator={true}
              decimalScale={2}
              onChange={updatePricingTerms('advertiserRate')}
              error={
                !pricingTermsValidator.isValidField(
                  lineItem.pricingTerms || {},
                  'advertiserRate',
                )
              }
              helperText={pricingTermsValidator.getFieldMessage(
                'advertiserRate',
              )}
              value={lineItem?.pricingTerms?.advertiserRate}
              InputProps={{
                startAdornment: <span>{'$'}</span>,
              }}
            />
          </Grid>
        </Conditional>
        <Grid item xs={4}>
          <NumericFormat
            fullWidth
            customInput={TextField}
            required
            variant="outlined"
            label="Publisher Rate"
            thousandSeparator={true}
            decimalScale={2}
            onChange={updatePricingTerms('publisherRate')}
            error={
              !pricingTermsValidator.isValidField(
                lineItem.pricingTerms || {},
                'publisherRate',
              )
            }
            helperText={pricingTermsValidator.getFieldMessage('publisherRate')}
            value={lineItem?.pricingTerms?.publisherRate}
            InputProps={{
              startAdornment: <span>{'$'}</span>,
            }}
          />
        </Grid>
        <Conditional predicate={conditions.estimatedRevenuePerClick(lineItem)}>
          <Grid item xs={4}>
            <NumericFormat
              fullWidth
              customInput={TextField}
              required
              variant="outlined"
              label="Estimated Revenue Per Click"
              thousandSeparator={true}
              decimalScale={2}
              onChange={(e) =>
                setLineItem({
                  ...lineItem,
                  estimatedRevenuePerClick: +e.target.value.split(',').join(''),
                })
              }
              error={
                !lineItemValidator.isValidField(
                  lineItem,
                  'estimatedRevenuePerClick',
                )
              }
              helperText={lineItemValidator.getFieldMessage(
                'estimatedRevenuePerClick',
              )}
              value={lineItem.estimatedRevenuePerClick}
              InputProps={{
                startAdornment: <span>{'$'}</span>,
              }}
            />
          </Grid>
        </Conditional>
        <Conditional
          predicate={conditions.guaranteedVolume(lineItem?.pricingTerms?.model)}
        >
          <Grid item xs={4}>
            <NumericFormat
              fullWidth
              customInput={TextField}
              variant="outlined"
              label="Guaranteed Volume"
              value={lineItem.guaranteedVolume || undefined}
              thousandSeparator={true}
              decimalScale={2}
              onChange={updateField('guaranteedVolume')}
              error={
                !lineItemValidator.isValidField(lineItem, 'guaranteedVolume')
              }
              helperText={lineItemValidator.getFieldMessage('guaranteedVolume')}
            />
          </Grid>
        </Conditional>
        <Grid item xs={4}>
          <NumericFormat
            fullWidth
            customInput={TextField}
            required
            variant="outlined"
            value={lineItem.budget || 0}
            label="Budget"
            thousandSeparator={true}
            decimalScale={2}
            onChange={updateField('budget')}
            error={!lineItemValidator.isValidField(lineItem, 'budget')}
            helperText={lineItemValidator.getFieldMessage('budget')}
            InputProps={{
              startAdornment: <span>{'$'}</span>,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

import { Components } from '@mui/material/styles';

const components: Components = {
  MuiButton: {
    defaultProps: {
      color: 'primary',
      variant: 'outlined',
    },
    styleOverrides: {
      root: {
        height: '40px',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& input::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          webkitAppearance: 'none',
          display: 'none',
        },
      },
    },
  },
};

export default components;

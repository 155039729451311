import React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from '@mui/styles';

import { breadcrumbsStyle } from "../../styles/breadcrumbs.style";

interface BreadcrumbsProps {
  items: Array<string>;
}

const useStyles = makeStyles(() => breadcrumbsStyle);

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({items}): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.breadcrumbs}>
      {
        items.map((item, index) => (
          <>
            {index > 0 && <Box key={`{index}_gt`}>&gt;</Box>}
            <Box key={index}>{item}</Box>
          </>
        ))
      }
    </Box>
  );
}

export default Breadcrumbs;

import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ViewContextProps } from "../../../../common/types/view";

const Actions: React.FC<ViewContextProps> = (
  props: ViewContextProps
): JSX.Element => (
  <Box
    display="flex"
    flexDirection="column"
    paddingX="2.5rem"
    paddingY="1.5rem"
    borderRadius="0.125rem"
    border="1px solid #000000"
  >
    <Button
      variant="outlined"
      sx={{
        textTransform: "none",
        backgroundColor: "#C4C4C4",
        color: "#000000",
      }}
      // disabled={true}
      href={"/proposals/create"}
    >
      Create Proposal
    </Button>
    <Button
      variant="outlined"
      sx={{ textTransform: "none", marginTop: "1rem" }}
      disabled={true}
    >
      Decline RFP
    </Button>
  </Box>
);

export default Actions;

import { Alert, AlertColor, Snackbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Theme } from '@mui/system';

export type NotifyProps = {
  durationInMiliseconds?: number;
};
export type NotificationState = {
  isVisible: boolean;
  message: string;
  secondaryMessage?: string;
  severity: AlertColor;
  durationInMiliseconds: number;
};
export type NotificationComponent = {
  notify: (message: string, severity?: AlertColor) => void;
  Notification: JSX.Element;
};
export const useNotifications = (
  props?: NotifyProps,
): NotificationComponent => {
  const [notification, setNotification] = useState<NotificationState>({
    isVisible: false,
    message: '',
    severity: 'info',
    durationInMiliseconds: props?.durationInMiliseconds || 6000,
  });
  const cancel = () =>
    setNotification({
      ...notification,
      isVisible: false,
    });
  const notify = (message: string, severity: AlertColor = 'info') =>
    setNotification({
      ...notification,
      message,
      severity,
      isVisible: true,
    });

  return {
    notify,
    Notification: (
      <Snackbar
        open={notification.isVisible}
        autoHideDuration={notification.durationInMiliseconds}
        onClose={cancel}
      >
        <Alert
          severity={notification.severity}
          sx={{
            backgroundColor: (theme: Theme) =>
              theme.palette[notification.severity].dark,
          }}
        >
          {notification.message.split(';').map((section, index) => (
            <div key={index}>
              <Typography color={'white'}>{section}</Typography>
              <br />
            </div>
          ))}
        </Alert>
      </Snackbar>
    ),
  };
};

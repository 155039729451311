import { TypographyVariantsOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    textLink1: React.CSSProperties;
    textLink2: React.CSSProperties;
    tableHeader: React.CSSProperties;
    tableRow: React.CSSProperties;
    tableBold: React.CSSProperties;
    tableSelectedRow: React.CSSProperties;
    tableStatus: React.CSSProperties;
    tooltipTitle: React.CSSProperties;
    tooltipText: React.CSSProperties;
    tag1: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    textLink1?: React.CSSProperties;
    textLink2?: React.CSSProperties;
    tableHeader?: React.CSSProperties;
    tableRow?: React.CSSProperties;
    tableBold?: React.CSSProperties;
    tableSelectedRow?: React.CSSProperties;
    tableStatus?: React.CSSProperties;
    tooltipTitle?: React.CSSProperties;
    tooltipText?: React.CSSProperties;
    tag1?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    textLink1: true;
    textLink2: true;
    tableHeader: true;
    tableRow: true;
    tableBold: true;
    tableSelectedRow: true;
    tableStatus: true;
    tooltipTitle: true;
    tooltipText: true;
    tag1: true;
  }
}

const typography: TypographyVariantsOptions = {
  fontFamily: 'Inter',
  h1: {
    fontSize: '32px',
    lineHeight: 'auto',
    fontWeight: 700,
  },
  h2: {
    fontSize: '24px',
    lineHeight: 'auto',
    fontWeight: 600,
  },
  h3: {
    fontSize: '20px',
    lineHeight: 'auto',
    fontWeight: 600,
  },
  h4: {
    fontSize: '16px',
    lineHeight: 'auto',
    fontWeight: 600,
  },
  h5: {
    fontSize: '14px',
    lineHeight: 'auto',
    fontWeight: 600,
  },
  h6: {
    fontSize: '12px',
    lineHeight: 'auto',
    fontWeight: 600,
  },
  body1: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  body2: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: '16px',
    lineHeight: 'auto',
    fontWeight: 200,
  },
  subtitle2: {
    fontSize: '12px',
    lineHeight: 'auto',
    fontWeight: 200,
  },
  textLink1: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
    textDecorationLine: 'underline',
  },
  textLink2: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 400,
    textDecorationLine: 'underline',
  },
  button: {
    fontSize: '13px',
    lineHeight: 'auto',
    fontWeight: 500,
  },
  tableHeader: {
    fontSize: '13px',
    lineHeight: 'auto',
    fontWeight: 600,
  },
  tableRow: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  tableBold: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 500,
  },
  tableSelectedRow: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 600,
  },
  tableStatus: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  tooltipTitle: {
    fontSize: '12px',
    lineHeight: 'auto',
    fontWeight: 700,
  },
  tooltipText: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
  },
  tag1: {
    fontSize: '12px',
    lineHeight: 'auto',
    fontWeight: 600,
  },
};

export default typography;

import { Box, Button, Snackbar } from '@mui/material';
import Assistance from '../../common/assistance/assistance';
import ViewTitle from '../../common/titles/viewTitle/viewTitle';
import React, { useEffect, useState } from 'react';
import { BrandForm } from './brand.form';
import { BrandsTable } from './brands.table';
import { Brand } from './brand.interface';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook';
import { ReduxState } from '../../store/store.interface';
import { readAll } from '../../store/brands.slice';
import { PlusOne } from '@mui/icons-material';
import { useNotify } from '../../hooks/notify.hook';
import { useAuthentication } from '../../common/user/auth/useAuthentication.hook';
import { BrandView } from './brand.view';
import { ViewContext } from '../../common/types/view';
import { viewStyles } from '../../styles/view.style';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => viewStyles);

export const BrandsAdminView: React.FC<{}> = () => {
  // state controls
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuthentication();
  const { brands, organizations } = useAppSelector(
    (state: ReduxState) => state,
  );
  const activeOrganizationId = useAppSelector(
    (state: ReduxState) => state.user.activeOrganizationId,
  );

  const filteredBrands: Brand[] =
    !brands || brands.length === 0
      ? []
      : brands.filter(
          (brand: Brand) => brand.organizationId === activeOrganizationId,
        );
  const [activeBrand, setActiveBrand] = useState<Brand>();
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [fetched, setFetched] = useState(false);
  const { notify, shouldNotify, cancel, duration, message } = useNotify();
  const toggleEdit = (brand?: Brand) => {
    if (brand) setActiveBrand(brand);
    setShowEdit(!showEdit);
    setFetched(false);
  };
  const toggleView = (brand?: Brand) => {
    if (brand) setActiveBrand(brand);
    setShowView(!showView);
    setFetched(false);
  };
  const handleCreateClick = () => {
    setActiveBrand(undefined);
    setShowEdit(true);
  };

  useEffect(() => {
    if (
      !showEdit &&
      isAuthenticated &&
      filteredBrands.length === 0 &&
      !fetched
    ) {
      dispatch(readAll() as any);
      setFetched(true);
    }
  }, [
    dispatch,
    fetched,
    filteredBrands.length,
    isAuthenticated,
    setFetched,
    showEdit,
  ]);
  return (
    <div className={classes.viewContainer}>
      <Box sx={{ mb: '3rem', color: 'primary.main' }}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Assistance />
        </Box>
        <ViewTitle
          {...{
            title: 'Brands',
            subTitle: 'Admin Console',
            rightAlignedContent: (
              <Button
                variant="outlined"
                startIcon={<PlusOne />}
                onClick={handleCreateClick}
                sx={{ backgroundColor: '#FFF' }}
              >
                Create Brand
              </Button>
            ),
          }}
        />
        {showEdit ? (
          <BrandForm
            brand={activeBrand}
            toggleVisibility={toggleEdit}
            notify={notify}
          />
        ) : showView ? (
          <BrandView
            {...{
              brand: activeBrand,
              viewContext: ViewContext.FLOW,
              toggleVisibility: toggleView,
            }}
          />
        ) : (
          <BrandsTable
            {...{
              edit: toggleEdit,
              brands: filteredBrands,
              organizations,
              view: toggleView,
            }}
          />
        )}
        <Snackbar
          open={shouldNotify}
          autoHideDuration={duration}
          message={message}
          onClose={cancel}
        />
      </Box>
    </div>
  );
};

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SectionTitle from "../../../../common/titles/sectionTitle";
import GridViewIcon from '@mui/icons-material/GridView';
import DetailsBoxTitle from "../../../../common/titles/detailsBoxTitle";
import {CheckCircleRounded} from "@mui/icons-material";
import {RfpDTO} from "../rfpDetails.interface";

interface IdealAudienceProps {
	data: RfpDTO;
}

const IdealAudience: React.FC<IdealAudienceProps> = ({data}: IdealAudienceProps): JSX.Element => (
	<Box marginTop="1.5rem">
		<SectionTitle id="rfps-ideal-audience-title" icon={(<GridViewIcon/>)} title="Ideal Audience"/>
		<Box marginTop="1.5rem">
			<DetailsBoxTitle id="rfps-description-subtitle" title="Description"/>
			<Typography id="rfps-description" marginTop="0.625rem" fontSize="0.875rem" lineHeight="120%" fontWeight="300">
				{data.audience.description}
			</Typography>
		</Box>
		<Typography id="rfps-geography-subtitle" marginTop="3rem" fontSize="1rem" lineHeight="120%">
			Geography
		</Typography>
		<Box display="flex" gap="6.25rem" marginTop="1.5rem">
			<Box>
				<DetailsBoxTitle id="rfps-including-subtitle" title="Including"/>
				<Box id="rfps-including">
					{
						data.audience.includedCountries.map((a: string) => (
							<Box key={a} display="flex" alignItems="center" marginTop="0.625rem">
								<Box display="flex" alignItems="center" marginTop="0.625rem">
									<CheckCircleRounded sx={{color: '#C4C4C4'}}/>
									<Typography fontSize="0.875rem" lineHeight="120%" fontWeight="300" marginLeft="0.625rem">
										{a}
									</Typography>
								</Box>
							</Box>
						))
					}
				</Box>
			</Box>
			<Box>
				<DetailsBoxTitle id="rfps-ideally-excluding-subtitle" title="Ideally Excluding"/>
				<Box id="rfps-ideally-excluding">
					{data.audience.excludedSubdivisions?.map(s => (
						<Box key={s} display="flex" alignItems="center" marginTop="0.625rem">
							<CheckCircleRounded sx={{color: '#C4C4C4'}}/>
							<Typography fontSize="0.875rem" lineHeight="120%" fontWeight="300" marginLeft="0.625rem">
								{s}
							</Typography>
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	</Box>);

export default IdealAudience;

import React from "react";
import { Link } from "react-router-dom";
export interface MailToButtonProps {
	mailto: string;
	label: string;
}
const MailToButton = (props: MailToButtonProps) => {
	return (
		<Link
			to="#"
			onClick={(e) => {
				window.location.href = props.mailto;
				e.preventDefault();
			}}
		>
			{props.label}
		</Link>
	);
};

export default MailToButton;
